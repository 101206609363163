import React from 'react';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import NumberInput from '../NumberInput';
import IconButton from '../IconButton';

type QuantityControllerProps = {
  value: string;
  onChange(value: string): void;
  limit?: number;
};

const QuantityController: React.FC<QuantityControllerProps> = ({
  value,
  onChange,
}) => {
  const { inputStyle, buttonStyle, secondaryBrandColor } = useThemeContext();

  const changeQuantity = (value: string, operation?: 'sum' | 'subtract') => {
    let currValue: number;
    if (operation) {
      switch (operation) {
        case 'sum':
          currValue = Number(value) + 1;
          onChange(currValue.toString());
          break;
        case 'subtract':
          currValue = Number(value) - 1;
          onChange(currValue.toString());
          break;
      }
    } else {
      onChange(value);
    }
  };

  return (
    <div className='flex flex-row items-center'>
      <IconButton
        variant='themed'
        iconName='minus'
        borderStyle={buttonStyle}
        styles='w-[2.25rem] h-[2.25rem]'
        iconColor={secondaryBrandColor || '#FFFFFF'}
        disabled={Number(value) <= 1 ? true : false}
        onClick={() => changeQuantity(value, 'subtract')}
        size={14}
      />
      <NumberInput
        value={value}
        borderStyle={inputStyle}
        ariaLabel='Quantity Selector'
        onChange={(event) =>
          changeQuantity(
            parseInt(event.target.value) < 1 ? '1' : event.target.value
          )
        }
      />
      <IconButton
        variant='themed'
        iconName='plus'
        borderStyle={buttonStyle}
        styles='w-[2.25rem] h-[2.25rem]'
        iconColor={secondaryBrandColor || '#FFFFFF'}
        onClick={() => changeQuantity(value, 'sum')}
        size={14}
      />
    </div>
  );
};

export default QuantityController;
