import React, { useState, useEffect } from 'react';
import CheckAnimation from 'components/atomic/CheckAnimation';
import { useGlobal } from 'context/global/GlobalContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';

const SuccessDrawer: React.FC = () => {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const { desktopView } = useGlobal();
  const { menuStyle } = useThemeContext();
  const {
    open,
    meta,
    success,
    closeSuccess,
    closeOnComplete,
    closeSuccessDrawer,
  } = useSuccessDrawerContext();

  useEffect(() => {
    if (open) setIsAnimating(true);
    else setTimeout(() => setIsAnimating(false), 700);
  }, [open]);

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-tl-3xl rounded-tr-3xl';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-tl-lg rounded-tr-lg';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-tl-3xl rounded-tr-3xl';
    }
  };

  return !open ? null : (
    <div
      className={`z-[999] absolute left-0 w-full flex flex-col items-center justify-end bg-primary duration-700 
      ${
        isAnimating
          ? `${desktopView ? 'lg:top-0' : 'delay-700 top-[12%]'}`
          : 'top-[100%]'
      } 
      ${desktopView ? 'lg:h-full' : 'h-[88%]'} 
      ${getCornerStyle()}`}
    >
      <div className='flex items-end justify-center h-1/2'>
        <CheckAnimation
          loading={!success}
          onComplete={() => {
            if (closeOnComplete) {
              setIsAnimating(false);
              setTimeout(() => {
                closeSuccessDrawer();
                closeSuccess();
              }, 700);
            }
          }}
        />
      </div>
      <div className={`px-6 h-1/2 ${!success ? 'opacity-0' : 'opacity-100'}`}>
        <h3
          tabIndex={0}
          className='text-[2rem] leading-snug mb-1.5 font-bold text-center text-white'
        >
          {meta.title}
        </h3>
        <p tabIndex={0} className='text-base text-center text-white'>
          {meta.description}
        </p>
      </div>
    </div>
  );
};

export default SuccessDrawer;
