import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useGlobal } from '../../context/global/GlobalContext';
import { ReturnedCollectionType } from 'types/ProductDetailsType';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GlobalContext } from 'context';
import { RoutesHashMap } from 'routes';
import { Helmet } from 'react-helmet';
import PageWrapper from 'components/layout/PageWrapper';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import ProductThumbnail from 'components/atomic/ProductThumbnail';

type BrandCollectionType = {
  brand: string;
  brandName?: string;
  items: ReturnedCollectionType[];
};

const Collection: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sortedCollection, setSortedCollection] = useState<
    BrandCollectionType[]
  >([]);

  const { t } = useTranslation('translation', { keyPrefix: 'collection' });
  const { collectionDetails, getCollection, setRegistrationId, setSlug } =
    useGlobal();
  const { logEvent } = useContext(GlobalContext);
  const history = useHistory();

  useEffect(() => {
    const fetchCollection = async () => {
      setLoading(true);
      await getCollection();
      setLoading(false);
    };

    fetchCollection();
  }, []);

  useEffect(() => {
    if (collectionDetails) {
      const allBrandsArr = new Set(
        collectionDetails?.map((item) => item?.brandId)
      );
      const outputArr: BrandCollectionType[] = [];
      allBrandsArr.forEach((brandId) => {
        brandId &&
          outputArr.push({
            brand: brandId,
            brandName: collectionDetails?.find(
              (item) => brandId === item?.brandId
            )?.brandName,
            items: collectionDetails
              .filter((item) => item?.brandId === brandId)
              .sort(
                (a, b) =>
                  new Date(b?.registrationDate).valueOf() -
                  new Date(a?.registrationDate).valueOf()
              ),
          });
      });

      setSortedCollection(outputArr);
    }
  }, [collectionDetails]);

  const renderCollection = useCallback(
    (items: ReturnedCollectionType[]) => {
      return (
        <div
          aria-hidden
          key={items[0]?.brandId}
          className={`grid mt-2 mb-8 grid-cols-2 gap-x-4 gap-y-2 ${
            items.length === 1 ? 'w-[calc(50% - 8px)]' : 'w-full'
          }`}
        >
          {items.map((node) => (
            <ProductThumbnail
              item={node}
              key={node.tagId}
              onClick={() => {
                setSlug(node.tagId);
                setRegistrationId(node.registrationId);
                logEvent({
                  eventType: 'ENGAGEMENTS',
                  event: 'VIEW_PRODUCT_INFO',
                  data: node.productId,
                });
                history.push(RoutesHashMap.ProductDetails.path(node.tagId));
              }}
            />
          ))}
        </div>
      );
    },
    [history, logEvent, setSlug, setRegistrationId]
  );

  return (
    <PageWrapper pageTitle={t('collectionPageTitle')} hideLogo>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {loading ? (
        <div className='w-full h-full flex my-auto pb-20'>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='w-full h-full min-h-max flex flex-col justify-start overflow-auto bg-white'>
          {sortedCollection?.length > 0 ? (
            <div className='w-full flex flex-col items-start justify-start py-6 px-5'>
              {sortedCollection.map((item) => (
                <div
                  aria-hidden
                  key={item.brand}
                  className='w-full flex flex-col'
                >
                  <div className='w-full flex justify-start'>
                    <h2
                      tabIndex={0}
                      role='presentation'
                      className='text-base font-semibold'
                      aria-label={item.brandName}
                    >
                      {item.brandName} ({item.items.length})
                    </h2>
                  </div>
                  {renderCollection(item.items)}
                </div>
              ))}
            </div>
          ) : (
            <div className='w-full h-full flex items-center justify-center px-5 pb-20'>
              <h4 tabIndex={0} className='text-center font-semibold'>
                {t('emptyCollectionMessage')}
              </h4>
            </div>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default Collection;
