import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import getFirebaseConfig from './firebase/config';
import KeyboardNavigation from 'context/KeyboardHandler/KeyboardHandler';
import { initializeApp } from '@firebase/app';
import {
  CartProvider,
  ThemeProvider,
  GlobalProvider,
  APICacheProvider,
  SuccessDrawerProvider,
} from './context';
import 'focus-visible';
import 'assets/main.css';
import './i18n';

const firebaseConfig = getFirebaseConfig();

initializeApp(firebaseConfig);
registerHandlers();

ReactDOM.render(
  (
    <React.StrictMode>
      <APICacheProvider>
        {/* <KeyboardNavigation /> */}
        <ThemeProvider>
          <GlobalProvider>
            <CartProvider>
              <SuccessDrawerProvider>
                <App />
              </SuccessDrawerProvider>
            </CartProvider>
          </GlobalProvider>
        </ThemeProvider>
      </APICacheProvider>
    </React.StrictMode>
  ) as any,
  document.getElementById('root')
);

function registerHandlers() {
  // window.addEventListener('error', (event) => {
  //   handleError(event);
  // });
  // window.addEventListener(
  //   'unhandledrejection',
  //   (event: PromiseRejectionEvent) => {
  //     handleError(event);
  //   }
  // );
}

function handleError(error: any) {
  try {
    window.parent.postMessage({ type: 'appCrashed', error: error.error }, '*');
  } catch (e) {}
}
