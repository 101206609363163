import React, { useState, useEffect, useRef, WheelEvent } from 'react';
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ReactComponent as Return } from 'assets/icons/svg/return.svg';
import { useCartContext } from 'context/CartContext/CartContext';
import { ShoppingModuleType } from 'types/ProductDetailsType';
import { useGlobal } from 'context/global/GlobalContext';
import { CartItem } from 'types/ShoppingExperience';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { UrlParam } from 'types/Misc';
import ShopProduct from './ShopProduct';
import ProductGrid from './ProductGrid';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import DiscountBanner from 'components/atomic/DiscountBanner/DiscountBanner';
import '../../../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../../../node_modules/slick-carousel/slick/slick.css';

type ShopDrawerProps = {
  data: ShoppingModuleType;
  brandName: string;
  brandId: string;
};

const ShopDrawer: React.FC<ShopDrawerProps> = ({
  data,
  brandId,
  brandName,
}) => {
  const [transistionAnimation, setTransistionAnimation] =
    useState<string>('right-to-left');

  const history = useHistory();
  const location = useLocation();
  const { desktopView } = useGlobal();
  const { id } = useParams<UrlParam>();
  const { cartOpen, cartProducts, recommendations } = useCartContext();
  const sliderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.shopDrawer',
  });

  useEffect(() => {
    if (
      location.pathname === `/c/${id}/shop` &&
      data?.defaultVariantDetails?.id
    )
      history.push(`/c/${id}/shop/${data.defaultVariantDetails.id.slice(29)}`);
  }, [location, history, id, data]);

  useEffect(() => {
    if (data.id)
      history.push(`/c/${id}/shop/${data.defaultVariantDetails.id.slice(29)}`);
  }, [data, history, id]);

  const horizontalScroll = (event: WheelEvent<HTMLDivElement>) => {
    const container = sliderRef.current;
    const delta = event.deltaY || event.deltaX;
    if (container) container.scrollLeft += delta;
  };

  const [isMounted, toggleIsMounted] = useState<boolean>(false);

  useEffect(() => {
    // TODO: Remove this in the future
    if ((window as any).mode === 'preview') {
      setTimeout(() => toggleIsMounted(true), 400);

      return () => toggleIsMounted(false);
    }
  }, []);

  return (
    <TransitionGroup
      className='relative w-full h-full overflow-auto'
      exit={false}
    >
      <CSSTransition
        timeout={200}
        key={location.key}
        classNames={
          !isMounted
            ? `${
                transistionAnimation === 'right-to-left'
                  ? 'slide'
                  : 'inverseslide'
              }`
            : ''
        }
      >
        <Switch location={location}>
          <Route
            path={`/c/${id}/shop/recommendations`}
            exact={true}
            render={() => (
              <div className='relative flex w-full h-auto pt-16 mb-6 overflow-auto overflow-x-hidden'>
                <div className='fixed top-0 left-0 w-full h-16 flex items-center rounded-[26px] z-10 bg-white'>
                  {data.isDiscountAvailable && (
                    <DiscountBanner
                      discountPercentage={data.discountPercentage}
                    />
                  )}
                </div>
                <button
                  type='button'
                  aria-label='Return'
                  className='z-[100] fixed top-4 left-7 cursor-pointer rounded-full focus-visible:outline focus-visible:outline-black/60'
                  onClick={() => {
                    setTransistionAnimation('left-to-right');
                    history.goBack();
                  }}
                >
                  <Return aria-hidden />
                </button>
                <ProductGrid slug={id} />
              </div>
            )}
          />
          <Route
            path={`/c/${id}/shop/:id`}
            exact={true}
            render={() => (
              <ModuleWrapper
                hideAnimation
                drawerTitle={
                  data.isDiscountAvailable && (
                    <DiscountBanner
                      discountPercentage={data.discountPercentage}
                    />
                  )
                }
              >
                {!cartOpen ? (
                  <div className='relative w-full h-full flex flex-col items-center justify-between overflow-auto overflow-x-hidden'>
                    <ShopProduct
                      product={data}
                      brandId={brandId}
                      brandName={brandName}
                    />
                    {!desktopView &&
                      recommendations &&
                      recommendations.length > 0 && (
                        <div className='relative h-[120px] max-h-[120px] w-full flex flex-col items-start pt-2.5 gap-2.5 mt-auto'>
                          <div className='w-full flex flex-row items-center justify-between'>
                            <span
                              tabIndex={0}
                              aria-label={t('recommendations.title')}
                              className='w-max tracking-[0.1px] leading-6 text-base text-muted'
                            >
                              {t('recommendations.title')}
                            </span>
                            <button
                              type='button'
                              onClick={() => {
                                setTransistionAnimation('right-to-left');
                                history.push(`/c/${id}/shop/recommendations`);
                              }}
                              className='relative block w-max mr-0.5 font-medium cursor-pointer tracking-[0.1px] text-primary whitespace-nowrap hover:underline focus-visible:outline focus-visible:outline-black/60'
                            >
                              {t('recommendations.seeMore')}
                              <label aria-hidden> &gt;</label>
                            </button>
                          </div>
                          <div
                            ref={sliderRef}
                            onWheel={horizontalScroll}
                            className='w-max max-w-full flex flex-row gap-2 bg-white overflow-x-auto'
                          >
                            {cartProducts.map((cartItem: CartItem) => (
                              <button
                                type='button'
                                className='relative w-[76px] min-w-[76px] h-[76px] rounded-lg overflow-hidden border-2 border-solid border-transparent focus-visible:border-black/60'
                                key={cartItem.id}
                                onClick={() => {
                                  setTransistionAnimation('right-to-left');
                                  history.push(`/c/${id}/shop/${cartItem.id}`);
                                }}
                              >
                                {cartItem.quantity && cartItem.quantity > 0 ? (
                                  <div className='z-10 absolute flex items-center justify-center bottom-0 right-0 w-7 h-6 p-2 bg-primary cursor-pointer rounded-md rounded-tl-md rounded-tr-none rounded-b-none'>
                                    <p className='text-xs text-center text-white'>
                                      {cartItem.quantity}
                                    </p>
                                  </div>
                                ) : null}
                                <img
                                  className='w-[76px] h=[76px] object-contain cursor-pointer'
                                  src={cartItem.product.image}
                                  alt={cartItem.product.name}
                                />
                              </button>
                            ))}
                            {recommendations.map(
                              (recommendation: ShoppingModuleType) => (
                                <button
                                  className='relative w-[76px] min-w-[76px] h-[76px] rounded-lg overflow-hidden border-2 border-solid border-transparent focus-visible:border-black/60'
                                  key={recommendation.id}
                                  onClick={() => {
                                    setTransistionAnimation('right-to-left');
                                    history.push(
                                      `/c/${id}/shop/${recommendation.defaultVariantDetails.id.slice(
                                        29
                                      )}`
                                    );
                                  }}
                                >
                                  <img
                                    className='w-[76px] h-[76px] object-cover cursor-pointer'
                                    src={
                                      recommendation.defaultVariantDetails.image
                                    }
                                    alt={
                                      recommendation.defaultVariantDetails.name
                                    }
                                  />
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                ) : null}
              </ModuleWrapper>
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default ShopDrawer;
