import React from 'react';
import { FormDetailModel } from 'types/FormTypes';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import { useIframeModifier } from 'hooks/useIframeModifier';
import FormImage from './FormImage';

type FormContentBlockProps = {
  formData: FormDetailModel;
};

const FormContentBlock: React.FC<FormContentBlockProps> = ({ formData }) => {
  return (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        tabIndex={0}
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p
          tabIndex={0}
          className='mt-1.5 mb-3 text-left text-xs font-semibold leading-formSubtitle text-muted'
        >
          {formData.subText}
        </p>
      )}
      <HtmlWrapper html={useIframeModifier(formData.config?.content || '')} />
    </div>
  );
};

export default FormContentBlock;
