import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MulberryCoverageDetails } from 'types/User';
import { useGlobal } from 'context/global/GlobalContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as ExpandIcon } from 'assets/icons/svg/expand.svg';
import { ModuleInfoType, WarrantyModuleType } from 'types/ProductDetailsType';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import useRedirectLink from 'hooks/useRedirectLink';
import Button from 'components/atomic/Button';
import WarrantyInfo from './WarrantyInfo';
import dateFormat from 'dateformat';

type WarrantyDrawerProps = {
  drawerTitle: string;
  warrantyId: string;
  warrantyData: WarrantyModuleType;
  warrantyModule: ModuleInfoType;
  registrationData?: any;
};

export enum ApprovalStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  DENIED = 'denied',
  EXPIRED = 'expired',
}

const getInfoText = (
  approvalStatus: ApprovalStatus,
  enableWarrantyStateMessages?: boolean,
  deniedMessage?: string,
  pendingMessage?: string,
  t?: any
): string => {
  let pendingText = '';
  let deniedText = '';
  if (enableWarrantyStateMessages) {
    pendingText = pendingMessage || t('pendingInfoText');
    deniedText = deniedMessage || t('deniedInfoText');
  }
  switch (approvalStatus) {
    case ApprovalStatus.APPROVED:
      return '';
    case ApprovalStatus.PENDING:
      return pendingText;
    case ApprovalStatus.DENIED:
      return deniedText;
    case ApprovalStatus.EXPIRED:
      return '';
    default:
      return '';
  }
};

const validateDate = (date: Date) =>
  new Date().setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0);

const isExpired = (expirationDate?: Date | string) => {
  if (!expirationDate) return false;
  if (expirationDate === 'LIFETIME') return false;
  return validateDate(new Date(dateFormat(expirationDate, 'mmm d, yyyy')));
};

const WarrantyDrawer: React.FC<WarrantyDrawerProps> = ({
  drawerTitle,
  warrantyData,
  warrantyModule,
  registrationData,
}) => {
  const [mulberryDropdownOpen, toggleMulberyDropdown] = useState<boolean>(true);
  const { brandTheme } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.warrantyDrawer',
  });
  const { slug, personalDetails, getCurrentRegistrationId, productDetails } =
    useGlobal();
  const productCollection = personalDetails?.profile?.productCollection;
  const registrationId = getCurrentRegistrationId();
  const redirectToExternalLink = useRedirectLink();
  const { enableWarrantyStateMessages, deniedMessage, pendingMessage } =
    warrantyModule;

  const currentProductItem = productCollection
    ?.filter((item) => !item.incomplete)
    .find(
      // TODO: remove this when we have a proper way to get the preview mode
      (item) => {
        const matched = item?.tagId === slug;
        if (registrationId)
          return matched && item?.registrationId === registrationId;
        return matched;
      }
    );

  let approvalStatus =
    (currentProductItem?.approvalStatus as ApprovalStatus) ||
    (warrantyData?.approvalStatus as ApprovalStatus) ||
    ApprovalStatus.APPROVED;

  approvalStatus = isExpired(warrantyData?.expirationDate)
    ? ApprovalStatus.EXPIRED
    : approvalStatus;

  const content = getInfoText(
    approvalStatus,
    enableWarrantyStateMessages,
    deniedMessage,
    pendingMessage,
    t
  );

  const showReceiptUploadOption = !!currentProductItem?.receipts?.length;

  const getHeaderWarranty = () => {
    let title = '';
    switch (approvalStatus) {
      case ApprovalStatus.APPROVED:
        if (
          mulberryCoverageDetails &&
          mulberryCoverageDetails?.available &&
          mulberryCoverageDetails?.purchased
        )
          title = t('warrantyHeadingWithMulberry');
        else title = t('warrantyHeading');
        break;
      case ApprovalStatus.PENDING:
        title = t('pendingWarrantyHeading');
        break;
      case ApprovalStatus.DENIED:
        title = t('deniedWarrantyHeading');
        break;
      case ApprovalStatus.EXPIRED:
        title = t('expiredWarrantyHeading');
        break;
      default:
        title = t('warrantyHeading');
        break;
    }
    return title;
  };

  let mulberryCoverageDetails: MulberryCoverageDetails | null = null;
  if (
    productDetails &&
    personalDetails?.profile?.warranties &&
    personalDetails?.profile?.warranties?.length > 0 &&
    warrantyData?.extendedCoverage?.warrantyExtension
  ) {
    const warranty = personalDetails?.profile?.warranties?.find(
      (warranty) => warranty?.productId === productDetails?.product?.id
    );
    if (warranty) mulberryCoverageDetails = warranty?.mulberryCoverage || null;
  }

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      <HtmlWrapper html={warrantyData?.details} />
      {warrantyData?.showWarrantyStatus && (
        <div className='w-full flex flex-col'>
          {mulberryCoverageDetails &&
            mulberryCoverageDetails?.available &&
            warrantyData?.extendedCoverage.warrantyExtension &&
            !mulberryCoverageDetails?.purchased && (
              <div className='relative w-full flex flex-col p-3 mt-3 gap-1.5 rounded bg-validWarranty/50 border border-solid border-primaryLight'>
                <button
                  type='button'
                  onClick={() => toggleMulberyDropdown(!mulberryDropdownOpen)}
                  className='relative w-full flex flex-row items-center justify-between font-bold text-brij focus-visible:outline focus-visible:outline-black/60'
                >
                  <p>
                    {warrantyData?.extendedCoverage.warrantyExtensionHeader ||
                      t('mulberryDefaultHeader')}
                  </p>
                  <ExpandIcon
                    aria-hidden
                    className={`min-w-[24px] min-h-[24px] transform duration-150 ${
                      mulberryDropdownOpen ? 'rotate-180' : 'rotate-0'
                    }`}
                    fill='#4B6EFA'
                    height={24}
                    width={24}
                  />
                </button>
                {mulberryDropdownOpen && (
                  <div className='relative w-full flex flex-col gap-1.5'>
                    <p className='font-medium text-xs text-brij'>
                      {warrantyData?.extendedCoverage.warrantyExtensionBody ||
                        t('mulberryDefaultDescription')}
                    </p>
                    <div className='w-full px-1.5'>
                      <Button
                        variant='dark'
                        styles='!mt-2.5 !bg-brij !text-white'
                        title={
                          warrantyData?.extendedCoverage.warrantyExtensionCTA ||
                          t('mulberryDefaultButtonCta')
                        }
                        onClick={() =>
                          redirectToExternalLink(
                            mulberryCoverageDetails?.url,
                            '_blank'
                          )
                        }
                      />
                    </div>
                    <p
                      tabIndex={0}
                      className='px-2 text-xxs text-gray text-center'
                    >
                      {t('mulberryExpirationNotice')}
                      <strong className='font-semibold text-brij'>
                        {dateFormat(
                          mulberryCoverageDetails?.expiresAt,
                          'mm/dd/yyyy'
                        )}
                      </strong>
                      .
                    </p>
                  </div>
                )}
              </div>
            )}
          <WarrantyInfo
            content={content}
            brandTheme={brandTheme}
            title={getHeaderWarranty()}
            approvalStatus={approvalStatus}
            registrationData={registrationData}
            warrantyClaim={warrantyData?.warrantyClaim || null}
            showReceiptUploadOption={showReceiptUploadOption}
            lifetime={warrantyData.duration.value === 'LIFETIME'}
            issueDate={dateFormat(warrantyData.purchaseDate, 'mmm d, yyyy')}
            expiryDate={
              approvalStatus === ApprovalStatus.PENDING
                ? 'N/A'
                : dateFormat(warrantyData.expirationDate, 'mmm d, yyyy')
            }
            mulberryContent={
              mulberryCoverageDetails &&
              mulberryCoverageDetails?.available &&
              mulberryCoverageDetails?.purchased
                ? {
                    policyTermsUrl:
                      mulberryCoverageDetails?.purchaseDetails?.lineItems[0]
                        ?.warranty?.policy_terms_url || '',
                    termLength:
                      mulberryCoverageDetails?.purchaseDetails?.lineItems[0]
                        ?.warranty?.duration_months || '0',
                    purchasedDate:
                      mulberryCoverageDetails?.purchaseDetails?.purchasedDate,
                  }
                : null
            }
          />
        </div>
      )}
    </ModuleWrapper>
  );
};

export default WarrantyDrawer;
