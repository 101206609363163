import React from 'react';
import { User } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import { isPreviewMode } from 'utils/preview';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import {
  ModuleInfoType,
  SweepstakesModuleType,
} from 'types/ProductDetailsType';
import RegistrationLogin from 'components/registration/RegistrationLogin';
import UnitTagLoginForm from 'components/forms/UnitTagLoginForm';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';

interface AuthDrawerProps {
  onAuthComplete: (provider: string, user?: User) => Promise<void>;
  html: string | undefined | null;
  customLegalText?: string;
  isAutodeploy?: boolean;
  productName?: string;
  brandName?: string;
  animated?: boolean;
  margin?: string;
}

const AuthDrawer: React.FC<AuthDrawerProps> = ({
  html,
  animated,
  brandName,
  productName,
  isAutodeploy,
  onAuthComplete,
  customLegalText,
}) => {
  const {
    slug,
    productDetails,
    setOpenAutoDeployModal,
    setUserClosedAutoDeployDrawer,
  } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.authDrawer',
  });

  const isUnitTagFlow =
    productDetails?.unitTagDetails && productDetails.unitTagDetails.registered;

  const getSweepstakesGameRules = () => {
    if (productDetails) {
      let sweepstakesModule: ModuleInfoType | undefined =
        productDetails?.modules?.find(
          (module) => module.type === 'SWEEPSTAKES_MODULE'
        );
      if (sweepstakesModule) {
        let sweepstakesModuleInfo: SweepstakesModuleType =
          sweepstakesModule.moduleInfo as SweepstakesModuleType;
        return sweepstakesModuleInfo?.gameRules ? true : false;
      } else return false;
    } else return false;
  };

  return (
    <Animated
      isVisible
      animateOnMount
      className='w-full h-full'
      animationIn='slideInRight'
      animationOut='slideOutLeft'
      animationInDuration={200}
      animationOutDuration={200}
    >
      <div
        className={`relative w-full h-full flex flex-col items-center justify-start overflow-x-hidden ${
          animated
            ? 'pt-6 pb-8 border border-solid border-t-0 border-l-0 border-r-0 border-b-2 border-authDrawerBorder'
            : 'pb-8'
        }`}
      >
        {html && !isUnitTagFlow && <HtmlWrapper html={html} />}
        {isUnitTagFlow && (
          <div className='w-full mt-4'>
            <HtmlWrapper
              html={`
                <h3>${t('unitTag.heading')}</h3>
                <p>${t('unitTag.description')}</p>
              `}
            />
          </div>
        )}
        <div className='relative w-full h-full flex flex-col duration-300'>
          {isUnitTagFlow ? (
            <UnitTagLoginForm isDrawer handleLogin={onAuthComplete} />
          ) : (
            <RegistrationLogin onRegistration={onAuthComplete} />
          )}
          <div className='flex py-2'>
            {customLegalText ? (
              <HtmlWrapper
                html={
                  isAutodeploy
                    ? customLegalText?.replaceAll(
                        '<a href=',
                        '<a target="_blank" href='
                      )
                    : customLegalText
                }
                styles='mt-4 [&_p]:text-left [&_p]:text-xxs [&_p]:text-darkGray'
              />
            ) : (
              <p tabIndex={0} className='text-xxs text-left text-darkGray'>
                {!isUnitTagFlow ? (
                  <>
                    {t('termsAndConditions.part1')}
                    {brandName}
                    {t('termsAndConditions.part2')}
                    {t('termsAndConditions.brijBrand')}
                    <Link
                      to={
                        isPreviewMode()
                          ? `/c/${slug}`
                          : productDetails
                          ? `/p/${slug}`
                          : '/p/BRIJ'
                      }
                      onClick={() => {
                        if (isAutodeploy) {
                          setOpenAutoDeployModal(false);
                          setUserClosedAutoDeployDrawer(true);
                        }
                      }}
                      className='underline cursor-pointer'
                    >
                      {t('termsAndConditions.linkText')}
                    </Link>
                    {getSweepstakesGameRules() && ` ${t('and')} `}
                    {getSweepstakesGameRules() && (
                      <Link
                        to={
                          isPreviewMode()
                            ? `/c/${slug}`
                            : productDetails
                            ? `/s/${slug}`
                            : '/s/BRIJ'
                        }
                        onClick={() => {
                          if (isAutodeploy) {
                            setOpenAutoDeployModal(false);
                            setUserClosedAutoDeployDrawer(true);
                          }
                        }}
                        className='underline cursor-pointer'
                      >
                        {t('sweepstakesRules')}
                      </Link>
                    )}
                    {'.'}
                    {t('termsAndConditions.part3')}
                  </>
                ) : (
                  <>{t('termsAndConditions.unitTagDisclaimer')}</>
                )}
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='underline cursor-pointer'
                  href={`mailto:support@brij.it?subject=Help with ${brandName} ${productName} (${slug})`}
                >
                  {t('termsAndConditions.helpEmail')}
                </a>
                {'.'}
              </p>
            )}
          </div>
        </div>
      </div>
    </Animated>
  );
};

export default AuthDrawer;
