import React from 'react';
import { hex2rgba } from 'utils/hexToRgba';
import { SocialsType } from 'types/SocialsType';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as WhatsAppIconDark } from 'assets/icons/svg/whatsapp-icon-dark.svg';
import { ReactComponent as LinkedInIconDark } from 'assets/icons/svg/linkedin-icon-dark.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/svg/instagram-icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/svg/facebook-icon.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/svg/linkedin-icon.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/svg/whatsapp-icon.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/svg/youtube-icon.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/svg/twitter-icon.svg';
import { ReactComponent as ThreadsIcon } from 'assets/icons/svg/threads-icon.svg';
import { ReactComponent as DiscordIcon } from 'assets/icons/svg/discord-icon.svg';
import { ReactComponent as TiktokIcon } from 'assets/icons/svg/tiktok-icon.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/svg/phone-icon.svg';
import { ReactComponent as MailIcon } from 'assets/icons/svg/mail-icon.svg';

type SocialIconProps = {
  socials: SocialsType;
  lightIcons?: boolean;
  darkIcons?: boolean;
  containerStyles?: string;
};

const SocialIcons: React.FC<SocialIconProps> = ({
  socials,
  containerStyles,
  darkIcons = false,
  lightIcons = false,
}) => {
  const { brandTheme, buttonStyle } = useThemeContext();
  const iconColor: string = darkIcons ? '#FFFFFF' : brandTheme;

  const getCornerStyle = (cornerStyle: string) => {
    switch (cornerStyle) {
      case 'largeCorner':
        return 'rounded-[56px]';
      case 'smallCorner':
        return 'rounded-[10px]';
      case 'noCorner':
        return 'rounded-none';
      default:
        return 'rounded-[56px]';
    }
  };

  return (
    <div
      className={`relative w-full flex items-center justify-center gap-3 px-5 py-3 ${containerStyles}`}
    >
      {socials?.phone && (
        <a
          aria-label='Phone'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={`tel:+${socials.phone}`}
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <PhoneIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
      {socials?.email && (
        <a
          aria-label='Email'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={`mailto:${socials.email}`}
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <MailIcon aria-hidden fill={iconColor} width={23} />
        </a>
      )}
      {socials?.tiktok && (
        <a
          aria-label='Tiktok'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.tiktok.includes('https://') ||
            socials.tiktok.includes('http://')
              ? socials.tiktok
              : `https://${socials.tiktok}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <TiktokIcon aria-hidden fill={iconColor} width={23} />
        </a>
      )}
      {socials?.twitter && (
        <a
          aria-label='Twitter'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.twitter.includes('https://') ||
            socials.twitter.includes('http://')
              ? socials.twitter
              : `https://${socials.twitter}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <TwitterIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
      {socials?.instagram && (
        <a
          aria-label='Instagram'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.instagram.includes('https://') ||
            socials.instagram.includes('http://')
              ? socials.instagram
              : `https://${socials.instagram}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <InstagramIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
      {socials?.threads && (
        <a
          aria-label='Threads'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.threads.includes('https://') ||
            socials.threads.includes('http://')
              ? socials.threads
              : `https://${socials.threads}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <ThreadsIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
      {socials?.facebook && (
        <a
          aria-label='Facebook'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.facebook.includes('https://') ||
            socials.facebook.includes('http://')
              ? socials.facebook
              : `https://${socials.facebook}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <FacebookIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
      {socials?.linkedin && (
        <a
          aria-label='LinkedIn'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.linkedin.includes('https://') ||
            socials.linkedin.includes('http://')
              ? socials.linkedin
              : `https://${socials.linkedin}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          {darkIcons ? (
            <LinkedInIconDark aria-hidden width={35} />
          ) : (
            <LinkedInIcon aria-hidden fill={iconColor} width={35} />
          )}
        </a>
      )}
      {socials?.youtube && (
        <a
          aria-label='YouTube'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-1 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={
            socials.youtube.includes('https://') ||
            socials.youtube.includes('http://')
              ? socials.youtube
              : `https://${socials.youtube}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <YouTubeIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
      {socials?.whatsapp && (
        <a
          aria-label='WhatsApp'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={`https://wa.me/${socials.whatsapp}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          {darkIcons ? (
            <WhatsAppIconDark aria-hidden fill={iconColor} width={35} />
          ) : (
            <WhatsAppIcon aria-hidden fill={iconColor} width={35} />
          )}
        </a>
      )}
      {socials?.discord && (
        <a
          aria-label='Discord'
          className={`h-[51px] w-full min-w-[30px] flex flex-1 items-center justify-center py-2 duration-200 focus-visible:ring-2 focus-visible:ring-primary 
          ${getCornerStyle(buttonStyle)}`}
          href={socials.discord}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: darkIcons
              ? 'rgba(0, 0, 0, 0.3)'
              : lightIcons
              ? '#F6F8FF'
              : hex2rgba(brandTheme, 0.05),
          }}
        >
          <DiscordIcon aria-hidden fill={iconColor} width={35} />
        </a>
      )}
    </div>
  );
};

export default SocialIcons;
