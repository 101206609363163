import React, { useState, useEffect, ChangeEvent } from 'react';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import InputMask from 'react-input-mask';

type TelInputProps = {
  name?: string;
  className?: string;
  value: string | undefined;
  mask?: string;
  styles?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const TelInput: React.FC<TelInputProps> = ({
  name,
  className,
  value,
  onChange,
  required,
  mask = '',
  styles,
  placeholder = '',
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { inputStyle } = useThemeContext();

  useEffect(() => {
    if (value) setIsFocused(true);
    else setIsFocused(false);
  }, [value]);

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  return (
    <InputMask
      mask={mask}
      value={value}
      disabled={disabled}
      onChange={(event) => onChange && onChange(event)}
    >
      {() => (
        <div
          className={`
            relative w-full h-input flex items-center px-6 border-2 border-solid border-input transition-all ease-linear duration-300 
            [&_input]:z-20 [&_input]:w-full [&_input]:border-0 [&_input]:bg-transparent 
            [&_input]:active:bg-transparent [&_input]:active:outline-none 
            [&_input]:hover:bg-transparent [&_input]:hover:outline-none 
            ${isFocused ? 'bg-white' : 'bg-lightgray'} 
            ${disabled && 'opacity-70'} 
            ${getCornerStyle()} 
            ${styles}
          `}
        >
          <span
            className={`z-40 absolute text-muted transition-all duration-300 pointer-events-none ${
              isFocused
                ? 'text-xxxs px-1 bg-white -translate-x-0.5 -translate-y-6 opacity-100 rounded'
                : 'text-[0.9rem] bg-transparent translate-x-0 translate-y-0 opacity-80'
            }`}
          >
            {placeholder}
          </span>
          <input
            type='tel'
            name={name}
            autoComplete='tel'
            required={required}
            aria-label={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => !value && setIsFocused(false)}
            className={`disabled:pointer-events-none disabled:text-disbaledInput ${
              className || ''
            }`}
          />
        </div>
      )}
    </InputMask>
  );
};

export default TelInput;
