import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { Animated } from 'react-animated-css';
import { getImageMeta } from 'utils/productUtils';
import { useGlobal } from 'context/global/GlobalContext';
import { PossibleExperienceType } from 'types/ExperienceTypes';
import ProgressiveImage from 'react-progressive-image';

type MediaBackgroundProps = {
  mainDrawerOpen?: boolean;
};

const MediaBackground: React.FC<MediaBackgroundProps> = ({
  mainDrawerOpen,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);
  const { productDetails, appZoom } = useGlobal();
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const name: string = productDetails?.brand?.name || '';
  const productExperience: boolean =
    productDetails?.product.qrCodeDestination ===
    PossibleExperienceType.PRODUCT_EXPERIENCE
      ? true
      : false;

  const customHubColorSelected: boolean =
    (!productExperience && productDetails?.product.customHubColorSelected) ||
    false;
  const customDigitalHubColor: string =
    productDetails?.product.customDigitalHubColor || '';

  const customSmsOptInColor: string =
    productDetails?.product.smsOptIn?.customColor || '';
  const customSmsOptInColorSelected: boolean =
    productDetails?.product.smsOptIn?.customColorSelected || false;

  const mediaSource: string =
    productDetails?.product.qrCodeDestination ===
    PossibleExperienceType.SMS_OPT_IN
      ? productDetails.product.smsOptIn?.image || ''
      : productDetails?.product?.image || '';

  let { mediaToUse, mediaType } = getImageMeta(
    mediaSource,
    renderOptimizedMedia
  );

  useEffect(() => {
    if (videoPlayer && videoPlayer.current) videoPlayer.current.load();
  }, [productDetails]);

  useEffect(() => {
    if (
      videoPlayer &&
      videoPlayer.current &&
      videoPlayer.current !== undefined &&
      mediaSource
    ) {
      const handlePlayPause = () => {
        const isPlaying =
          videoPlayer?.current?.currentTime !== undefined &&
          videoPlayer?.current?.currentTime > 0 &&
          !videoPlayer.current?.paused &&
          !videoPlayer.current?.ended &&
          videoPlayer?.current?.readyState > 2;
        if (isPlaying && mainDrawerOpen) {
          videoPlayer.current?.pause();
        } else if (
          !isPlaying &&
          !mainDrawerOpen &&
          videoPlayer?.current?.readyState !== undefined &&
          videoPlayer?.current?.readyState > 2
        ) {
          videoPlayer.current?.play();
        }
      };
      videoPlayer.current.addEventListener('loadeddata', handlePlayPause);
      handlePlayPause();
      const currentVideoPlayer = videoPlayer.current;
      return () =>
        currentVideoPlayer?.removeEventListener('loadeddata', handlePlayPause);
    }
  }, [mainDrawerOpen, mediaSource]);

  const backgroundColor: string = useMemo(() => {
    return productDetails?.product.customTheme &&
      productDetails?.product.customThemeConfig?.customBgColor
      ? productDetails?.product.customThemeConfig?.customBgColor
      : productDetails?.brand?.customBgColor
      ? productDetails?.brand?.customBgColor
      : 'white';
  }, [productDetails]);

  if (
    productDetails?.product.qrCodeDestination ===
      PossibleExperienceType.SMS_OPT_IN &&
    customSmsOptInColorSelected
  ) {
    return (
      <div
        className='absolute top-0 left-0 w-full h-full flex items-center justify-center'
        style={{ background: customSmsOptInColor }}
      />
    );
  }

  if (customHubColorSelected) {
    return (
      <div
        className='absolute top-0 left-0 w-full h-full flex items-center justify-center'
        style={{ background: customDigitalHubColor }}
      />
    );
  }

  return (
    <Fragment>
      {!!productDetails && !mediaToUse ? (
        <div
          className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center'
          style={{
            background: backgroundColor,
            height: `${window.innerHeight / appZoom}px`,
          }}
        />
      ) : (
        !!productDetails &&
        (mediaType.includes('mp4') || mediaType.includes('webm') ? (
          <div
            className={`absolute top-0 left-0 w-full flex flex-col items-center justify-center ${
              productExperience ? 'h-[calc(100%-168px)]' : 'h-full'
            }`}
          >
            <video
              loop
              muted
              autoPlay
              controls={false}
              ref={videoPlayer}
              playsInline={true}
              className='w-full h-full object-cover'
              onError={(e) => (e.currentTarget.src = mediaSource)}
            >
              <source src={mediaToUse} type={mediaType} />
            </video>
          </div>
        ) : (
          <ProgressiveImage
            onError={(error) => {
              setRenderOptimizedMedia(false);
              console.log(error);
            }}
            src={mediaToUse}
            placeholder={name}
          >
            {(src: string, loading: boolean) => {
              return !!loading ? (
                <div
                  className={`w-full ${
                    productExperience ? 'pb-[168px]' : 'p-0'
                  }`}
                  style={{
                    background: backgroundColor,
                    height: `${window.innerHeight / appZoom}px`,
                  }}
                />
              ) : (
                <div
                  aria-hidden
                  className={`flex min-h-full max-h-full w-full ${
                    productExperience ? 'pb-[168px]' : 'p-0'
                  }`}
                  style={{
                    background: backgroundColor,
                    height: `${window.innerHeight / appZoom}px`,
                  }}
                >
                  <Animated
                    isVisible
                    animationIn='fadeIn'
                    animationOut='fadeIn'
                    animationInDelay={500}
                    className='relative w-full h-full'
                  >
                    <img
                      src={src}
                      alt={name}
                      aria-hidden
                      className='relative w-full min-h-full max-h-full m-auto object-cover'
                    />
                  </Animated>
                </div>
              );
            }}
          </ProgressiveImage>
        ))
      )}
    </Fragment>
  );
};

export default MediaBackground;
