import axios from 'axios';
import { ProductDetailsType } from 'types/ProductDetailsType';

function listenChanges(
  onChanges: (
    data: {
      text: string;
      key: string;
    }[]
  ) => void
) {
  // Regex to check if text is a html tag
  const htmlTagRegex = /<[^>]*>/g;

  // Add the 'txt-node' class to all elements that have a direct text node
  function ensureTxtNodeClass() {
    function hasDirectTextNode(element: any) {
      for (var i = 0; i < element.childNodes.length; i++) {
        if (
          element.childNodes[i].nodeType === Node.TEXT_NODE &&
          element.childNodes[i].textContent.trim() !== ''
        ) {
          return true;
        }
      }
      return false;
    }

    function traverse(element: any) {
      if (
        hasDirectTextNode(element) &&
        Array.from(element.classList).indexOf('txt-node') === -1
      ) {
        element.classList.add('txt-node');
        // Inject an id to the class starting with bjtxtid-xxxx
        element.classList.add(
          'bjtxtid-' + Math.random().toString(36).substr(2)
        );
      }
      for (var i = 0; i < element.children.length; i++) {
        traverse(element.children[i]);
      }
    }

    traverse(document.body);
  }

  // Get all text nodes
  function getAllTextNodes() {
    const txtNodes = document.querySelectorAll('.txt-node');
    const txtNodesText = Array.from(txtNodes)
      .map(function (node) {
        // Get the node bjtxtid from the node's class list
        const bjtxtid = Array.from(node.classList).find(function (className) {
          return className.startsWith('bjtxtid-');
        });
        const hasBeenTranslated = Array.from(node.classList).find(function (
          className
        ) {
          return className.startsWith('bj-translated');
        });
        return {
          hasBeenTranslated: hasBeenTranslated,
          text: node.innerHTML,
          bjtxtid: bjtxtid,
        };
      })
      .filter(function (data) {
        return (
          !htmlTagRegex.test(data.text as string) && !data.hasBeenTranslated
        );
      });
    return {
      txtNodesText: txtNodesText,
      txtNodes: txtNodes,
    };
  }

  function replaceTextNodeWithTranslation(brijjtxtid: any, translation: any) {
    const txtNodes = document.querySelectorAll('.txt-node');
    txtNodes.forEach(function (node) {
      // Get the node bjtxtid from the node's class list
      const bjtxtid = Array.from(node.classList).find(function (className) {
        return className.startsWith('bjtxtid-');
      });

      if (bjtxtid === brijjtxtid) {
        node.textContent = translation;
        node.classList.add('bj-translated');
      }
    });
  }

  // Create a new MutationObserver instance
  const observer = new MutationObserver(function (mutationsList, observer) {
    // Ensure that all elements with direct text nodes have the corresponding class
    ensureTxtNodeClass();

    // Get all nodes texts
    const txtNodesText = getAllTextNodes().txtNodesText;

    onChanges(
      txtNodesText
        .filter(function (item) {
          const buffElement = document.querySelector('.' + item.bjtxtid);
          if (buffElement?.tagName.match(/script/i)) {
            return false;
          }
          if (!item.text || !item.bjtxtid) {
            return false;
          }
          return true;
        })
        .map(function (item) {
          return {
            text: item.text as string,
            key: item.bjtxtid as string,
          };
        })
    );
  });

  // Start observing the document's body for changes
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
}

// let translationsMap: any = {}
let selectorKeysToValueKeyMap: any = {};
let valueKeyCacheMap: any = {};

const w: any = window;

async function translateItems(
  items: any[],
  productDetails: ProductDetailsType,
  language: string
) {
  w.cachedTranslations = w.cachedTranslations || {};
  const pendingItemsWithNoCachedTranslation = items.filter(function (item) {
    return !w.cachedTranslations[item.text];
  });
  if (!pendingItemsWithNoCachedTranslation.length) {
    return Object.fromEntries(
      items.map(function (item, index) {
        return [item.key, w.cachedTranslations[item.text]];
      })
    );
  }
  const { data: out } = await axios.post(
    `/app_api/products/${productDetails.tag.slug}/translate`,
    {
      language,
      textsList: pendingItemsWithNoCachedTranslation.slice(0, 30),
    }
  );
  out.textList.forEach(function (item: any) {
    const initItem = items.find(function (i) {
      return i.key === item.key;
    });
    w.cachedTranslations[initItem.text] = item.text;
  });
  return Object.fromEntries(
    out.textList.map(function (item: any) {
      return [item.key, item.text];
    })
  );
}

let isHandlingTranslationChanges = false;
let alreadyTranslatedKeysMap: any = {};

export default function handleTranslation({
  productDetails,
}: {
  productDetails: ProductDetailsType;
}) {
  if (w._alreadyHandling) {
    console.log('Translation handling already initialized..');
    return;
  }
  w._alreadyHandling = true;

  // Setup listener
  const currentLanguage =
    localStorage.getItem('default-language') ||
    navigator.language.split('-')[0];
  valueKeyCacheMap = {};
  listenChanges(async (changes) => {
    // if (isHandlingTranslationChanges) {
    //   return;
    // }
    const notTranslatedChangesNumber = changes.filter(function (item) {
      return !alreadyTranslatedKeysMap[item.key];
    }).length;
    if (!notTranslatedChangesNumber) {
      return;
    }
    console.log('Handling translation..');
    isHandlingTranslationChanges = true;
    const expectedItemsMap: any = await translateItems(
      changes,
      productDetails,
      currentLanguage
    );
    changes.forEach(function (item) {
      var currentNode: any = document.querySelector('.' + item.key);
      if (!currentNode) {
        return;
      }
      if (
        // currentNode.innerText != expectedItemsMap[item.key] &&
        currentNode.innerHTML != expectedItemsMap[item.key] &&
        !!expectedItemsMap[item.key]
      ) {
        // currentNode.innerText = expectedItemsMap[item.key];
        currentNode.innerHTML = expectedItemsMap[item.key];
      }
    });
    isHandlingTranslationChanges = false;
    changes.forEach(function (item) {
      alreadyTranslatedKeysMap[item.key] = true;
    });
  });
}
