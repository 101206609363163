import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getImageMeta } from 'utils/productUtils';
import { ReturnedCollectionType } from '../../../types/ProductDetailsType';
import placeholder from 'assets/images/png/image-placeholder.png';
import ProgressiveImage from 'react-progressive-image';
import dayjs from 'dayjs';

type ProductThumbnailProps = {
  item: ReturnedCollectionType;
  onClick: () => void;
};

const ProductThumbnail: React.FC<ProductThumbnailProps> = ({
  item,
  onClick,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);
  const { t } = useTranslation('translation', { keyPrefix: 'collection' });

  const collectionLabel = (item: ReturnedCollectionType) => {
    if (item.expirationDate)
      return `${t('expireStatus')} ${dayjs(item.expirationDate).format(
        'MM/DD/YYYY'
      )}`;
    if (item.purchaseDate)
      return `${t('purchaseStatus')} ${dayjs(item.purchaseDate).format(
        'MM/DD/YYYY'
      )}`;
    if (item.registrationDate)
      return `${t('registerStatus')} ${dayjs(item.registrationDate).format(
        'MM/DD/YYYY'
      )}`;
  };

  let mediaLink: string = item?.image || '';
  let { mediaToUse, mediaType } = getImageMeta(mediaLink, renderOptimizedMedia);

  return (
    <button
      type='button'
      onClick={() => onClick()}
      aria-label='Product Thumbnail'
      className='relative w-full h-full pt-[100%] justify-self-center cursor-pointer focus-visible:outline focus-outline:black/60'
    >
      {mediaType.includes('mp4') || mediaType.includes('webm') ? (
        <video
          className='absolute w-full h-full top-0 left-0 m-auto object-cover'
          loop
          muted
          autoPlay
          aria-hidden
          controls={false}
          playsInline={true}
          onError={(e) => (e.currentTarget.src = mediaLink)}
        >
          <source type={mediaType} src={mediaToUse} />
        </video>
      ) : (
        <ProgressiveImage
          placeholder={placeholder}
          src={mediaToUse}
          onError={() => {
            setRenderOptimizedMedia(false);
            console.log('Optimized Image Error');
          }}
        >
          {(src: string, loading: boolean) => (
            <img
              src={src}
              aria-hidden
              alt={mediaToUse}
              className={`absolute w-full h-full top-0 left-0 m-auto object-contain duration-300 ${
                loading ? 'opacity-50' : 'opacity-100'
              }`}
            />
          )}
        </ProgressiveImage>
      )}
      <div className='absolute bottom-0 left-0 px-2.5 py-1.5 text-white text-xs bg-primary rounded-tr-2xl'>
        <p tabIndex={0}>{collectionLabel(item)}</p>
      </div>
    </button>
  );
};

export default ProductThumbnail;
