import React, { useState, useRef, RefObject, useMemo } from 'react';
import logGtmEvent, { PossibleGtmEventNames } from 'utils/logGtmEvent';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { ContentGateType } from 'types/ContentGateType';
import { Scrollbar } from 'react-scrollbars-custom';
import { getLocalStorage } from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Animated } from 'react-animated-css';
import ContentGateBackground from 'components/atomic/ContentGateBackground';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import useElementSize from 'hooks/useElementSize';
import Button from 'components/atomic/Button';

type ContentGateProps = {
  data: ContentGateType;
};

const ContentGate: React.FC<ContentGateProps> = ({ data }) => {
  const [warningDisplay, setWarningDisplay] = useState<boolean>(false);
  const [isFocused, setIsFocused] =
    useState<RefObject<HTMLInputElement> | null>(null);
  const [birthDate, setBirthDate] = useState({
    day: '',
    month: '',
    year: '',
  });

  const { secondaryBrandColor, inputStyle } = useThemeContext();
  const { contentGateDisplay, toggleContentGateDisplay, productDetails } =
    useGlobal();
  const { t } = useTranslation('translation', { keyPrefix: 'contentGate' });
  const [containerRef, { height }] = useElementSize();

  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filteredValue = event.target.value.replace(/[^0-9]/g, '');
    if (parseInt(filteredValue) > 12) filteredValue = '12';
    else if (filteredValue === '00') filteredValue = '01';
    setBirthDate({ ...birthDate, month: filteredValue });
    if (event.target.value.length === 2) dayRef.current?.focus();
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filteredValue = event.target.value.replace(/[^0-9]/g, '');
    if (parseInt(filteredValue) > 31) filteredValue = '31';
    else if (filteredValue === '00') filteredValue = '01';
    setBirthDate({ ...birthDate, day: filteredValue });
    if (event.target.value.length === 2) yearRef.current?.focus();
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentYear = new Date().getFullYear();
    let filteredValue = event.target.value.replace(/[^0-9]/g, '');
    if (filteredValue.length === 4 && parseInt(filteredValue) < 1900)
      filteredValue = '1900';
    else if (parseInt(filteredValue) > currentYear)
      filteredValue = `${currentYear}`;
    if (event.target.value.length < 5)
      setBirthDate({ ...birthDate, year: filteredValue });
  };

  const handleBackspace = (
    event: React.KeyboardEvent<HTMLInputElement>,
    targetRef: React.RefObject<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && event.currentTarget.value.length === 0) {
      targetRef.current?.focus();
    }
  };

  const validateAge = (day: string, month: string, year: string): boolean => {
    if (!day || !month || !year) return false;
    else if (
      year.length < 4 ||
      parseInt(year) < 1900 ||
      parseInt(year) > new Date().getFullYear()
    ) {
      showToast({
        message: t('invalidYearInput'),
        type: 'error',
      });
      return false;
    }
    const today = new Date();
    const birthDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
    return age >= data?.minimumAge;
  };

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  const getTargetRef = (ref: RefObject<HTMLInputElement>) => {
    if (ref === yearRef) return dayRef;
    else if (ref === dayRef) return monthRef;
    else return ref;
  };

  const inputField = (
    ref: RefObject<HTMLInputElement>,
    value: string,
    fieldName: string,
    placeholder: string,
    maxLength: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => (
    <input
      ref={ref}
      type='text'
      value={value}
      inputMode='decimal'
      aria-label={fieldName}
      className={`
        w-full h-input relative py-4 text-center rounded-full leading-5 border-2 border-solid duration-100 transition-all 
        ${
          isFocused === ref
            ? 'bg-white border-input'
            : 'bg-lightgray border-lightgray'
        } 
        ${isMobile ? 'text-base' : 'text-sm'} 
        ${getCornerStyle()}
      `}
      onKeyDown={(event) => handleBackspace(event, getTargetRef(ref))}
      onFocus={() => setIsFocused(ref)}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
    />
  );

  const background: string = useMemo(() => {
    if (data?.customColor) return data?.customColor;
    return productDetails?.product.customTheme &&
      productDetails?.product.customThemeConfig?.customBgColor
      ? productDetails?.product.customThemeConfig?.customBgColor
      : productDetails?.brand?.customBgColor
      ? productDetails?.brand?.customBgColor
      : 'white';
  }, [productDetails, data]);

  return contentGateDisplay &&
    getLocalStorage().getItem('ageGateChecked') !== 'true' ? (
    <div
      style={{ backgroundColor: background }}
      className='z-50 absolute top-0 left-0 w-full h-full max-h-full flex flex-col justify-between items-stretch'
    >
      <ContentGateBackground />
      <Scrollbar
        className='
            [&_.ScrollbarsCustom-Content]:!relative 
            [&_.ScrollbarsCustom-Content]:!w-full 
            [&_.ScrollbarsCustom-Content]:!h-full 
          '
      >
        <div
          className='relative w-[calc(100%_-_40px)] h-auto max-w-96 flex flex-col items-center justify-center p-6 gap-3 mt-4 mx-auto bg-white rounded-3xl'
          ref={containerRef}
          style={{
            background: data.showBackground
              ? data.backgroundColor
              : 'transparent',
            boxShadow: 'box-shadow: 0px 4px 10px 0px #00001F',
            marginTop:
              height + 32 < window.innerHeight
                ? `${(window.innerHeight - height) / 2}px`
                : '32px',
          }}
        >
          {data?.content && <HtmlWrapper html={data.content} />}
          <div className='relative w-full flex flex-col items-center justify-center pt-2'>
            {data?.verifyAge && data?.birthdayRequired && (
              <div className='relative w-full flex items-stretch justify-center mx-auto mb-3 gap-1.5'>
                {inputField(
                  monthRef,
                  birthDate.month,
                  'month-input',
                  'MM',
                  2,
                  handleMonthChange
                )}
                {inputField(
                  dayRef,
                  birthDate.day,
                  'day-input',
                  'DD',
                  2,
                  handleDayChange
                )}
                {inputField(
                  yearRef,
                  birthDate.year,
                  'year-input',
                  'YYYY',
                  4,
                  handleYearChange
                )}
              </div>
            )}
            <Button
              variant='dark'
              title={data?.verifyAge ? t('enterButton') : t('continueButton')}
              styles={warningDisplay ? '!mb-3' : '!mb-0'}
              color={secondaryBrandColor}
              onClick={() => {
                if (!data?.verifyAge) {
                  getLocalStorage().setItem(
                    'ageGateChecked',
                    JSON.stringify(true)
                  );
                  logGtmEvent(PossibleGtmEventNames.CONTENT_GATE_CHECKED);
                  toggleContentGateDisplay(false);
                } else if (data?.verifyAge && !data?.birthdayRequired) {
                  getLocalStorage().setItem(
                    'ageGateChecked',
                    JSON.stringify(true)
                  );
                  logGtmEvent(PossibleGtmEventNames.CONTENT_GATE_CHECKED);
                  toggleContentGateDisplay(false);
                } else if (data?.verifyAge && data?.birthdayRequired) {
                  if (
                    !validateAge(birthDate.day, birthDate.month, birthDate.year)
                  ) {
                    setWarningDisplay(true);
                  } else if (
                    validateAge(birthDate.day, birthDate.month, birthDate.year)
                  ) {
                    getLocalStorage().setItem(
                      'ageGateChecked',
                      JSON.stringify(true)
                    );
                    getLocalStorage().setItem(
                      'ageGateBirthDate',
                      JSON.stringify(
                        `${birthDate.month}/${birthDate.day}/${birthDate.year}`
                      )
                    );
                    logGtmEvent(PossibleGtmEventNames.CONTENT_GATE_CHECKED);
                    toggleContentGateDisplay(false);
                  }
                }
              }}
            />
            {data?.minimumAge && (
              <Animated
                isVisible={warningDisplay}
                animationIn='headShake'
                animationOut='slideOutDown'
                animationInDelay={100}
                animationInDuration={300}
                animationOutDuration={300}
                className={`${
                  warningDisplay ? 'h-4' : 'h-0'
                } duration-100 transition-all`}
              >
                <p className='relative w-full text-xxs font-medium text-center text-warning overflow-x-hidden'>
                  {t('warningMessage').replace(
                    '{{ageLimit}}',
                    data.minimumAge.toString()
                  )}
                </p>
              </Animated>
            )}
          </div>
        </div>
      </Scrollbar>
    </div>
  ) : null;
};

export default ContentGate;
