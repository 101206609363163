import React, { useState, useEffect, useCallback } from 'react';
import { VariantDetails, ShoppingModuleType } from 'types/ProductDetailsType';
import { ReactComponent as CartIcon } from 'assets/icons/svg/cart.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { useCartContext } from 'context/CartContext/CartContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { OptionItemType } from 'types/ProductDetailsType';
import { validateOption } from 'utils/validateOption';
import { CartItem } from 'types/ShoppingExperience';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { UrlParam } from 'types/Misc';
import QuantityController from 'components/atomic/QuantityController';
import CustomSelectInput from 'components/atomic/CustomSelectInput';
import placeholder from 'assets/images/png/placeholder.png';
import ProgressiveImage from 'react-progressive-image';
import Button from 'components/atomic/Button/Button';
import useElementSize from 'hooks/useElementSize';
import hash from 'object-hash';

type ShopProductType = {
  brandId: string;
  brandName: string;
  product: ShoppingModuleType;
  productDescription?: string;
};

const ShopProduct: React.FC<ShopProductType> = ({
  product,
  brandId,
  brandName,
}) => {
  const [option, updateOption] = useState<{ [key: string]: string }>({});
  const [currentProduct, setCurrentProduct] =
    useState<ShoppingModuleType | null>(null);
  const [choosenVariant, setChoosenVariant] = useState<VariantDetails | null>(
    null
  );
  const [isValidCombo, setIsValidCombo] = useState<boolean | null>(true);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [expandDescription, toggleExpandDescription] = useState<boolean>(false);

  const { cartProducts, setCartProducts, openCart, setRecommendations } =
    useCartContext();
  const [optionsContainerRef, { height: optionsContainerHeight }] =
    useElementSize();
  const [contentRef, { height: contentHeight }] = useElementSize();
  const { buttonStyle, secondaryBrandColor } = useThemeContext();
  const { id } = useParams<UrlParam>();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.shopDrawer',
  });

  useEffect(() => {
    if (product) {
      let currentVariant: VariantDetails | undefined =
        product?.variantDetails?.find(
          (variant: VariantDetails) => variant.id.slice(29) === id
        );
      if (currentVariant) {
        setCurrentProduct(product);
        setChoosenVariant(currentVariant);
      } else if (
        product.recommendations &&
        product.recommendations.length > 0
      ) {
        for (let x = 0; x < product.recommendations.length; x++) {
          let recommendation: ShoppingModuleType = product.recommendations[x];
          if (
            recommendation.variantDetails &&
            recommendation?.variantDetails.length > 0
          ) {
            for (let y = 0; y < recommendation?.variantDetails?.length; y++) {
              let currentVariant: VariantDetails =
                recommendation.variantDetails[y];
              if (currentVariant.id.slice(29) === id) {
                setCurrentProduct(recommendation);
                setChoosenVariant(currentVariant);
              }
            }
          }
        }
      }
    }
  }, [id, product]);

  useEffect(() => {
    if (choosenVariant && choosenVariant.options)
      updateOption(choosenVariant.options);
  }, [choosenVariant]);

  useEffect(() => {
    if (!product.recommendations) setRecommendations([]);

    if (
      currentProduct &&
      product.recommendations &&
      product.recommendations.length > 0
    ) {
      let updatedRecommendations: ShoppingModuleType[] =
        product.recommendations.filter(
          (recommendation: ShoppingModuleType) =>
            recommendation.id !== currentProduct.id
        );
      const filteredRecommendations =
        updatedRecommendations &&
        updatedRecommendations.filter((recommendation: ShoppingModuleType) => {
          return (
            recommendation.variantDetails &&
            recommendation.variantDetails.every(
              (variant: VariantDetails) =>
                !cartProducts.some(
                  (cartItem: CartItem) => cartItem.id === variant.id.slice(29)
                )
            )
          );
        });
      setRecommendations([...filteredRecommendations]);
    }
  }, [id, product, cartProducts, currentProduct, setRecommendations]);

  useEffect(() => {
    if (currentProduct && option) {
      const objHash = hash(option);
      const variant = currentProduct.variantDetails?.find(
        (item) => item.objectHash === objHash
      );
      if (variant) {
        if (variant.options) {
          if (
            Object.keys(variant.options).length <= 1 ||
            (Object.keys(variant.options)[0] === 'Title' &&
              Object.values(variant.options)[0] === 'Default Title')
          )
            setIsValidCombo(true);
          else setIsValidCombo(variant.inventoryQuantity > 0);
        }
        setChoosenVariant(variant);
        setSelectedQuantity(1);
      }
    }
  }, [id, option, currentProduct]);

  const handleQuantity = useCallback((value: string) => {
    if (!value) setSelectedQuantity(0);
    else setSelectedQuantity(parseInt(value));
  }, []);

  const addToCart = useCallback(
    (choosenVariant: VariantDetails) => {
      let updatedCart: CartItem[] = cartProducts;
      if (cartProducts.length > 0 && cartProducts[0].brandId !== brandId) {
        updatedCart = [];
        toast.info(t('cartResetNotification') + brandName);
      }
      if (updatedCart.length > 0) {
        let existingProduct: CartItem | undefined = undefined;
        existingProduct = updatedCart.find(
          (cartItem: CartItem) => choosenVariant.id.slice(29) === cartItem.id
        );
        if (existingProduct) {
          updatedCart = updatedCart.filter(
            (cartItem: CartItem) => cartItem.id !== existingProduct?.id
          );
          let updatedProduct: CartItem = existingProduct;
          updatedProduct['quantity'] =
            existingProduct.quantity + selectedQuantity;
          updatedCart.push(updatedProduct);
          setCartProducts(updatedCart);
          openCart();
        } else {
          let cartItem: CartItem = {
            id: choosenVariant.id.slice(29),
            quantity: selectedQuantity,
            product: choosenVariant,
            brandId: brandId,
          };
          updatedCart.push(cartItem);
          setCartProducts([...updatedCart]);
          openCart();
        }
      } else {
        let cartItem: CartItem = {
          id: choosenVariant.id.slice(29),
          quantity: selectedQuantity,
          product: choosenVariant,
          brandId: brandId,
        };
        updatedCart.push(cartItem);
        setCartProducts([...updatedCart]);
        openCart();
      }
    },
    [
      brandId,
      brandName,
      cartProducts,
      openCart,
      selectedQuantity,
      setCartProducts,
      t,
    ]
  );

  const getCallToAction = useCallback(() => {
    if (!isValidCombo) return t('cartButton.invalidCombo');
    else if (selectedQuantity < 1) return t('cartButton.invalidQuantity');
    else
      return choosenVariant && currentProduct
        ? `${t('cartButton.callToAction')} ${` • ${
            choosenVariant && currentProduct
              ? `${(
                  parseFloat(
                    !currentProduct?.isDiscountAvailable &&
                      choosenVariant?.contextualPricing?.amount
                      ? choosenVariant?.contextualPricing?.amount
                      : choosenVariant?.discountedContextualPricing || ''
                  ) * selectedQuantity
                ).toLocaleString(navigator.language, {
                  style: 'currency',
                  currency: choosenVariant?.contextualPricing?.currencyCode
                    ? choosenVariant?.contextualPricing?.currencyCode
                    : 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : ''
          }`}`
        : '';
  }, [t, isValidCombo, currentProduct, choosenVariant, selectedQuantity]);

  const getCornerStyle = () => {
    switch (buttonStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-2xl';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case 'noCorner':
        return 'rounded-none';
      default:
        return 'rounded-2xl';
    }
  };

  return currentProduct ? (
    <div
      ref={optionsContainerRef}
      id='shop-options-container'
      className='relative w-full h-full flex flex-col items-center justify-start overflow-auto'
    >
      <div className='w-full flex flex-row items-center justify-between mt-2 mb-4'>
        <ProgressiveImage
          src={
            choosenVariant
              ? choosenVariant.image
              : currentProduct.defaultVariantDetails.image
          }
          placeholder={placeholder}
        >
          {(src: string, loading: boolean) => (
            <img
              src={src}
              alt='product'
              aria-label={`Image for ${choosenVariant?.name || 'product'}`}
              tabIndex={0}
              className={`h-auto w-full max-w-[40%] object-contain aspect-square border border-solid border-gray-100 duration-300 
                ${loading ? 'opacity-50' : 'opacity-100'} 
                ${getCornerStyle()}
              `}
            />
          )}
        </ProgressiveImage>
        <div className='w-[55%] h-full flex flex-col justify-center px-2'>
          <div className='w-full flex flex-col mb-4'>
            <p
              tabIndex={0}
              aria-label={choosenVariant?.name || ''}
              className='text-base leading-5 font-semibold text-black'
            >
              {choosenVariant?.name}
            </p>
            <div
              role='presentation'
              className='w-max flex flex-row items-center'
            >
              {currentProduct.isDiscountAvailable ? (
                <>
                  <span
                    tabIndex={0}
                    className='mt-[3px] text-xs font-medium line-through text-muted'
                    aria-label={`Pre discount price ${
                      choosenVariant &&
                      parseFloat(
                        choosenVariant?.contextualPricing?.amount || '0'
                      ).toLocaleString(navigator.language, {
                        style: 'currency',
                        currency: choosenVariant?.contextualPricing
                          ?.currencyCode
                          ? choosenVariant?.contextualPricing?.currencyCode
                          : 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                    `}
                  >
                    {choosenVariant &&
                      parseFloat(
                        choosenVariant?.contextualPricing?.amount || '0'
                      ).toLocaleString(navigator.language, {
                        style: 'currency',
                        currency: choosenVariant?.contextualPricing
                          ?.currencyCode
                          ? choosenVariant?.contextualPricing?.currencyCode
                          : 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </span>
                  <span
                    tabIndex={0}
                    className='text-[0.9rem] font-semibold pl-1.5'
                    aria-label={`Price ${
                      choosenVariant &&
                      parseFloat(
                        choosenVariant?.discountedContextualPricing!
                      ).toLocaleString(navigator.language, {
                        style: 'currency',
                        currency: choosenVariant?.contextualPricing
                          ?.currencyCode
                          ? choosenVariant?.contextualPricing?.currencyCode
                          : 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                    `}
                  >
                    {choosenVariant &&
                      parseFloat(
                        choosenVariant?.discountedContextualPricing!
                      ).toLocaleString(navigator.language, {
                        style: 'currency',
                        currency: choosenVariant?.contextualPricing
                          ?.currencyCode
                          ? choosenVariant?.contextualPricing?.currencyCode
                          : 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </span>
                </>
              ) : (
                <span
                  tabIndex={0}
                  className='text-[0.9rem] font-semibold'
                  aria-label={`Price ${
                    choosenVariant &&
                    parseFloat(
                      choosenVariant?.contextualPricing?.amount || '0'
                    ).toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: choosenVariant?.contextualPricing?.currencyCode
                        ? choosenVariant?.contextualPricing?.currencyCode
                        : 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                  `}
                >
                  {choosenVariant &&
                    parseFloat(
                      choosenVariant?.contextualPricing?.amount || '0'
                    ).toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: choosenVariant?.contextualPricing?.currencyCode
                        ? choosenVariant?.contextualPricing?.currencyCode
                        : 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </span>
              )}
            </div>
          </div>
          <QuantityController
            onChange={handleQuantity}
            value={String(selectedQuantity)}
          />
        </div>
      </div>
      <div
        className={`relative w-full flex flex-col 
          ${
            contentHeight > 50 && !expandDescription
              ? 'h-[50px] min-h-[50px] overflow-y-hidden mb-4'
              : 'h-max min-h-max overflow-y-visible mb-8'
          }
        `}
      >
        <p
          aria-label={`${
            currentProduct.description ||
            currentProduct.alternateProductDescription
          }`}
          className='w-full h-max leading-4 text-xs text-left text-darkGray whitespace-pre-line'
          ref={contentRef}
          tabIndex={0}
        >
          {currentProduct.description ||
            currentProduct.alternateProductDescription}
        </p>
        {contentHeight > 50 && (
          <p
            tabIndex={0}
            aria-label={
              expandDescription
                ? t('productDetails.showLess')
                : t('productDetails.showMore')
            }
            className={`block absolute right-0 text-xs leading-4 pl-2 text-primary underline cursor-pointer bg-white shadow-expandText ${
              expandDescription ? '-bottom-4' : 'bottom-0.5'
            }`}
            onClick={() => toggleExpandDescription(!expandDescription)}
          >
            {expandDescription
              ? t('productDetails.showLess')
              : t('productDetails.showMore')}
          </p>
        )}
      </div>
      <div className='w-full flex flex-col px-0.5 duration-300'>
        {currentProduct.allOptions && (
          <div className='w-full flex flex-col items-center justify-start mb-4'>
            {currentProduct.allOptions.map(
              (optionItem: OptionItemType, index: any) =>
                validateOption(optionItem) && (
                  <CustomSelectInput
                    type='text'
                    name={optionItem.name}
                    selected={{
                      value: option[optionItem.name],
                      label: option[optionItem.name],
                    }}
                    options={optionItem.values.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(option) =>
                      updateOption((prev) => ({
                        ...prev,
                        [optionItem.name]: String(option.value),
                      }))
                    }
                    styles='!mb-4 last:!mb-0'
                    key={`${optionItem.name}-${index}`}
                    placeholder={optionItem.name}
                    scrollIntoView={{
                      enabled: true,
                      parentHeight: optionsContainerHeight,
                      parentContainerId: 'shop-options-container',
                    }}
                  />
                )
            )}
          </div>
        )}
        <div className='w-full flex flex-col items-center justify-start mb-24'>
          <Button
            transition
            variant='dark'
            title={getCallToAction()}
            disabled={!isValidCombo || selectedQuantity < 1}
            onClick={() => choosenVariant && addToCart(choosenVariant)}
            content={
              <CartIcon
                width={22}
                className='mr-2'
                fill={secondaryBrandColor || '#FFFFFF'}
              />
            }
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default ShopProduct;
