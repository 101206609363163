import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormDetailModel } from 'types/FormTypes';
import DatePicker from 'components/atomic/DatePicker';
import FormImage from './FormImage';

type FormDatePickerProps = {
  name: string;
  formRef: FormikProps<any> | null;
  formData: FormDetailModel;
  updateFormField: (name: string, value: any) => void;
};

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  formRef,
  formData,
  updateFormField,
}) => {
  useEffect(() => {
    formRef?.validateForm();
  }, []);
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.datePicker',
  });

  useEffect(() => {
    updateFormField(name, formRef?.values[name] || new Date());
  }, []);

  const { property } = formData;

  // TODO: Remove this when we allow config to be passed from the backend
  const restrictFutureDate =
    property?.name === 'purchaseDate' && property?.global;

  return (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        tabIndex={0}
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p
          tabIndex={0}
          className='mt-1.5 mb-4 text-left text-xs font-semibold leading-formSubtitle text-muted'
        >
          {formData.subText}
        </p>
      )}
      <DatePicker
        label={t('placeholder')}
        errorMessage={t('errorMessage')}
        minDate={new Date('1910-01-01')}
        maxDate={restrictFutureDate ? new Date() : new Date('2100-01-01')}
        selectedDate={formRef?.values[name] || new Date()}
        changeDate={(date) => updateFormField(name, date)}
      />
    </div>
  );
};

export default FormDatePicker;
