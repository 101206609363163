import React from 'react';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import ProgressiveImage from 'react-progressive-image';
import { Animated } from 'react-animated-css';

type FormImageProps = {
  image: string;
  alt: string;
};

const FormImage: React.FC<FormImageProps> = ({ image, alt }) => (
  <div className='w-full flex-col mb-3 justify-center'>
    <ProgressiveImage src={image} placeholder=''>
      {(src: string, loading: boolean) =>
        loading ? (
          <div className='w-full flex min-h-[13.75rem]'>
            <LoadingIndicator />
          </div>
        ) : (
          <Animated isVisible animationIn='fadeIn' animationOut='fadeIn'>
            <img
              src={src}
              alt={alt}
              aria-label={alt}
              className={`relative w-full max-h-[13.75rem] object-cover duration-300 ${
                loading ? 'opacity-0' : 'opacity-100'
              }`}
            />
          </Animated>
        )
      }
    </ProgressiveImage>
  </div>
);

export default FormImage;
