import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/svg/info-icon.svg';

type TooltipProps = {
  label: string;
  content: string;
};

const Tooltip: React.FC<TooltipProps> = ({ label, content }) => {
  const [showTooltipContent, toggleTooltipContent] = useState<boolean>(false);

  const handleMouseEnter = () => toggleTooltipContent(true);
  const handleMouseLeave = () => toggleTooltipContent(false);
  const handleTouch = () => toggleTooltipContent((prev: boolean) => !prev);

  return (
    <div className='w-full relative flex flex-row items-center justify-between py-3 px-4 gap-3 rounded-lg bg-minimalGray'>
      <p
        tabIndex={0}
        className='font-medium text-xs text-left text-extraDarkGray'
      >
        {label}
      </p>
      <InfoIcon
        width={14}
        height={14}
        tabIndex={0}
        aria-label={content}
        className='cursor-pointer min-w-[14px] min-h-[14px]'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouch}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
      />
      {showTooltipContent && (
        <div
          className='z-10 w-max max-w-[75%] absolute bottom-full right-3.5 p-2.5 text-gray text-left text-xxs rounded-lg shadow bg-white border border-solid border-lightBorder [&_a]:underline'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
};

export default Tooltip;
