import React from 'react';
import { useHistory } from 'react-router-dom';
import { ShoppingModuleType } from 'types/ProductDetailsType';
import { useCartContext } from 'context/CartContext/CartContext';
import { CartItem } from 'types/ShoppingExperience';

type ProductGridProps = {
  slug: string;
};

const ProductGrid: React.FC<ProductGridProps> = ({ slug }) => {
  const { cartProducts, recommendations } = useCartContext();
  const history = useHistory();

  return (
    <div className='relative grid grid-cols-2 auto-rows-min gap-x-4 gap-y-4 w-full h-full overflow-auto'>
      {cartProducts.map((cartItem: CartItem) => (
        <button
          type='button'
          key={cartItem.id}
          className='w-full flex flex-col rounded-xl border-2 border-solid border-transparent focus-visible:border-black/60'
          onClick={() => {
            history.push(`/c/${slug}/shop/${cartItem.id}`);
          }}
        >
          <div className='relative w-auto rounded-lg overflow-hidden'>
            <div className='z-[1] absolute right-0 top-[calc(100%-24px)] w-7 h-6 flex items-center justify-center p-2 cursor-pointer bg-primary rounded-md rounded-tl-md rounded-tr-none rounded-b-none'>
              <p className='text-xs text-center text-white'>
                {cartItem.quantity}
              </p>
            </div>
            <img
              src={cartItem.product.image}
              alt={cartItem.product.name}
              className='object-cover aspect-square cursor-pointer'
              height='100%'
              width='100%'
            />
          </div>
          <p className='mt-4 mb-1.5 text-sm font-medium leading-4 cursor-pointer text-left text-black hover:underline'>
            {cartItem.product.name}
          </p>
          <div className='flex items-center gap-1'>
            {cartItem?.product?.discountedContextualPricing &&
              cartItem?.product?.discountedContextualPricing !== '0' && (
                <span className='text-xxs line-through text-muted'>
                  {`${parseFloat(
                    cartItem?.product?.contextualPricing?.amount || '0'
                  ).toLocaleString(navigator.language, {
                    style: 'currency',
                    currency: cartItem?.product?.contextualPricing?.currencyCode
                      ? cartItem?.product?.contextualPricing?.currencyCode
                      : 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </span>
              )}
            <span className='font-semibold text-xs leading-5 text-black'>
              {`${parseFloat(
                cartItem?.product?.discountedContextualPricing
                  ? cartItem?.product?.discountedContextualPricing
                  : cartItem?.product?.contextualPricing?.amount || '0'
              ).toLocaleString(navigator.language, {
                style: 'currency',
                currency: cartItem?.product?.contextualPricing?.currencyCode
                  ? cartItem?.product?.contextualPricing?.currencyCode
                  : 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </span>
          </div>
        </button>
      ))}
      {recommendations &&
        recommendations.map((product: ShoppingModuleType) => (
          <button
            type='button'
            key={product.id}
            className='w-full flex flex-col rounded-xl border-2 border-solid border-transparent focus-visible:border-black/60'
            onClick={() => {
              history.push(
                `/c/${slug}/shop/${product.defaultVariantDetails.id.slice(29)}`
              );
            }}
          >
            <div className='relative w-auto rounded-lg overflow-hidden'>
              <img
                src={product.defaultVariantDetails.image}
                alt={product.defaultVariantDetails.name}
                className='object-cover aspect-square cursor-pointer'
                height='100%'
                width='100%'
              />
            </div>
            <p className='mt-4 mb-1.5 text-sm font-medium leading-4 cursor-pointer text-left text-black hover:underline'>
              {product.defaultVariantDetails.name}
            </p>
            <div className='flex items-center gap-1'>
              {product.isDiscountAvailable ? (
                <>
                  <span
                    className='text-xxs text-muted line-through'
                    aria-label={`Pre discount price ${parseFloat(
                      product?.defaultVariantDetails?.contextualPricing
                        ?.amount || '0'
                    ).toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: product?.defaultVariantDetails
                        ?.contextualPricing?.currencyCode
                        ? product?.defaultVariantDetails?.contextualPricing
                            ?.currencyCode
                        : 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  >
                    {`${parseFloat(
                      product?.defaultVariantDetails?.contextualPricing
                        ?.amount || '0'
                    ).toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: product?.defaultVariantDetails
                        ?.contextualPricing?.currencyCode
                        ? product?.defaultVariantDetails?.contextualPricing
                            ?.currencyCode
                        : 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </span>
                  <span
                    className='font-semibold text-xs leading-5 text-black'
                    aria-label={`Price ${parseFloat(
                      product?.defaultVariantDetails
                        ?.discountedContextualPricing || '0'
                    ).toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: product?.defaultVariantDetails
                        ?.contextualPricing?.currencyCode
                        ? product?.defaultVariantDetails?.contextualPricing
                            ?.currencyCode
                        : 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  >
                    {`${parseFloat(
                      product?.defaultVariantDetails
                        ?.discountedContextualPricing || '0'
                    ).toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: product?.defaultVariantDetails
                        ?.contextualPricing?.currencyCode
                        ? product?.defaultVariantDetails?.contextualPricing
                            ?.currencyCode
                        : 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </span>
                </>
              ) : (
                <span className='font-semibold text-xs leading-5 text-black'>
                  {`${parseFloat(
                    product?.defaultVariantDetails?.contextualPricing?.amount ||
                      '0'
                  ).toLocaleString(navigator.language, {
                    style: 'currency',
                    currency: product?.defaultVariantDetails?.contextualPricing
                      ?.currencyCode
                      ? product?.defaultVariantDetails?.contextualPricing
                          ?.currencyCode
                      : 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </span>
              )}
            </div>
          </button>
        ))}
    </div>
  );
};

export default ProductGrid;
