import { useState, useEffect, useRef, useCallback } from 'react';
import { useCartContext } from 'context/CartContext/CartContext';
import { useAPICacheContext } from 'context/APICacheContext/APICacheContext';
import {
  ModuleInfoType,
  SweepstakesModuleType,
} from 'types/ProductDetailsType';
import { ReactComponent as SweepstakesIcon } from 'assets/icons/svg/sweepstakes.svg';
import { ReactComponent as LoadingIndicator } from 'assets/icons/svg/loading-small.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/svg/external.svg';
import { ReactComponent as CollectionIcon } from 'assets/icons/svg/collection.svg';
import { ReactComponent as TranslateIcon } from 'assets/icons/svg/translate.svg';
import { ReactComponent as PrivacyIcon } from 'assets/icons/svg/privacy.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/svg/log-out.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/svg/person.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/svg/log-in.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/svg/trash.svg';
import { ReactComponent as CartIcon } from 'assets/icons/svg/cart.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { ignoreIfPreview, isPreviewMode } from 'utils/preview';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { Link, useLocation } from 'react-router-dom';
import { getLocalStorage } from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoutesHashMap } from 'routes';
import { useAPI } from 'utils/api';
import useFirebase from 'hooks/useFirebase';
import brijLogo from 'assets/logos/svg/brij.svg';
import IconButton from 'components/atomic/IconButton';
import poweredByLogo from 'assets/images/svg/powered-by.svg';

const defaultLanguage =
  localStorage.getItem('default-language') || navigator.language.split('-')[0];

const SideMenu = () => {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { menuStyle } = useThemeContext();
  const { getAuth, signOut } = useFirebase();
  const { invalidateCache } = useAPICacheContext();
  const { cartProducts, cartQuantity, openCart } = useCartContext();
  const { t } = useTranslation('translation', { keyPrefix: 'sideMenu' });
  const {
    user,
    slug,
    token,
    setSlug,
    logEvent,
    isMenuOpen,
    desktopView,
    setIsNewUser,
    setIsMenuOpen,
    registrationId,
    formRegistration,
    setRegistrationId,
    getPersonalDetails,
    setpersonalDetails,
    sweepstakesGameRules,
    setAutoDeployIsNewUser,
    termsAndPrivacyDetails,
    productDetails: details,
    getCurrentRegistrationId,
  } = useGlobal();
  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();
  const firstRegistrationId = getCurrentRegistrationId();

  const getSweepstakesGameRules = () => {
    if (details) {
      let sweepstakesModule: ModuleInfoType | undefined =
        details?.modules?.find(
          (module) => module.type === 'SWEEPSTAKES_MODULE'
        );
      if (sweepstakesModule) {
        let sweepstakesModuleInfo: SweepstakesModuleType =
          sweepstakesModule.moduleInfo as SweepstakesModuleType;
        return sweepstakesModuleInfo?.gameRules
          ? sweepstakesModuleInfo?.gameRules
          : '';
      } else return '';
    } else return '';
  };

  const handleLogoutButtonClicked = useCallback(() => {
    if (error !== '') setError('');
    // form registraion clean up
    getLocalStorage().removeItem(
      `brij-form-${slug}-${formRegistration?.id}-complete`
    );
    setSlug(null);
    setLoading(true);
    setIsAnimating(false);
    signOut(auth)
      .then(() => {
        setLoading(false);
        showToast({ message: t('signOutToastMessage'), type: 'success' });
        // for now bc of phantom useApi rendender turn global state false.
        setAutoDeployIsNewUser(false);
        setIsNewUser(false);
        invalidateCache();
        getLocalStorage().clear();
        setpersonalDetails(null);
        history.push(RoutesHashMap.Login.path);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
    setIsAnimating(false);
  }, [setIsAnimating, auth, error, t, setSlug, invalidateCache]);

  const redirectToCollection = useCallback(async () => {
    await getPersonalDetails();
    invalidateCache();
    showToast({
      message: `${
        details?.product?.displayName || details?.product?.name || slug
      }${t('productRemoved')}`,
      type: 'success',
    });
    setSlug(null);
    setRegistrationId(null);
    setIsAnimating(false);
    getLocalStorage().removeItem(
      `brij-form-${slug}-${formRegistration?.id}-filled`
    );
    history.push(RoutesHashMap.Collection.path);
  }, [
    t,
    slug,
    history,
    setSlug,
    setIsAnimating,
    invalidateCache,
    setRegistrationId,
    getPersonalDetails,
    formRegistration?.id,
    details?.product?.name,
    details?.product?.displayName,
  ]);

  const onRemoveProductError = (error: any) => {
    showToast({ message: error.message, type: 'error' });
    setIsAnimating(false);
  };

  const [removeProduct] = useAPI({
    endpoint: 'user/remove_product',
    method: 'DELETE',
    onSuccess: redirectToCollection,
    onError: onRemoveProductError,
  });

  const showRemoveProductButton =
    details?.product?.registeredToCurrentUser &&
    window.location.pathname.includes(`/c/${slug}`);

  const userToUse = user;
  const showTranslationOption = details?.basicTranslationEnabled;

  useEffect(() => {
    if (isMenuOpen) setIsAnimating(true);
  }, [isMenuOpen]);

  useEffect(() => {
    if (!isAnimating) setTimeout(() => setIsMenuOpen(false), 300);
  }, [isAnimating, setIsMenuOpen]);

  const websiteUrl = useCallback(() => {
    if (details?.brand.website) {
      return details?.brand.website;
    } else if (termsAndPrivacyDetails?.website) {
      return termsAndPrivacyDetails?.website;
    } else if (sweepstakesGameRules?.website) {
      return sweepstakesGameRules?.website;
    } else return '';
  }, [details, termsAndPrivacyDetails, sweepstakesGameRules]);

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'lg:rounded-2xl';
      case CornerStyle.ROUNDED_CORNERS:
        return 'lg:rounded-sm1';
      case CornerStyle.SQUARE_CORNERS:
        return 'lg:rounded-none';
      default:
        return 'lg:rounded-2xl';
    }
  };

  const validateHamburgerOption = (
    optionEnabled: boolean | undefined | null
  ) => {
    if (location.pathname.includes(`/c/${slug}`))
      return optionEnabled ? true : false;
    else return true;
  };

  return !isMenuOpen ? null : (
    <div role='dialog' aria-modal='true' className='relative w-full h-full'>
      <div
        onClick={() => setIsAnimating(false)}
        className={`absolute z-[99] top-0 right-0 w-full h-full duration-200 bg-black ${
          isAnimating
            ? 'opacity-40 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        }`}
      />
      <div
        className={`z-[99] absolute w-3/4 max-w-[285px] bg-primary overflow-hidden duration-200 
          ${
            desktopView
              ? `lg:h-[calc(100%_-_3rem)] lg:top-6 ${getCornerStyle()}`
              : 'h-full top-0 rounded-tl-[26px]'
          } 
          ${
            isAnimating
              ? `${desktopView ? 'lg:right-6' : 'right-0'}`
              : '-right-[75%]'
          }
        `}
      >
        <div className='relative w-full h-full flex flex-col items-end pl-3 pr-2 pt-5 pb-7 overflow-auto'>
          <IconButton
            size={18}
            styles='mr-1.5'
            variant='blank'
            iconName='close'
            iconColor='#FFFFFF'
            onClick={() => setIsAnimating(false)}
          />
          <ul
            aria-hidden
            className='relative w-full h-full flex flex-col items-end overflow-auto pr-4 pt-3 mt-3 gap-6'
          >
            {cartProducts && cartProducts.length > 0 && (
              <li aria-hidden>
                <button
                  type='button'
                  className='relative w-full flex flex-row items-start justify-end pl-1 gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                  onClick={() => {
                    openCart();
                    setIsAnimating(false);
                  }}
                >
                  {t('myCart')}
                  <span aria-hidden className='min-w-6'>
                    <CartIcon aria-hidden width={24} fill='#FFFFFF' />
                  </span>
                  <div
                    aria-label={` ${cartQuantity}-items`}
                    className='absolute -top-2 -right-2 w-[19px] h-[19px] flex items-center justify-center rounded-full bg-white'
                  >
                    <span
                      aria-hidden
                      className='font-semibold text-[9px] leading-[13px] text-black'
                    >
                      {cartQuantity}
                    </span>
                  </div>
                </button>
              </li>
            )}
            {!userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.signInSignOut
              ) && (
                <Link
                  aria-label='Sign In'
                  to={isPreviewMode() ? `/c/${slug}` : RoutesHashMap.Login.path}
                  onClick={() => ignoreIfPreview(() => setIsAnimating(false))}
                  className='pl-1 rounded focus-visible:outline focus-visible:outline-black/60'
                >
                  <li
                    aria-hidden
                    className='w-full flex flex-row items-start justify-end gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer'
                  >
                    {t('signIn')}
                    <span aria-hidden className='w-6'>
                      <LoginIcon aria-hidden width={24} />
                    </span>
                  </li>
                </Link>
              )}
            {userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.myProfile
              ) && (
                <li aria-hidden>
                  <Link
                    to={
                      isPreviewMode()
                        ? `/c/${slug}`
                        : RoutesHashMap.Profile.path
                    }
                    onClick={() => ignoreIfPreview(() => setIsAnimating(false))}
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    aria-label='My Profile'
                  >
                    <span
                      aria-hidden
                      className='text-right whitespace-normal mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'
                    >
                      {t('myProfile')}
                    </span>
                    <span className='min-w-6'>
                      <ProfileIcon aria-hidden width={24} />
                    </span>
                  </Link>
                </li>
              )}
            {userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.myCollection
              ) &&
              location.pathname !== RoutesHashMap.Collection.path && (
                <li aria-hidden>
                  <Link
                    to={
                      isPreviewMode()
                        ? `/c/${slug}`
                        : RoutesHashMap.Collection.path
                    }
                    aria-label='My Collection'
                    onClick={() => ignoreIfPreview(() => setIsAnimating(false))}
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                  >
                    <span
                      aria-hidden
                      className='text-right whitespace-normal leading-6 tracking-[0.1px] text-lg text-white text-right break-words'
                    >
                      {t('myCollection')}
                    </span>
                    <span aria-hidden className='min-w-6'>
                      <CollectionIcon aria-hidden width={24} />
                    </span>
                  </Link>
                </li>
              )}
            {showRemoveProductButton &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.removeFromCollection
              ) && (
                <li aria-hidden>
                  <button
                    type='button'
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        removeProduct(
                          {
                            tag: slug,
                            registrationId:
                              registrationId || firstRegistrationId,
                          },
                          token
                        );
                        setIsAnimating(false);
                      })
                    }
                  >
                    <span className='text-right whitespace-normal leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {t('removeProduct')}
                    </span>
                    <span aria-hidden className='min-w-6'>
                      <TrashIcon aria-hidden width={24} />
                    </span>
                  </button>
                </li>
              )}
            {(window.location.pathname.includes(`/c/`) ||
              window.location.pathname.includes(`/p/`) ||
              window.location.pathname.includes(`/s/`)) &&
              window.location.pathname !== `/p/BRIJ` &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.visitWebsite
              ) &&
              websiteUrl && (
                <li aria-hidden>
                  <a
                    aria-label='Visit Website'
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    href={
                      websiteUrl().includes('https://') ||
                      websiteUrl().includes('http://')
                        ? websiteUrl()
                        : `https://${websiteUrl()}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        setIsAnimating(false);
                        logEvent({
                          eventType: 'ENGAGEMENTS',
                          event: 'WEBSITE_VISITS',
                          data: {
                            url:
                              websiteUrl().includes('https://') ||
                              websiteUrl().includes('http://')
                                ? websiteUrl()
                                : `https://${websiteUrl()}`,
                          },
                        });
                      })
                    }
                  >
                    <span
                      aria-hidden
                      className='text-right whitespace-normal mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'
                    >
                      {t('visitWebsite')}
                    </span>
                    <span aria-hidden className='min-w-6'>
                      <ExternalLinkIcon aria-hidden width={24} />
                    </span>
                  </a>
                </li>
              )}
            {validateHamburgerOption(
              details?.brand.webappMenuOptions.customLinkOne
            ) &&
              details?.brand.webappMenuOptions.customLinkOneContent
                ?.linkUrl && (
                <li aria-hidden>
                  <a
                    aria-label={
                      details.brand.webappMenuOptions.customLinkOneContent
                        .linkName
                    }
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    href={
                      details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                        'https://'
                      ) ||
                      details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                        'http://'
                      )
                        ? details.brand.webappMenuOptions.customLinkOneContent
                            .linkUrl
                        : `https://${details.brand.webappMenuOptions.customLinkOneContent.linkUrl}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        setIsAnimating(false);
                        logEvent({
                          eventType: 'ENGAGEMENTS',
                          event: 'WEBSITE_VISITS',
                          data: details?.brand.webappMenuOptions
                            .customLinkOneContent?.linkUrl
                            ? {
                                url:
                                  details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                                    'https://'
                                  ) ||
                                  details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                                    'http://'
                                  )
                                    ? details.brand.webappMenuOptions
                                        .customLinkOneContent.linkUrl
                                    : `https://${details.brand.webappMenuOptions.customLinkOneContent.linkUrl}`,
                              }
                            : '',
                        });
                      })
                    }
                  >
                    <span
                      aria-hidden
                      className='mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'
                    >
                      {
                        details.brand.webappMenuOptions.customLinkOneContent
                          .linkName
                      }
                    </span>
                    <span aria-hidden className='min-w-6'>
                      <ExternalLinkIcon aria-hidden width={24} />
                    </span>
                  </a>
                </li>
              )}
            {validateHamburgerOption(
              details?.brand.webappMenuOptions.customLinkTwo
            ) &&
              details?.brand.webappMenuOptions.customLinkTwoContent
                ?.linkUrl && (
                <li aria-hidden>
                  <a
                    aria-label={
                      details.brand.webappMenuOptions.customLinkTwoContent
                        .linkName
                    }
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    href={
                      details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                        'https://'
                      ) ||
                      details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                        'http://'
                      )
                        ? details.brand.webappMenuOptions.customLinkTwoContent
                            .linkUrl
                        : `https://${details.brand.webappMenuOptions.customLinkTwoContent.linkUrl}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        setIsAnimating(false);
                        logEvent({
                          eventType: 'ENGAGEMENTS',
                          event: 'WEBSITE_VISITS',
                          data: details?.brand.webappMenuOptions
                            .customLinkTwoContent?.linkUrl
                            ? {
                                url:
                                  details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                                    'https://'
                                  ) ||
                                  details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                                    'http://'
                                  )
                                    ? details.brand.webappMenuOptions
                                        .customLinkTwoContent.linkUrl
                                    : `https://${details.brand.webappMenuOptions.customLinkTwoContent.linkUrl}`,
                              }
                            : '',
                        });
                      })
                    }
                  >
                    <span
                      aria-hidden
                      className='mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'
                    >
                      {
                        details.brand.webappMenuOptions.customLinkTwoContent
                          .linkName
                      }
                    </span>
                    <span aria-hidden className='min-w-6'>
                      <ExternalLinkIcon aria-hidden width={24} />
                    </span>
                  </a>
                </li>
              )}
            {validateHamburgerOption(
              details?.brand?.webappMenuOptions.termsAndPrivacy
            ) &&
              !location.pathname.includes('/p/') && (
                <Link
                  className='pl-1 rounded focus-visible:outline focus-visible:outline-black/60'
                  to={details ? `/p/${details?.brand?.id}` : '/p/BRIJ'}
                  onClick={() => setIsAnimating(false)}
                  aria-label='Terms and Privacy'
                >
                  <li
                    aria-hidden
                    className='w-full flex flex-row items-start justify-end gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer'
                  >
                    {t('termsAndPrivacy')}
                    <span aria-hidden className='w-6'>
                      <PrivacyIcon aria-hidden width={24} />
                    </span>
                  </li>
                </Link>
              )}
            {!location.pathname.includes('/s/') &&
              (getSweepstakesGameRules() ||
                sweepstakesGameRules?.gamerules) && (
                <Link
                  to={`/s/${slug}`}
                  aria-label='Sweepstakes Rules'
                  onClick={() => setIsAnimating(false)}
                  className='pl-1 rounded focus-visible:outline focus-visible:outline-black/60'
                >
                  <li
                    aria-hidden
                    className='w-full flex flex-row items-start justify-end gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer'
                  >
                    {t('sweepstakesRules')}
                    <span aria-hidden className='w-6'>
                      <SweepstakesIcon aria-hidden width={24} />
                    </span>
                  </li>
                </Link>
              )}
            {!!showTranslationOption && (
              <li aria-hidden>
                <button
                  aria-label='Translate'
                  className='relative w-full h-6 flex flex-row items-start justify-end pl-1 gap-3 tracking-[0.1px] text-lg text-white whitespace-nowrap cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                >
                  <select
                    style={{
                      background: 'none',
                      textAlign: 'right',
                    }}
                    defaultValue={defaultLanguage}
                    onChange={(e) => {
                      localStorage.setItem('default-language', e.target.value);
                      window.location.reload();
                    }}
                  >
                    <option value='en'>English</option>
                    <option value='es'>Español</option>
                    <option value='fr'>Français</option>
                    <option value='de'>Deutsch</option>
                    <option value='it'>Italiano</option>
                    <option value='pt'>Português</option>
                    <option value='ja'>日本語</option>
                    <option value='ko'>한국어</option>
                    <option value='zh'>中文</option>
                    <option value='hi'>हिन्दी</option>
                  </select>
                  {!desktopView && (
                    <span aria-hidden className='min-w-6'>
                      <TranslateIcon aria-hidden width={23} />
                    </span>
                  )}
                </button>
              </li>
            )}
            {userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.signInSignOut
              ) && (
                <li aria-hidden>
                  <button
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    onClick={() => ignoreIfPreview(handleLogoutButtonClicked)}
                    aria-label='Sign Out'
                    type='button'
                  >
                    <span
                      aria-hidden
                      className='mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'
                    >
                      {t('signOut')}
                    </span>
                    {loading ? (
                      <span aria-hidden className='w-6 min-w-6'>
                        <LoadingIndicator />
                      </span>
                    ) : (
                      <span aria-hidden className='w-6 min-w-6'>
                        <LogoutIcon width={24} />
                      </span>
                    )}
                  </button>
                </li>
              )}
          </ul>
          {!details?.brand?.minimizeBranding && (
            <span className='flex flex-row items-baseline pb-1 pt-5 pr-5'>
              {window.location.pathname.includes(`/c/${slug}`) && (
                <img
                  src={poweredByLogo}
                  alt='powered-by'
                  className='w-[111px] pr-2 translate-y-1'
                />
              )}
              <img
                className={`${
                  window.location.pathname.includes(`/c/${slug}`)
                    ? 'w-12'
                    : 'auto'
                }`}
                src={brijLogo}
                alt='brij-logo'
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
