import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDetailModel, NameConfig } from 'types/FormTypes';
import { FormikProps } from 'formik';
import Input from 'components/atomic/Input';
import FormImage from './FormImage';

type FormNameProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormName: React.FC<FormNameProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const [firstNameValue, setFirstName] = useState<string>('');
  const [lastNameValue, setLastName] = useState<string>('');

  const { firstName, lastName } = (formData?.config as NameConfig) || {};

  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer',
  });

  useEffect(() => {
    if (formRef?.values[name]) {
      if (firstName) {
        setFirstName(formRef.values[name]?.firstName);
      }

      if (lastName) {
        setLastName(formRef.values[name]?.lastName);
      }
    }
  }, [firstName, lastName]);

  const updateAddress = (value: any, key: string) => {
    switch (key) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
    }

    const existingValue = formRef?.values[name] || {};
    updateFormField(name, { ...existingValue, [key]: value });
  };

  return (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        tabIndex={0}
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p
          tabIndex={0}
          className='mt-1.5 mb-3 text-left text-xs font-semibold leading-formSubtitle text-muted'
        >
          {formData.subText}
        </p>
      )}
      <div className='relative w-full flex flex-col items-center justify-start gap-3'>
        {firstName && (
          <Input
            value={firstNameValue}
            placeholder={t('firstNamePlaceholder')}
            onChange={(e) => updateAddress(e.target.value, 'firstName')}
          />
        )}
        {lastName && (
          <Input
            value={lastNameValue}
            placeholder={t('lastNamePlaceholder')}
            onChange={(e) => updateAddress(e.target.value, 'lastName')}
          />
        )}
      </div>
    </div>
  );
};

export default FormName;
