import React from 'react';
import Dragbar from '../Dragbar/Dragbar';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { useCartContext } from 'context/CartContext/CartContext';

type DiscountBannerProps = {
  discountPercentage: number | undefined;
};

const DiscountBanner: React.FC<DiscountBannerProps> = ({
  discountPercentage,
}) => {
  const { desktopView } = useGlobal();
  const { cartOpen } = useCartContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.shopDrawer',
  });

  return (
    <div className='absolute top-0 left-0 right-0 pt-2 my-0 pb-[0.4rem] px-4 mx-auto w-max rounded-bl-2xl rounded-br-2xl bg-primary'>
      {!desktopView && <Dragbar />}
      <p
        tabIndex={cartOpen ? -1 : 0}
        aria-label={`${t('savingBanner.title')} ${discountPercentage! + '%'}`}
        className={`text-center whitespace-nowrap text-[0.8rem] text-secondary ${
          !desktopView && 'lg:pt-2'
        }`}
      >
        {t('savingBanner.title')} {discountPercentage! + '%'}
      </p>
    </div>
  );
};

export default DiscountBanner;
