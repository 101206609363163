export const resources = {
  en: {
    // English
    translation: {
      rotationScreen: {
        message:
          'Please rotate your device to portrait mode to view experience',
      },
      registration: {
        productRegistered: 'Product Registered!',
        signedUp: "You're Signed Up!",
        warrantyActivated: 'Warranty Activated!',
        completeProfile: 'Complete Profile',
        registrationError: 'Registration error, please try again',
      },
      socialLogin: {
        withGoogleText: 'with Google',
        continueGoogleButton: 'Continue with Google',
        signinError: 'There was an error. Please try signing in again',
        popupBlockedMessage:
          'was blocked by your browser. Please disable popup blockers or choose the email option to continue.',
        cookiesDisabled:
          'Please enable cookies to continue or try registering with email.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Receipt uploaded.',
        receiptUploadError: 'Error uploading receipt',
        noReceiptError: 'Please upload purchase receipt',
        firstnameInputPlaceholder: 'Enter first name...',
        firstnameFilledInputPlaceholder: 'First name',
        lastnameInputPlaceholder: 'Enter last name...',
        lastnameFilledInputPlaceholder: 'Last name',
        phoneNumberInputPlaceholder: 'Enter phone number...',
        emailInputPlaceholder: 'Enter email...',
        line1InputPlaceholder: 'Enter address line 1...',

        serialNumberInputPlaceholder: 'Enter serial number...',
        serialNumberFilledInputPlaceholder: 'Serial number',
        uploadReceiptButtonText: 'Upload Receipt',
        datepickerLabel: 'Purchase date...',
        datepickerFilledLabel: 'Purchase date',
        smsConsentRequired: 'Please check the consent box',
        invalidType: 'Invalid file type. Please upload an image or PDF',
        invalidDate: 'Invalid Date',
        retailChannelInputPlaceholder: 'Place of Purchase',
        quantitySelectorInputPlaceholder: 'Select Quantity',
        quantitySelectorInputOther: 'Other',
        failedToUpload: ' failed to upload',
        submitButtonText: 'Submit',
        receiptFailedError:
          'One or more of your receipts may not meet the requirements. Please consider re-uploading a clear, well-lit image of your receipt that includes the relevant line item(s).',
      },
      signIn: {
        pageTitle: 'Sign In to Brij',
        pageHeaderTitle: 'Sign In',
        withEmail: ' with Email',
        completeProfile: 'Complete Profile',
        useDifferentOption: 'Use a Different Option',
        continueWithEmail: 'Continue with Email',
        unitTagSignInLink: 'Request Sign In Link',
        checkYourEmail: 'Check your email',
        emailInputPlaceholder: 'Enter email...',
        emailInputFilledPlaceholder: 'Email',
        toastMessages: {
          invalidEmail: 'Please enter a valid email address',
          completeRecaptchaVerification:
            'Please complete Recpatcha Verification',
          cantVerifyLoginDetails: 'Not able to verify the login details',
          emailConsentRequired: 'Please check the email consent box',
          privacyConsentRequired: 'Please check the privacy consent box',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': 'Please provie a valid email',
        'auth/missing-email': 'Please provie an email',
        'auth/user-not-found': "User doesn't exist",
        'auth/wrong-password': 'Invalid email or password',
        'auth/internal-error': 'Server error occured',
        'auth/popup-closed-by-user':
          'Sign in canceled. Please complete the sign in process or choose email option to continue',
        'auth/cancelled-popup-request':
          'Sign in canceled. Please complete the sign in process or choose email option to continue',
        'auth/invalid-credential-or-provider-id': 'Invalid credentials',
        'auth/unauthorized-domain':
          'You are accessing the website from a non secure domain.',
        'auth/invalid-action-code': 'The code is invalid or expired',
        'auth/popup-blocked':
          'Popup blocked. Please enable popups or choose email option to continue.',
      },
      magicLink: {
        linkSentToastMessage:
          'Welcome back! Check your email for a sign-in link.',
        magicLinkError: 'An error occurred',
        redirecting: 'Redirecting...',
      },
      ageGate: {
        title: 'Confirm your age',
        subtitle: 'Are you {{ageLimit}} or older?',
        confirmationButton: 'YES',
        disapprovalButton: 'NO',
        warningMessage: 'You must be {{ageLimit}}+ to view this site!',
      },
      contentGate: {
        enterButton: 'ENTER',
        continueButton: 'CONTINUE',
        warningMessage: 'Sorry, you must be {{ageLimit}} or older',
        invalidYearInput: 'Please enter a valid year',
      },
      productDetails: {
        viewWarranty: 'View Warranty',
        leadInformation: {
          registeredTo: 'Registered To',
          warranty: 'Warranty',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Tap to search...',
        dropdownNoResults: 'No results',
      },
      smsOptIn: {
        receiveMessageButton: 'TEXT ME & GET STARTED',
        sendMessageButton: 'TEXT US & GET STARTED',
        trySendingAgain: 'Try again',
        sendMeText: 'Send me a text instead',
        sendUsText: 'Send us a text instead',
        phoneNumberInputPlaceholder: 'Enter phone number...',
        consentRequiredError: 'Please check the consent box',
        checkYourPhone: 'Check your phone for a text message',
        errorSendingRequest: 'Error sending request. Please try again.',
        messageSentSuccessfully: 'Please check your phone for a text message',
        invalidPhoneNumberError: 'Please enter a valid phone number',
        rebateAlreadyClaimedError: 'This rebate is already claimed',
        rebateIdError: 'Rebate ID not found',
        messageSentText:
          "A text message was sent to the number provided. Don't see it? ",
        defaultLegalText: `<p>By entering your phone number or by sending us a text message you agree to receive recurring automated promotional and personalized marketing text messages (e.g. special offers) from {{brand_name}} at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. View <a href='https://brij.it/terms' target='_self'>Terms & Privacy</a>.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Please check the consent box',
        firstNameInputPlaceholder: 'First Name',
        lastNameInputPlaceholder: 'Last Name',
        phoneNumberInputPlaceholder: 'Phone Number',
        invalidFirstName: 'First name is required',
        invalidLastName: 'Last name is required',
        invalidPhoneNumber: 'Please enter a valid phone number',
        completeProfile: 'Complete Profile',
        continueButton: 'Continue',
        skip: `I'll do this later`,
      },
      sideMenu: {
        myCart: 'My Cart',
        myProfile: 'My Profile',
        myCollection: 'My Collection',
        visitWebsite: 'Visit Website',
        removeProduct: 'Remove Product',
        productRemoved: ' has been removed from your collection.',
        signOut: 'Sign Out',
        signIn: 'Sign In',
        signOutToastMessage: 'Logout Successful',
        translate: 'Translate',
        termsAndPrivacy: 'Terms & Privacy',
        sweepstakesRules: 'Sweepstakes Rules',
      },
      profile: {
        pageTitle: 'Profile',
        pageHeaderTitle: 'Profile',
        firstNameInput: 'First Name',
        lastNameInput: 'Last Name',
        emailInput: 'Email',
        phoneNumberInput: 'Phone Number',
        saveChanges: 'Save Changes',
        updateToastMessage: 'Profile Updated',
      },
      collection: {
        pageTitle: 'My Collection',
        collectionPageTitle: 'My Collection',
        emptyCollectionMessage: 'No Products in your Collection',
        scanCodeButton: 'Scan Code',
        scanSuccessMessage: 'Product Scanned',
        scanErrorMessage: 'Scan Error',
        invalidScanMessage: 'Not a valid Brij code',
        expireStatus: 'Expires',
        purchaseStatus: 'Purchased',
        registerStatus: 'Registered',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Terms & Privacy',
        pageTitle: 'Terms & Privacy',
        subtitle: 'Terms & Conditions and Privacy Policy',
        questionsAboutData: 'Questions about your data?',
        contactUs: 'Contact us',
        legalNotice: `Brij Inc. acts as a service provider for {{brand_name}}, facilitating the collection and management of user data, including but not limited to email addresses and phone numbers. This partnership allows {{brand_name}} to utilize the data gathered through Brij Inc.'s platform for purposes specified in their respective privacy policies and terms of service. Brij Inc. is committed to handling this data responsibly and in compliance with all applicable data protection laws, ensuring that user privacy is respected and protected in the course of our collaboration with {{brand_name}}. `,
        legalNoticeMixed: `Please see the respective privacy policies and terms and conditions of both Brij Inc. and {{brand_name}} below for more detailed information.`,
        legalNoticeBrij: `Please see the privacy policiy and terms and conditions of Brij Inc. below for more detailed information.`,
        termsButton: 'Terms & Conditions',
        privacyButton: 'Privacy Policy',
      },
      gameRules: {
        pageTitle: 'Sweepstakes Rules',
        pageHeaderTitle: 'Sweepstakes Rules',
      },
      fourZeroFour: {
        pageTitle: '404 | Not Found',
        message: 'This experience does not exist',
        learnMoreButton: 'Learn about Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Recommendations',
      },
      customSelectInput: {
        noOptions: 'NO OPTIONS',
      },
      SearchableDropDown: {
        noOptions: 'No Options',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            'Need to register another product? Tap here',
          callToActionButton: 'Call to Action',
          moreButton: 'More',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'Add Purchase Details',
            completeProfileScreen: 'Complete Profile',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1: 'By registering you agree to email communications from ',
            part2: ' and accept ',
            brijBrand: " Brij's ",
            linkText: 'Terms & Conditions',
            part3: ' For help or questions email ',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'If you are the owner of this product and are having difficulty signing in, please contact ',
          },
          unitTag: {
            heading: 'Welcome Back',
            description:
              'To view this content, please tap the button below to have a sign in link sent to the registered email address.',
          },
          viewDetails: 'View Details',
          fullTermsLink: 'See full terms',
          emailConsentRequired: 'Please check the email consent box',
          privacyConsentRequired: 'Please check the privacy consent box',
          and: 'and',
          sweepstakesRules: 'Sweepstakes Rules',
          successMessage: {
            productRegistered: 'Product Registered!',
            signedUp: "You're Signed Up",
            warrantyActivated: 'Warranty Activated!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'Add Purchase Details',
          successDrawer: {
            title: 'Product Registered!',
            description: 'Check your email for details',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Test Results Lookup',
          enterLotNumber:
            'Enter your product’s lot number to view testing results of your product:',
          enterUpcNumber:
            'Enter your product’s 12-digit UPC number to view testing results of your product:',
          enterUpcAndLotNumber:
            'Enter your product’s 12-digit UPC and lot number to view testing results of your product:',
          lotNumberEmptyInputPlaceholder: 'Enter lot number...',
          lotNumberInputPlaceholder: 'Lot number...',
          optionalPlaceholderText: '(optional)',
          upcNumberEmptyInputPlaceholder: 'Enter UPC number...',
          upcNumberInputPlaceholder: 'UPC number...',
          submitLotNumberButton: 'View Test Results',
          lotNumberHeading: 'Lot No',
          testDateHeading: 'Test Date',
          expirationDateHeading: 'Exp. Date',
          viewFullReportButton: 'View all test results',
          startOverButton: 'Start Over',
          heavyMetalsHeading: 'Heavy Metals',
          pesticidesAndGlyphosateHeading: 'Pesticides & Glyphosate',
          plasticizersHeading: 'Plasticizers (BPA, BPS)',
          standardHeading: 'Standard',
          disclaimer:
            'Testing was performed in compliance with California AB 899 standards. For the latest FDA guidance and information on the health effects of toxic elements on children, please ',
          disclaimerLink: 'visit this link',
          errors: {
            emptyLotOrUpcNumber:
              'Please enter a UPC number or a lot number to proceed',
            noRecordFound:
              'Unable to find test data. Please ensure that the UPC or lot number is entered correctly.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Entry Date',
          entryIdHeading: 'Entry ID',
          sweepstakesEntryStatus: {
            entryApproved: 'Entry Approved',
            entryPending: 'Entry Pending',
            entryDenied: 'Entry Denied',
            entryExpired: 'Sweepstakes Expired',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage:
              "You have successfully entered this sweepstakes. We'll reach out if you're selected.",
            entryPendingReviewMessage:
              'Your sweepstakes entry is under review. Check back soon to view your status.',
            entryDeniedMessage:
              'Your entry was denied. Please ensure that your entry meets all requirements for this sweepstakes.',
            sweepstakesExpiredMessage:
              'This sweepstakes has ended. Thank you for participating.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Warranty Active',
          warrantyHeadingWithMulberry: 'Extended Warranty by Mulberry',
          expiredWarrantyHeading: 'Warranty Expired',
          pendingWarrantyHeading: 'Warranty Pending Review',
          deniedWarrantyHeading: 'Warranty Denied',
          mulberryDefaultHeader: 'Stay Covered for Longer',
          mulberryDefaultDescription:
            'For a limited time, upgrade to extend your protection at the lowest rates, guaranteed.',
          mulberryDefaultButtonCta: 'Extend My Warranty Coverage',
          mulberryExpirationNotice:
            'Warranty extensions are managed by Mulberry Technology Inc. and are available for purchase until ',
          mulberryPolicyTerms: 'View Policy Terms',
          mulberryHelpText: {
            part1: 'Extended warranty plans are managed by',
            part2:
              'Contact help@getmulberry.com with questions regarding your extended warranty.',
          },
          lifetimeLabel: 'Lifetime',
          pendingInfoText:
            'Your warranty registration is currently under review. Check back later to view its status.',
          deniedInfoText:
            'Your warranty registration was denied. Please submit a valid dated receipt below and ensure the purchase date matches the date on the receipt.',
          issueDate: 'Start Date',
          expirationDate: 'Expiration Date',
          termLength: 'Term Length',
          fullTermsLink: 'See full terms',
          viewDetails: 'View Details',
          detailsModal: {
            activateWarrantyHeading: 'Activate Warranty',
            updateWarrantyHeading: 'Update Details',
            description:
              'To activate your warranty please enter the purchase date and upload a dated receipt.',
            updateDescription:
              'To update your purchase details, please enter the new purchase date and upload a dated receipt.',
            activateButton: 'Activate',
            updateButton: 'Update',
            cancelButton: 'Cancel',
          },
          successDrawer: {
            title: 'Warranty Activated!',
            description: 'Check your email for details',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Copy link',
          copyLinkToastMessage: 'Copied to clipboard',
          shareLinkButton: 'Share link',
          shareText: 'Have a look at this product from Brij',
          helpText: 'Have your friend scan this code',
        },
        smsDrawer: {
          signUpButton: 'Sign up for VIP texts',
          subscriptionDisclaimer:
            'By signing up via text, you agree to receive recurring automated personalized and promotional marketing text messages from Canopy at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. Must be a resident of the US and 18 years or older. View Terms and Privacy.',
        },
        shopDrawer: {
          successDrawer: {
            title: 'Order Placed!',
            description: 'Check your email for an order confirmation',
          },
          productDetails: {
            showMore: 'More',
            showLess: 'Less',
          },
          savingBanner: {
            title: "You're saving",
          },
          cartButton: {
            callToAction: 'Add to Cart',
            invalidCombo: 'Out of Stock',
            invalidQuantity: 'Select Quantity',
          },
          checkoutHint: {
            chooseOptions: 'Please choose options',
            comboUnavailable: 'Combination not available',
          },
          recommendations: {
            title: 'Recommendations',
            seeMore: 'See More',
          },
          cartResetNotification: 'A new cart has been created for ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Checkout',
          },
          drawerTitle: 'My Cart',
          emptyCartText: 'There are currently no items in your cart',
        },
        discountDrawer: {
          discountCodeHeading: 'Discount Code:',
          copiedToClipboard: 'Discount Code copied to clipboard',
        },
        formDrawer: {
          submit: 'Submit',
          next: 'Next',
          start: 'Start',
          regFormFetchError:
            'Registration form fetch unsuccessful. Please refresh and try again.',
          formCompletetionMessage:
            'Your responses were submitted successfully.',
          formSubmissionError: 'Form submission failed. Please resubmit',
          submissionSuccessful: 'Submission Successful',
          submitAnotherResponse: 'Submit Another Response',
          fileUpload: {
            fileName: 'File Name...',
            uploadFileButton: 'Upload Files',
            syncToS3Error: 'File Failed to sync to s3 please upload again.',
            fileSizeError: 'File size exceeds 20MB. Please resubmit.',
            fileLimitError: '5 file limit exceeded. Please resubmit.',
            fileExtensionError: `File extenstion not supported. valid files are .pdf, .jpeg,
              .jpg, heic and .png`,
          },
          textArea: {
            placeholder: 'Type your answer here...',
            placeholderFilled: 'Your answer...',
          },
          dropDown: {
            selectText: 'Click to select',
          },
          searchableDropDown: {
            selectText: 'Type to search...',
          },
          datePicker: {
            placeholder: 'Please select a date',
            errorMessage: 'Invalid Date',
          },
          addressFields: {
            countryPlaceholder: 'Choose Country',
            addressLineOnePlaceholder: 'Address line 1',
            addressLineTwoPlaceholder: 'Address line 2',
            cityPlaceholder: 'City',
            stateDropdownPlaceholder: 'Choose state',
            stateFieldPlaceholder: 'State',
            zipcodePlaceholder: 'Zip',
          },
          firstNamePlaceholder: 'First Name',
          lastNamePlaceholder: 'Last Name',
        },
      },
    },
  },
  es: {
    // Spanish
    translation: {
      rotationScreen: {
        message:
          'Por favor, gire su dispositivo a la posición vertical para ver la experiencia',
      },
      registration: {
        productRegistered: '¡Producto registrado!',
        signedUp: '¡Te has registrado!',
        warrantyActivated: '¡Garantía activada!',
        completeProfile: 'Completa tu perfil',
        registrationError: 'Error de registro, por favor inténtelo de nuevo',
      },
      socialLogin: {
        withGoogleText: 'con Google',
        continueGoogleButton: 'Continuar con Google',
        signinError: 'Hubo un error. Por favor, inténtelo de nuevo',
        popupBlockedMessage:
          'fue bloqueado por su navegador. Desactive los bloqueadores de ventanas emergentes o elija la opción de correo electrónico para continuar.',
        cookiesDisabled:
          'Por favor, habilite las cookies para continuar o intente registrarse con correo electrónico.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Recibo subido.',
        receiptUploadError: 'Error al subir el recibo',
        noReceiptError: 'Suba el recibo de compra',
        firstnameInputPlaceholder: 'Ingrese su nombre...',
        firstnameFilledInputPlaceholder: 'Nombre',
        lastnameInputPlaceholder: 'Ingrese su apellido...',
        lastnameFilledInputPlaceholder: 'Apellido',
        phoneNumberInputPlaceholder: 'Ingrese su número de teléfono...',
        serialNumberInputPlaceholder: 'Ingrese el número de serie...',
        serialNumberFilledInputPlaceholder: 'Número de serie',
        uploadReceiptButtonText: 'Subir recibo',
        datepickerLabel: 'Fecha de compra...',
        datepickerFilledLabel: 'Fecha de compra',
        smsConsentRequired: 'Por favor marque la casilla de consentimiento',
        invalidType: 'Tipo de archivo no válido. Suba una imagen o PDF',
        invalidDate: 'Fecha no válida',
        retailChannelInputPlaceholder: 'Lugar de compra',
        quantitySelectorInputPlaceholder: 'Seleccione cantidad',
        quantitySelectorInputOther: 'Otro',
        failedToUpload: ' no se pudo subir',
        submitButtonText: 'Enviar',
        receiptFailedError:
          'Uno o más de sus recibos pueden no cumplir con los requisitos. Considere volver a subir una imagen clara y bien iluminada de su recibo que incluya la(s) línea(s) relevante(s).',
      },
      signIn: {
        pageTitle: 'Iniciar sesión en Brij',
        pageHeaderTitle: 'Iniciar sesión',
        withEmail: ' con Correo Electrónico',
        completeProfile: 'Completa tu perfil',
        useDifferentOption: 'Usar una opción diferente',
        continueWithEmail: 'Continuar con Correo Electrónico',
        unitTagSignInLink: 'Solicitar enlace de inicio de sesión',
        checkYourEmail: 'Revisa tu correo electrónico',
        emailInputPlaceholder: 'Ingrese su correo electrónico...',
        emailInputFilledPlaceholder: 'Correo electrónico',
        toastMessages: {
          invalidEmail:
            'Por favor, ingrese una dirección de correo electrónico válida',
          completeRecaptchaVerification:
            'Por favor complete la verificación de Recaptcha',
          cantVerifyLoginDetails:
            'No se pueden verificar los detalles de inicio de sesión',
          emailConsentRequired:
            'Por favor marque la casilla de consentimiento de correo electrónico',
          privacyConsentRequired:
            'Por favor marque la casilla de consentimiento de privacidad',
        },
      },
      firebaseErrors: {
        'auth/invalid-email':
          'Por favor, proporcione un correo electrónico válido',
        'auth/missing-email': 'Por favor, proporcione un correo electrónico',
        'auth/user-not-found': 'El usuario no existe',
        'auth/wrong-password': 'Correo electrónico o contraseña no válidos',
        'auth/internal-error': 'Ocurrió un error del servidor',
        'auth/popup-closed-by-user':
          'Inicio de sesión cancelado. Por favor complete el proceso de inicio de sesión o elija la opción de correo electrónico para continuar',
        'auth/cancelled-popup-request':
          'Inicio de sesión cancelado. Por favor complete el proceso de inicio de sesión o elija la opción de correo electrónico para continuar',
        'auth/invalid-credential-or-provider-id': 'Credenciales no válidas',
        'auth/unauthorized-domain':
          'Está accediendo al sitio web desde un dominio no seguro.',
        'auth/invalid-action-code': 'El código es inválido o ha expirado',
        'auth/popup-blocked':
          'Bloqueo de ventanas emergentes. Por favor habilite las ventanas emergentes o elija la opción de correo electrónico para continuar.',
      },
      magicLink: {
        linkSentToastMessage:
          '¡Bienvenido de nuevo! Revise su correo electrónico para obtener un enlace de inicio de sesión.',
        magicLinkError: 'Se produjo un error',
        redirecting: 'Redirigiendo...',
      },
      ageGate: {
        title: 'Confirma tu edad',
        subtitle: '¿Tienes {{ageLimit}} años o más?',
        confirmationButton: 'SÍ',
        disapprovalButton: 'NO',
        warningMessage:
          '¡Debes tener {{ageLimit}} años o más para ver este sitio!',
      },
      contentGate: {
        enterButton: 'ENTRAR',
        continueButton: 'CONTINUAR',
        warningMessage: 'Lo siento, debes tener {{ageLimit}} años o más',
        invalidYearInput: 'Por favor, ingrese un año válido',
      },
      productDetails: {
        viewWarranty: 'Ver garantía',
        leadInformation: {
          registeredTo: 'Registrado a',
          warranty: 'Garantía',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Toque para buscar...',
        dropdownNoResults: 'Sin resultados',
      },
      smsOptIn: {
        receiveMessageButton: 'MENSAJE UN MENSAJE Y COMIENCE',
        sendMessageButton: 'ENVÍENOS UN MENSAJE Y COMIENCE',
        trySendingAgain: 'Inténtalo de nuevo',
        sendMeText: 'Envíame un mensaje de texto en su lugar',
        sendUsText: 'Envíanos un mensaje de texto en su lugar',
        phoneNumberInputPlaceholder: 'Ingrese su número de teléfono...',
        consentRequiredError: 'Por favor marque la casilla de consentimiento',
        checkYourPhone: 'Revise su teléfono para ver un mensaje de texto',
        errorSendingRequest:
          'Error al enviar la solicitud. Por favor inténtelo de nuevo.',
        messageSentSuccessfully:
          'Revise su teléfono para ver un mensaje de texto',
        invalidPhoneNumberError:
          'Por favor ingrese un número de teléfono válido',
        rebateAlreadyClaimedError: 'Este reembolso ya ha sido reclamado',
        rebateIdError: 'ID de reembolso no encontrado',
        messageSentText:
          'Se envió un mensaje de texto al número proporcionado. ¿No lo ves? ',
        defaultLegalText: `<p>Al ingresar su número de teléfono o al enviarnos un mensaje de texto, usted acepta recibir mensajes de texto promocionales automatizados y personalizados recurrentes (por ejemplo, ofertas especiales) de {{brand_name}} en el número de teléfono celular utilizado al registrarse. El consentimiento no es una condición para ninguna compra. Responda AYUDA para obtener ayuda y PARE para cancelar. La frecuencia de los mensajes varía. Pueden aplicarse tarifas por mensajes y datos. Vea los <a href='https://brij.it/terms' target='_self'>Términos y Privacidad</a>.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Por favor marque la casilla de consentimiento',
        firstNameInputPlaceholder: 'Nombre',
        lastNameInputPlaceholder: 'Apellido',
        phoneNumberInputPlaceholder: 'Número de teléfono',
        invalidFirstName: 'Se requiere el nombre',
        invalidLastName: 'Se requiere el apellido',
        invalidPhoneNumber: 'Por favor, ingrese un número de teléfono válido',
        completeProfile: 'Completa tu perfil',
        continueButton: 'Continuar',
        skip: 'Lo haré más tarde',
      },
      sideMenu: {
        myCart: 'Mi carrito',
        myProfile: 'Mi perfil',
        myCollection: 'Mi colección',
        visitWebsite: 'Visitar sitio web',
        removeProduct: 'Eliminar producto',
        productRemoved: ' se ha eliminado de tu colección.',
        signOut: 'Cerrar sesión',
        signIn: 'Iniciar sesión',
        signOutToastMessage: 'Cierre de sesión exitoso',
        translate: 'Traducir',
        termsAndPrivacy: 'Términos y Privacidad',
        sweepstakesRules: 'Reglas del Sorteo',
      },
      profile: {
        pageTitle: 'Perfil',
        pageHeaderTitle: 'Perfil',
        firstNameInput: 'Nombre',
        lastNameInput: 'Apellido',
        emailInput: 'Correo Electrónico',
        phoneNumberInput: 'Número de teléfono',
        saveChanges: 'Guardar cambios',
        updateToastMessage: 'Perfil actualizado',
      },
      collection: {
        pageTitle: 'Mi colección',
        collectionPageTitle: 'Mi colección',
        emptyCollectionMessage: 'No hay productos en tu colección',
        scanCodeButton: 'Escanear código',
        scanSuccessMessage: 'Producto escaneado',
        scanErrorMessage: 'Error de escaneo',
        invalidScanMessage: 'No es un código Brij válido',
        expireStatus: 'Expira',
        purchaseStatus: 'Comprado',
        registerStatus: 'Registrado',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Términos y Privacidad',
        pageTitle: 'Términos y Privacidad',
        subtitle: 'Términos y condiciones y política de privacidad',
        questionsAboutData: '¿Preguntas sobre tus datos?',
        contactUs: 'Contáctenos',
        legalNotice: `Brij Inc. actúa como proveedor de servicios para {{brand_name}}, facilitando la recopilación y gestión de datos de usuario, incluidas, entre otras, direcciones de correo electrónico y números de teléfono. Esta asociación permite a {{brand_name}} utilizar los datos recopilados a través de la plataforma de Brij Inc. para los fines especificados en sus respectivas políticas de privacidad y términos de servicio. Brij Inc. se compromete a manejar estos datos de manera responsable y cumplir con todas las leyes aplicables de protección de datos, garantizando que la privacidad del usuario sea respetada y protegida en el transcurso de nuestra colaboración con {{brand_name}}. `,
        legalNoticeMixed: `Consulte las respectivas políticas de privacidad y términos y condiciones de Brij Inc. y {{brand_name}} a continuación para obtener información más detallada.`,
        legalNoticeBrij: `Consulte la política de privacidad y los términos y condiciones de Brij Inc. a continuación para obtener información más detallada.`,
        termsButton: 'Términos y Condiciones',
        privacyButton: 'Política de Privacidad',
      },
      gameRules: {
        pageTitle: 'Reglas del Sorteo',
        pageHeaderTitle: 'Reglas del Sorteo',
      },
      fourZeroFour: {
        pageTitle: '404 | No encontrado',
        message: 'Esta experiencia no existe',
        learnMoreButton: 'Aprende sobre Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Recomendaciones',
      },
      customSelectInput: {
        noOptions: 'NO HAY OPCIONES',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            '¿Necesitas registrar otro producto? Toque aquí',
          callToActionButton: 'Llamada a la acción',
          moreButton: 'Más',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'Agregar detalles de compra',
            completeProfileScreen: 'Completa tu perfil',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1:
              'Al registrarte, aceptas las comunicaciones por correo electrónico de ',
            part2: ' y aceptas ',
            brijBrand: ' los ',
            linkText: 'Términos y Condiciones',
            part3:
              ' Para obtener ayuda o preguntas, envía un correo electrónico a ',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'Si eres el propietario de este producto y tienes dificultades para iniciar sesión, comunícate con ',
          },
          unitTag: {
            heading: 'Bienvenido de Nuevo',
            description:
              'Para ver este contenido, toque el botón a continuación para que se envíe un enlace de inicio de sesión a la dirección de correo electrónico registrada.',
          },
          viewDetails: 'Ver detalles',
          fullTermsLink: 'Ver términos completos',
          emailConsentRequired:
            'Por favor marque la casilla de consentimiento de correo electrónico',
          privacyConsentRequired:
            'Por favor marque la casilla de consentimiento de privacidad',
          and: 'y',
          sweepstakesRules: 'Reglas del Sorteo',
          successMessage: {
            productRegistered: '¡Producto registrado!',
            signedUp: '¡Te has registrado!',
            warrantyActivated: '¡Garantía activada!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'Agregar detalles de compra',
          successDrawer: {
            title: '¡Producto registrado!',
            description: 'Revise su correo electrónico para obtener detalles',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Búsqueda de resultados de prueba',
          enterLotNumber:
            'Ingrese el número de lote de su producto para ver los resultados de la prueba de su producto:',
          enterUpcNumber:
            'Ingrese el número UPC de 12 dígitos de su producto para ver los resultados de las pruebas de su producto:',
          enterUpcAndLotNumber:
            'Ingrese el UPC de 12 dígitos y el número de lote de su producto para ver los resultados de las pruebas de su producto:',
          lotNumberEmptyInputPlaceholder: 'Ingrese el número de lote...',
          lotNumberInputPlaceholder: 'Número de lote...',
          optionalPlaceholderText: '(opcional)',
          upcNumberEmptyInputPlaceholder: 'Ingrese el número de UPC...',
          upcNumberInputPlaceholder: 'Número de UPC...',
          submitLotNumberButton: 'Ver resultados de la prueba',
          lotNumberHeading: 'Lote No',
          testDateHeading: 'Fecha de prueba',
          expirationDateHeading: 'Fecha de vencimiento',
          viewFullReportButton: 'Ver todos los resultados de la prueba',
          startOverButton: 'Empezar de Nuevo',
          heavyMetalsHeading: 'Metales pesados',
          pesticidesAndGlyphosateHeading: 'Pesticidas y Glifosato',
          plasticizersHeading: 'Plastificantes (BPA, BPS)',
          standardHeading: 'Estándar',
          disclaimer:
            'Las pruebas se realizaron cumpliendo con los estándares de California AB 899. Para obtener la información más reciente de la FDA y orientación sobre los efectos en la salud de los elementos tóxicos en los niños, por favor ',
          disclaimerLink: 'visite este enlace',
          errors: {
            emptyLotOrUpcNumber:
              'Por favor ingrese un número de lote para continuar',
            noRecordFound:
              'No se pudo encontrar datos de prueba. Asegúrese de que el UPC o el número de lote estén ingresados correctamente.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Fecha de Entrada',
          entryIdHeading: 'ID de Entrada',
          sweepstakesEntryStatus: {
            entryApproved: 'Entrada Aprobada',
            entryPending: 'Entrada Pendiente',
            entryDenied: 'Entrada Denegada',
            entryExpired: 'Sorteo Expirado',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage:
              'Ha ingresado con éxito a este sorteo. Nos comunicaremos si es seleccionado.',
            entryPendingReviewMessage:
              'Su entrada al sorteo está en revisión. Vuelva pronto para ver su estado.',
            entryDeniedMessage:
              'Su entrada fue denegada. Asegúrese de que su entrada cumpla con todos los requisitos para este sorteo.',
            sweepstakesExpiredMessage:
              'Este sorteo ha finalizado. Gracias por participar.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Garantía activa',
          warrantyHeadingWithMulberry: 'Garantía extendida por Mulberry',
          expiredWarrantyHeading: 'Garantía vencida',
          pendingWarrantyHeading: 'Garantía pendiente de revisión',
          deniedWarrantyHeading: 'Garantía denegada',
          mulberryDefaultHeader: 'Manténgase cubierto por más tiempo',
          mulberryDefaultDescription:
            'Por tiempo limitado, actualice para extender su protección a las tarifas más bajas, garantizado.',
          mulberryDefaultButtonCta: 'Extender mi cobertura de garantía',
          mulberryExpirationNotice:
            'Las extensiones de garantía son administradas por Mulberry Technology Inc. y están disponibles para su compra hasta ',
          mulberryPolicyTerms: 'Ver términos de la póliza',
          mulberryHelpText: {
            part1: 'Los planes de garantía extendida son administrados por',
            part2:
              'Comuníquese con help@getmulberry.com si tiene preguntas sobre su garantía extendida.',
          },
          lifetimeLabel: 'Vitalicia',
          pendingInfoText:
            'Su registro de garantía está actualmente en revisión. Vuelva más tarde para ver su estado.',
          deniedInfoText:
            'Su registro de garantía fue denegado. Por favor, envíe un recibo fechado válido a continuación y asegúrese de que la fecha de compra coincida con la fecha en el recibo.',
          issueDate: 'Fecha de inicio',
          expirationDate: 'Fecha de vencimiento',
          termLength: 'Duración del término',
          fullTermsLink: 'Ver términos completos',
          viewDetails: 'Ver detalles',
          detailsModal: {
            activateWarrantyHeading: 'Activar garantía',
            updateWarrantyHeading: 'Actualizar detalles',
            description:
              'Para activar su garantía, ingrese la fecha de compra y cargue un recibo fechado.',
            updateDescription:
              'Para actualizar sus detalles de compra, ingrese la nueva fecha de compra y cargue un recibo fechado.',
            activateButton: 'Activar',
            updateButton: 'Actualizar',
            cancelButton: 'Cancelar',
          },
          successDrawer: {
            title: '¡Garantía activada!',
            description: 'Revise su correo electrónico para obtener detalles',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Copiar enlace',
          copyLinkToastMessage: 'Copiado al portapapeles',
          shareLinkButton: 'Compartir enlace',
          shareText: 'Echa un vistazo a este producto de Brij',
          helpText: 'Haz que tu amigo escanee este código',
        },
        smsDrawer: {
          signUpButton: 'Regístrate para recibir mensajes de texto VIP',
          subscriptionDisclaimer:
            'Al registrarte por mensaje de texto, aceptas recibir mensajes de texto promocionales automatizados y personalizados recurrentes de Canopy en el número de teléfono celular utilizado al registrarte. El consentimiento no es una condición para ninguna compra. Responde AYUDA para obtener ayuda y PARE para cancelar. La frecuencia de los mensajes varía. Pueden aplicarse tarifas por mensajes y datos. Debes ser residente de EE. UU. y tener 18 años o más. Ver Términos y Privacidad.',
        },
        shopDrawer: {
          successDrawer: {
            title: '¡Pedido realizado!',
            description:
              'Revise su correo electrónico para obtener una confirmación del pedido',
          },
          productDetails: {
            showMore: 'Más',
            showLess: 'Menos',
          },
          savingBanner: {
            title: 'Estás ahorrando',
          },
          cartButton: {
            callToAction: 'Agregar al carrito',
            invalidCombo: 'Agotado',
            invalidQuantity: 'Seleccione cantidad',
          },
          checkoutHint: {
            chooseOptions: 'Por favor elija opciones',
            comboUnavailable: 'Combinación no disponible',
          },
          recommendations: {
            title: 'Recomendaciones',
            seeMore: 'Ver más',
          },
          cartResetNotification: 'Se ha creado un nuevo carrito para ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Pagar',
          },
          drawerTitle: 'Mi carrito',
          emptyCartText: 'Actualmente no hay artículos en su carrito',
        },
        discountDrawer: {
          discountCodeHeading: 'Código de descuento:',
          copiedToClipboard: 'Código de descuento copiado al portapapeles',
        },
        formDrawer: {
          submit: 'Enviar',
          next: 'Siguiente',
          start: 'Comenzar',
          regFormFetchError:
            'Error al recuperar el formulario de registro. Por favor, actualice e inténtelo de nuevo.',
          formCompletetionMessage: 'Sus respuestas se enviaron correctamente.',
          formSubmissionError:
            'Error al enviar el formulario. Por favor, vuelva a enviar',
          submissionSuccessful: 'Envío exitoso',
          submitAnotherResponse: 'Enviar Otra Respuesta',
          fileUpload: {
            fileName: 'Nombre del archivo...',
            uploadFileButton: 'Subir archivos',
            syncToS3Error:
              'Error al sincronizar el archivo con s3, por favor suba de nuevo.',
            fileSizeError:
              'El tamaño del archivo supera los 20MB. Por favor, vuelva a enviar.',
            fileLimitError:
              'Se superó el límite de 5 archivos. Por favor, vuelva a enviar.',
            fileExtensionError: `Extensión de archivo no admitida. Los archivos válidos son .pdf, .jpeg,
              .jpg, heic y .png`,
          },
          textArea: {
            placeholder: 'Escribe tu respuesta aquí...',
            placeholderFilled: 'Tu respuesta...',
          },
          dropDown: {
            selectText: 'Haga clic para seleccionar',
          },
          datePicker: {
            placeholder: 'Seleccione una fecha',
            errorMessage: 'Fecha no Válida',
          },
          addressFields: {
            countryPlaceholder: 'Elija País',
            addressLineOnePlaceholder: 'Dirección línea 1',
            addressLineTwoPlaceholder: 'Dirección línea 2',
            cityPlaceholder: 'Ciudad',
            stateDropdownPlaceholder: 'Elija Estado',
            stateFieldPlaceholder: 'Estado',
            zipcodePlaceholder: 'Código Postal',
          },
          firstNamePlaceholder: 'Nombre de Pila',
          lastNamePlaceholder: 'Apellido',
        },
      },
    },
  },
  fr: {
    // French
    translation: {
      rotationScreen: {
        message:
          'Veuillez tourner votre appareil en mode portrait pour voir l’expérience',
      },
      registration: {
        productRegistered: 'Produit enregistré!',
        signedUp: 'Inscrit!',
        warrantyActivated: 'Garantie activée!',
        completeProfile: 'Complétez votre profil',
        registrationError: 'Erreur d’inscription, veuillez réessayer',
      },
      socialLogin: {
        withGoogleText: 'avec Google',
        continueGoogleButton: 'Continuer avec Google',
        signinError:
          'Une erreur est survenue. Veuillez réessayer de vous connecter',
        popupBlockedMessage:
          'a été bloqué par votre navigateur. Veuillez désactiver les bloqueurs de fenêtres contextuelles ou choisissez l’option e-mail pour continuer.',
        cookiesDisabled:
          'Veuillez activer les cookies pour continuer ou essayez de vous inscrire avec un e-mail.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Reçu téléchargé.',
        receiptUploadError: 'Erreur lors du téléchargement du reçu',
        noReceiptError: "Veuillez télécharger le reçu d'achat",
        firstnameInputPlaceholder: 'Entrez votre prénom...',
        firstnameFilledInputPlaceholder: 'Prénom',
        lastnameInputPlaceholder: 'Entrez votre nom de famille...',
        lastnameFilledInputPlaceholder: 'Nom de famille',
        phoneNumberInputPlaceholder: 'Entrez votre numéro de téléphone...',
        serialNumberInputPlaceholder: 'Entrez le numéro de série...',
        serialNumberFilledInputPlaceholder: 'Numéro de série',
        uploadReceiptButtonText: 'Télécharger le reçu',
        datepickerLabel: "Date d'achat...",
        datepickerFilledLabel: "Date d'achat",
        smsConsentRequired: 'Veuillez cocher la case de consentement',
        invalidType:
          'Type de fichier invalide. Veuillez télécharger une image ou un PDF',
        invalidDate: 'Date invalide',
        retailChannelInputPlaceholder: 'Lieu d’achat...',
        quantitySelectorInputPlaceholder: 'Sélectionnez la quantité',
        quantitySelectorInputOther: 'Autre',
        failedToUpload: ' n’a pas pu être téléchargé',
        submitButtonText: 'Soumettre',
        receiptFailedError:
          'Un ou plusieurs de vos reçus peuvent ne pas respecter les exigences. Considérez de re-télécharger une image claire et bien éclairée de votre reçu qui inclut la/les ligne(s) pertinente(s).',
      },
      signIn: {
        pageTitle: 'Se connecter à Brij',
        pageHeaderTitle: 'Se connecter',
        withEmail: ' avec Email',
        completeProfile: 'Complétez votre profil',
        useDifferentOption: 'Utiliser une autre option',
        continueWithEmail: 'Continuer avec Email',
        unitTagSignInLink: 'Demander un lien de connexion',
        checkYourEmail: 'Vérifiez votre e-mail',
        emailInputPlaceholder: 'Entrez votre e-mail...',
        emailInputFilledPlaceholder: 'Email',
        toastMessages: {
          invalidEmail: 'Veuillez entrer une adresse e-mail valide',
          completeRecaptchaVerification:
            'Veuillez compléter la vérification Recaptcha',
          cantVerifyLoginDetails: `Impossible de vérifier les détails de connexion`,
          emailConsentRequired:
            'Veuillez cocher la case de consentement par e-mail',
          privacyConsentRequired:
            'Veuillez cocher la case de consentement de confidentialité',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': 'Veuillez fournir une adresse e-mail valide',
        'auth/missing-email': 'Veuillez fournir une adresse e-mail',
        'auth/user-not-found': "L'utilisateur n'existe pas",
        'auth/wrong-password': 'E-mail ou mot de passe invalide',
        'auth/internal-error': 'Une erreur de serveur est survenue',
        'auth/popup-closed-by-user':
          "Connexion annulée. Veuillez compléter le processus de connexion ou choisir l'option e-mail pour continuer",
        'auth/cancelled-popup-request':
          "Connexion annulée. Veuillez compléter le processus de connexion ou choisir l'option e-mail pour continuer",
        'auth/invalid-credential-or-provider-id':
          "Informations d'identification non valides",
        'auth/unauthorized-domain':
          "Vous accédez au site Web à partir d'un domaine non sécurisé.",
        'auth/invalid-action-code': 'Le code est invalide ou a expiré',
        'auth/popup-blocked':
          "Blocage de fenêtres contextuelles. Veuillez activer les fenêtres contextuelles ou choisir l'option e-mail pour continuer.",
      },
      magicLink: {
        linkSentToastMessage:
          'Bienvenue! Vérifiez votre e-mail pour obtenir un lien de connexion.',
        magicLinkError: 'Une erreur est survenue',
        redirecting: 'Redirection...',
      },
      ageGate: {
        title: 'Confirmez votre âge',
        subtitle: 'Avez-vous {{ageLimit}} ans ou plus?',
        confirmationButton: 'OUI',
        disapprovalButton: 'NON',
        warningMessage:
          'Vous devez avoir {{ageLimit}} ans ou plus pour voir ce site!',
      },
      contentGate: {
        enterButton: 'ENTRER',
        continueButton: 'CONTINUER',
        warningMessage: 'Désolé, vous devez avoir {{ageLimit}} ans ou plus',
        invalidYearInput: 'Veuillez entrer une année valide',
      },
      productDetails: {
        viewWarranty: 'Voir la garantie',
        leadInformation: {
          registeredTo: 'Enregistré à',
          warranty: 'Garantie',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Appuyez pour rechercher...',
        dropdownNoResults: 'Aucun résultat',
      },
      smsOptIn: {
        receiveMessageButton: 'ENVOYEZ-MOI UN TEXTE ET COMMENCEZ',
        sendMessageButton: 'ENVOYEX-NOUS UN SMS ET COMMENCEZ',
        trySendingAgain: 'Réessayer',
        sendMeText: 'Envoyez-moi un SMS à la place',
        sendUsText: 'Envoyez-nous un SMS à la place',
        phoneNumberInputPlaceholder: 'Entrez votre numéro de téléphone...',
        consentRequiredError: 'Veuillez cocher la case de consentement',
        checkYourPhone: 'Vérifiez votre téléphone pour un message texte',
        errorSendingRequest:
          "Erreur lors de l'envoi de la demande. Veuillez réessayer.",
        messageSentSuccessfully:
          'Veuillez vérifier votre téléphone pour un message texte',
        invalidPhoneNumberError:
          'Veuillez entrer un numéro de téléphone valide',
        rebateAlreadyClaimedError: 'Ce rabais est déjà réclamé',
        rebateIdError: 'ID de rabais introuvable',
        messageSentText:
          'Un message texte a été envoyé au numéro fourni. Vous ne le voyez pas? ',
        defaultLegalText: `<p>En entrant votre numéro de téléphone ou en nous envoyant un message texte, vous acceptez de recevoir des messages texte promotionnels automatisés récurrents et personnalisés (par exemple, des offres spéciales) de {{brand_name}} au numéro de téléphone utilisé lors de l'inscription. Le consentement n'est pas une condition d'achat. Répondre AIDE pour obtenir de l'aide et STOP pour annuler. La fréquence des messages varie. Les tarifs des messages et des données peuvent s'appliquer. Voir <a href='https://brij.it/terms' target='_self'>Conditions et confidentialité</a>.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Veuillez cocher la case de consentement',
        firstNameInputPlaceholder: 'Prénom',
        lastNameInputPlaceholder: 'Nom de famille',
        phoneNumberInputPlaceholder: 'Numéro de téléphone',
        invalidFirstName: 'Prénom requis',
        invalidLastName: 'Nom de famille requis',
        invalidPhoneNumber: 'Veuillez entrer un numéro de téléphone valide',
        completeProfile: 'Compléter le profil',
        continueButton: 'Continuer',
        skip: `Je le ferai plus tard`,
      },
      sideMenu: {
        myCart: 'Mon panier',
        myProfile: 'Mon profil',
        myCollection: 'Ma collection',
        visitWebsite: 'Visiter le site Web',
        removeProduct: 'Supprimer le produit',
        productRemoved: ' a été retiré de votre collection.',
        signOut: 'Déconnexion',
        signIn: 'Se connecter',
        signOutToastMessage: 'Déconnexion réussie',
        translate: 'Traduire',
        termsAndPrivacy: 'Conditions et confidentialité',
        sweepstakesRules: 'Règles du Concours',
      },
      profile: {
        pageTitle: 'Profil',
        pageHeaderTitle: 'Profil',
        firstNameInput: 'Prénom',
        lastNameInput: 'Nom de famille',
        emailInput: 'Email',
        phoneNumberInput: 'Numéro de téléphone',
        saveChanges: 'Enregistrer les modifications',
        updateToastMessage: 'Profil mis à jour',
      },
      collection: {
        pageTitle: 'Ma collection',
        collectionPageTitle: 'Ma collection',
        emptyCollectionMessage: 'Aucun produit dans votre collection',
        scanCodeButton: 'Scanner le code',
        scanSuccessMessage: 'Produit scanné',
        scanErrorMessage: 'Erreur de scan',
        invalidScanMessage: 'Code Brij non valide',
        expireStatus: 'Expire',
        purchaseStatus: 'Acheté',
        registerStatus: 'Enregistré',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Conditions et confidentialité',
        pageTitle: 'Conditions et confidentialité',
        subtitle: 'Conditions générales et politique de confidentialité',
        questionsAboutData: 'Des questions sur vos données?',
        contactUs: 'Contactez-nous',
        legalNotice: `Brij Inc. agit en tant que prestataire de services pour {{brand_name}}, facilitant la collecte et la gestion des données des utilisateurs, y compris, mais sans s'y limiter, les adresses e-mail et les numéros de téléphone. Ce partenariat permet à {{brand_name}} d'utiliser les données recueillies via la plateforme de Brij Inc. à des fins spécifiées dans leurs politiques de confidentialité et conditions d'utilisation respectives. Brij Inc. s'engage à traiter ces données de manière responsable et en conformité avec toutes les lois applicables en matière de protection des données, garantissant que la vie privée des utilisateurs est respectée et protégée dans le cadre de notre collaboration avec {{brand_name}}.`,
        legalNoticeMixed: `Veuillez consulter les politiques de confidentialité et les conditions générales respectives de Brij Inc. et de {{brand_name}} ci-dessous pour des informations plus détaillées.`,
        legalNoticeBrij: `Veuillez consulter la politique de confidentialité et les conditions générales de Brij Inc. ci-dessous pour des informations plus détaillées.`,
        termsButton: 'Conditions Générales',
        privacyButton: 'Politique de Confidentialité',
      },
      gameRules: {
        pageTitle: 'Règles du Concours',
        pageHeaderTitle: 'Règles du Concours',
      },
      fourZeroFour: {
        pageTitle: '404 | Non trouvé',
        message: 'Cette expérience n’existe pas',
        learnMoreButton: 'En savoir plus sur Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Recommandations',
      },
      customSelectInput: {
        noOptions: 'AUCUNE OPTION',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            "Besoin d'enregistrer un autre produit? Appuyez ici",
          callToActionButton: "Appel à l'action",
          moreButton: 'Plus',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: "Ajouter les détails de l'achat",
            completeProfileScreen: 'Compléter le profil',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1:
              'En vous inscrivant, vous acceptez les communications par e-mail de ',
            part2: ' et acceptez ',
            brijBrand: ' les ',
            linkText: 'Conditions générales',
            part3:
              " Pour obtenir de l'aide ou des questions, envoyez un e-mail à ",
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'Si vous êtes le propriétaire de ce produit et que vous avez des difficultés à vous connecter, veuillez contacter ',
          },
          unitTag: {
            heading: 'Bienvenue de Nouveau',
            description:
              'Pour voir ce contenu, appuyez sur le bouton ci-dessous pour envoyer un lien de connexion à l’adresse e-mail enregistrée.',
          },
          viewDetails: 'Voir les détails',
          fullTermsLink: 'Voir les conditions complètes',
          emailConsentRequired:
            'Veuillez cocher la case de consentement par e-mail',
          privacyConsentRequired:
            'Veuillez cocher la case de consentement de confidentialité',
          and: 'et',
          sweepstakesRules: 'Règles du Tirage au Sort',
          successMessage: {
            productRegistered: 'Produit enregistré!',
            signedUp: 'Inscrit!',
            warrantyActivated: 'Garantie activée!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: "Ajouter les détails de l'achat",
          successDrawer: {
            title: 'Produit enregistré!',
            description: 'Consultez votre e-mail pour les détails',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Recherche de résultats de test',
          enterLotNumber:
            'Entrez le numéro de lot de votre produit pour voir les résultats du test de votre produit:',
          enterUpcNumber:
            'Entrez le code UPC de 12 chiffres de votre produit pour voir les résultats du test de votre produit:',
          enterUpcAndLotNumber:
            'Entrez le code UPC de 12 chiffres et le numéro de lot de votre produit pour voir les résultats du test de votre produit:',
          lotNumberEmptyInputPlaceholder: 'Entrez le numéro de lot...',
          lotNumberInputPlaceholder: 'Numéro de lot...',
          optionalPlaceholderText: '(facultatif)',
          upcNumberEmptyInputPlaceholder: 'Entrez le code UPC...',
          upcNumberInputPlaceholder: 'Code UPC...',
          submitLotNumberButton: 'Voir les résultats du test',
          lotNumberHeading: 'Lot No',
          testDateHeading: 'Date du test',
          expirationDateHeading: 'Date d’expiration',
          viewFullReportButton: 'Voir tous les résultats du test',
          startOverButton: 'Recommencer',
          heavyMetalsHeading: 'Métaux lourds',
          pesticidesAndGlyphosateHeading: 'Pesticides et Glyphosate',
          plasticizersHeading: 'Plastifiants (BPA, BPS)',
          standardHeading: 'Norme',
          disclaimer:
            'Les tests ont été effectués conformément aux normes de la Californie AB 899. Pour les informations les plus récentes de la FDA et des conseils sur les effets sur la santé des éléments toxiques chez les enfants, veuillez ',
          disclaimerLink: 'visitez ce lien',
          errors: {
            emptyLotOrUpcNumber:
              'Veuillez entrer un numéro de lot pour continuer',
            noRecordFound:
              'Impossible de trouver les données du test. Assurez-vous que le code UPC ou le numéro de lot est correctement saisi.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Date d’entrée',
          entryIdHeading: 'ID d’entrée',
          sweepstakesEntryStatus: {
            entryApproved: 'Entrée Approuvée',
            entryPending: 'Entrée en Attente',
            entryDenied: 'Entrée Refusée',
            entryExpired: 'Tirage Expiré',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage:
              'Vous avez avec succès entré dans ce tirage. Nous vous contacterons si vous êtes sélectionné.',
            entryPendingReviewMessage:
              'Votre entrée au tirage est en cours de révision. Revenez bientôt pour voir son statut.',
            entryDeniedMessage:
              'Votre entrée a été refusée. Assurez-vous que votre entrée respecte tous les critères pour ce tirage.',
            sweepstakesExpiredMessage:
              'Ce tirage est terminé. Merci d’avoir participé.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Garantie active',
          warrantyHeadingWithMulberry: 'Garantie étendue par Mulberry',
          expiredWarrantyHeading: 'Garantie expirée',
          pendingWarrantyHeading: 'Garantie en attente de révision',
          deniedWarrantyHeading: 'Garantie refusée',
          mulberryDefaultHeader: 'Restez couvert plus longtemps',
          mulberryDefaultDescription:
            'Pour un temps limité, mettez à jour pour étendre votre protection aux tarifs les plus bas, garantis.',
          mulberryDefaultButtonCta: 'Étendre ma couverture de garantie',
          mulberryExpirationNotice:
            'Les extensions de garantie sont gérées par Mulberry Technology Inc. et sont disponibles à l’achat jusqu’à ',
          mulberryPolicyTerms: 'Voir les termes de la police',
          mulberryHelpText: {
            part1: "Les plans d'extension de garantie sont gérés par",
            part2:
              'Contactez help@getmulberry.com pour toute question concernant votre extension de garantie.',
          },
          lifetimeLabel: 'À vie',
          pendingInfoText:
            'Votre enregistrement de garantie est actuellement en cours de révision. Revenez plus tard pour voir son statut.',
          deniedInfoText:
            'Votre enregistrement de garantie a été refusé. Veuillez soumettre un reçu daté valide ci-dessous et assurez-vous que la date d’achat correspond à la date sur le reçu.',
          issueDate: 'Date de début',
          expirationDate: "Date d'expiration",
          termLength: 'Durée du terme',
          fullTermsLink: 'Voir les conditions complètes',
          viewDetails: 'Voir les détails',
          detailsModal: {
            activateWarrantyHeading: 'Activer la garantie',
            updateWarrantyHeading: 'Mettre à jour les détails',
            description:
              "Pour activer votre garantie, veuillez entrer la date d'achat et télécharger un reçu daté.",
            updateDescription:
              "Pour mettre à jour vos détails d'achat, veuillez entrer la nouvelle date d'achat et télécharger un reçu daté.",
            activateButton: 'Activer',
            updateButton: 'Mettre à jour',
            cancelButton: 'Annuler',
          },
          successDrawer: {
            title: 'Garantie activée!',
            description: 'Consultez votre e-mail pour les détails',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Copier le lien',
          copyLinkToastMessage: 'Copié dans le presse-papiers',
          shareLinkButton: 'Partager le lien',
          shareText: 'Jetez un œil à ce produit de Brij',
          helpText: 'Demandez à votre ami de scanner ce code',
        },
        smsDrawer: {
          signUpButton: 'Inscrivez-vous pour les SMS VIP',
          subscriptionDisclaimer:
            "En vous inscrivant par SMS, vous acceptez de recevoir des messages texte marketing automatisés personnalisés et promotionnels récurrents de Canopy au numéro de téléphone utilisé lors de l'inscription. Le consentement n'est pas une condition d'achat. Répondre AIDE pour obtenir de l'aide et STOP pour annuler. La fréquence des messages varie. Les tarifs des messages et des données peuvent s'appliquer. Doit être résident des États-Unis et avoir 18 ans ou plus. Voir Conditions et confidentialité.",
        },
        shopDrawer: {
          successDrawer: {
            title: 'Commande passée!',
            description:
              'Consultez votre e-mail pour une confirmation de commande',
          },
          productDetails: {
            showMore: 'Plus',
            showLess: 'Moins',
          },
          savingBanner: {
            title: 'Vous économisez',
          },
          cartButton: {
            callToAction: 'Ajouter au panier',
            invalidCombo: 'En rupture de stock',
            invalidQuantity: 'Sélectionnez la quantité',
          },
          checkoutHint: {
            chooseOptions: 'Veuillez choisir des options',
            comboUnavailable: 'Combinaison non disponible',
          },
          recommendations: {
            title: 'Recommandations',
            seeMore: 'Voir plus',
          },
          cartResetNotification: 'Un nouveau panier a été créé pour ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Caisse',
          },
          drawerTitle: 'Mon panier',
          emptyCartText:
            "Il n'y a actuellement aucun article dans votre panier",
        },
        discountDrawer: {
          discountCodeHeading: 'Code de réduction:',
          copiedToClipboard: 'Code de réduction copié dans le presse-papiers',
        },
        formDrawer: {
          submit: 'Soumettre',
          next: 'Suivant',
          start: 'Démarrer',
          regFormFetchError:
            "Échec de la récupération du formulaire d'inscription. Veuillez rafraîchir et réessayer.",
          formCompletetionMessage: 'Vos réponses ont été soumises avec succès.',
          formSubmissionError:
            'Échec de la soumission du formulaire. Veuillez soumettre à nouveau',
          submissionSuccessful: 'Soumission Réussie',
          submitAnotherResponse: 'Soumettre une Autre Réponse',
          fileUpload: {
            fileName: 'Nom du fichier...',
            uploadFileButton: 'Télécharger des fichiers',
            syncToS3Error:
              'Échec de la synchronisation du fichier sur s3, veuillez télécharger à nouveau.',
            fileSizeError:
              'La taille du fichier dépasse 20 Mo. Veuillez soumettre à nouveau.',
            fileLimitError:
              'Limite de 5 fichiers dépassée. Veuillez soumettre à nouveau.',
            fileExtensionError: `Extension de fichier non prise en charge. Les fichiers valides sont .pdf, .jpeg,
              .jpg, heic et .png`,
          },
          textArea: {
            placeholder: 'Tapez votre réponse ici...',
            placeholderFilled: 'Votre réponse...',
          },
          dropDown: {
            selectText: 'Cliquez pour sélectionner',
          },
          datePicker: {
            placeholder: 'Sélectionnez une date',
            errorMessage: 'Date non Valide',
          },
          addressFields: {
            countryPlaceholder: 'Choisir un Pays',
            addressLineOnePlaceholder: 'Adresse ligne 1',
            addressLineTwoPlaceholder: 'Adresse ligne 2',
            cityPlaceholder: 'Ville',
            stateDropdownPlaceholder: 'Choisir un État',
            stateFieldPlaceholder: 'État',
            zipcodePlaceholder: 'Code Postal',
          },
          firstNamePlaceholder: 'Prénom',
          lastNamePlaceholder: 'Nom de famille',
        },
      },
    },
  },
  de: {
    // German
    translation: {
      rotationScreen: {
        message:
          'Bitte drehen Sie Ihr Gerät in den Hochformatmodus, um das Erlebnis anzuzeigen',
      },
      registration: {
        productRegistered: 'Produkt registriert!',
        signedUp: 'Sie sind angemeldet!',
        warrantyActivated: 'Garantie aktiviert!',
        completeProfile: 'Profil vervollständigen',
        registrationError:
          'Fehler bei der Registrierung, bitte erneut versuchen',
      },
      socialLogin: {
        withGoogleText: 'mit Google',
        continueGoogleButton: 'Mit Google fortfahren',
        signinError:
          'Es ist ein Fehler aufgetreten. Bitte versuchen Sie erneut, sich anzumelden',
        popupBlockedMessage:
          'wurde von Ihrem Browser blockiert. Bitte deaktivieren Sie Popup-Blocker oder wählen Sie die E-Mail-Option, um fortzufahren.',
        cookiesDisabled:
          'Bitte aktivieren Sie Cookies, um fortzufahren, oder versuchen Sie, sich mit E-Mail zu registrieren.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Beleg hochgeladen.',
        receiptUploadError: 'Fehler beim Hochladen des Belegs',
        noReceiptError: 'Bitte laden Sie den Kaufbeleg hoch',
        firstnameInputPlaceholder: 'Vorname eingeben...',
        firstnameFilledInputPlaceholder: 'Vorname',
        lastnameInputPlaceholder: 'Nachname eingeben...',
        lastnameFilledInputPlaceholder: 'Nachname',
        phoneNumberInputPlaceholder: 'Telefonnummer eingeben...',
        serialNumberInputPlaceholder: 'Seriennummer eingeben...',
        serialNumberFilledInputPlaceholder: 'Seriennummer',
        uploadReceiptButtonText: 'Beleg hochladen',
        datepickerLabel: 'Kaufdatum...',
        datepickerFilledLabel: 'Kaufdatum',
        smsConsentRequired: 'Bitte setzen Sie das Einverständnis-Häkchen',
        invalidType:
          'Ungültiger Dateityp. Bitte laden Sie ein Bild oder PDF hoch',
        invalidDate: 'Ungültiges Datum',
        retailChannelInputPlaceholder: 'Kaufort eingeben...',
        quantitySelectorInputPlaceholder: 'Menge auswählen',
        quantitySelectorInputOther: 'Andere',
        failedToUpload: ' konnte nicht hochgeladen werden',
        submitButtonText: 'Absenden',
        receiptFailedError:
          'Einer oder mehrere Ihrer Belege entsprechen möglicherweise nicht den Anforderungen. Erwägen Sie das erneute Hochladen eines klaren, gut beleuchteten Bildes Ihres Belegs, das die relevanten Zeilen enthält.',
      },
      signIn: {
        pageTitle: 'Bei Brij anmelden',
        pageHeaderTitle: 'Anmelden',
        withEmail: ' mit E-Mail',
        completeProfile: 'Profil vervollständigen',
        useDifferentOption: 'Andere Option verwenden',
        continueWithEmail: 'Mit E-Mail fortfahren',
        unitTagSignInLink: 'Anmelde-Link anfordern',
        checkYourEmail: 'Überprüfen Sie Ihre E-Mails',
        emailInputPlaceholder: 'E-Mail eingeben...',
        emailInputFilledPlaceholder: 'E-Mail',
        toastMessages: {
          invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
          completeRecaptchaVerification:
            'Bitte führen Sie die Recaptcha-Überprüfung durch',
          cantVerifyLoginDetails:
            'Die Anmeldedaten können nicht überprüft werden',
          emailConsentRequired:
            'Bitte setzen Sie das Einverständnis-Häkchen für E-Mail',
          privacyConsentRequired: 'Bitte setzen Sie das Datenschutz-Häkchen',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': 'Bitte geben Sie eine gültige E-Mail-Adresse an',
        'auth/missing-email': 'Bitte geben Sie eine E-Mail-Adresse an',
        'auth/user-not-found': 'Benutzer existiert nicht',
        'auth/wrong-password': 'Ungültige E-Mail-Adresse oder Passwort',
        'auth/internal-error': 'Es ist ein Serverfehler aufgetreten',
        'auth/popup-closed-by-user':
          'Anmeldung abgebrochen. Bitte schließen Sie den Anmeldevorgang ab oder wählen Sie die E-Mail-Option, um fortzufahren',
        'auth/cancelled-popup-request':
          'Anmeldung abgebrochen. Bitte schließen Sie den Anmeldevorgang ab oder wählen Sie die E-Mail-Option, um fortzufahren',
        'auth/invalid-credential-or-provider-id':
          'Ungültige Anmeldeinformationen',
        'auth/unauthorized-domain':
          'Sie greifen von einer nicht sicheren Domain auf die Website zu.',
        'auth/invalid-action-code': 'Der Code ist ungültig oder abgelaufen',
        'auth/popup-blocked':
          'Popup blockiert. Bitte aktivieren Sie Popups oder wählen Sie die E-Mail-Option, um fortzufahren.',
      },
      magicLink: {
        linkSentToastMessage:
          'Willkommen zurück! Überprüfen Sie Ihre E-Mails auf einen Anmelde-Link.',
        magicLinkError: 'Ein Fehler ist aufgetreten',
        redirecting: 'Umleiten...',
      },
      ageGate: {
        title: 'Bestätigen Sie Ihr Alter',
        subtitle: 'Sind Sie {{ageLimit}} oder älter?',
        confirmationButton: 'JA',
        disapprovalButton: 'NEIN',
        warningMessage:
          'Sie müssen {{ageLimit}} Jahre oder älter sein, um diese Website zu sehen!',
      },
      contentGate: {
        enterButton: 'EINGEBEN',
        continueButton: 'WEITER',
        warningMessage:
          'Entschuldigung, Sie müssen mindestens {{ageLimit}} Jahre alt sein',
        invalidYearInput: 'Bitte geben Sie ein gültiges Jahr ein',
      },
      productDetails: {
        viewWarranty: 'Garantie anzeigen',
        leadInformation: {
          registeredTo: 'Registriert auf',
          warranty: 'Garantie',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Tippen zum Suchen...',
        dropdownNoResults: 'Keine Ergebnisse',
      },
      smsOptIn: {
        receiveMessageButton: 'SCHICKEN SIE MIR EINE SMS UND LEGEN SIE LOS',
        sendMessageButton: 'SCHICKEN SIE UNS EINE SMS UND LEGEN SIE LOS',
        trySendingAgain: 'Erneut versuchen',
        sendMeText: 'Senden Sie mir einen Text anstelle davon',
        sendUsText: 'Senden Sie uns einen Text anstelle davon',
        phoneNumberInputPlaceholder: 'Telefonnummer eingeben...',
        consentRequiredError: 'Bitte setzen Sie das Einverständnis-Häkchen',
        checkYourPhone: 'Überprüfen Sie Ihr Telefon auf eine Textnachricht',
        errorSendingRequest:
          'Fehler beim Senden der Anfrage. Bitte versuchen Sie es erneut.',
        messageSentSuccessfully:
          'Bitte überprüfen Sie Ihr Telefon auf eine Textnachricht',
        invalidPhoneNumberError:
          'Bitte geben Sie eine gültige Telefonnummer ein',
        rebateAlreadyClaimedError: 'Dieser Rabatt wurde bereits beansprucht',
        rebateIdError: 'Rabatt-ID nicht gefunden',
        messageSentText:
          'Eine Textnachricht wurde an die angegebene Nummer gesendet. Nicht gefunden? ',
        defaultLegalText: `<p>Indem Sie Ihre Telefonnummer eingeben oder uns eine Textnachricht senden, erklären Sie sich damit einverstanden, wiederkehrende automatisierte Werbe- und personalisierte Marketing-Textnachrichten (z. B. Sonderangebote) von {{brand_name}} an die bei der Anmeldung verwendete Telefonnummer zu erhalten. Die Zustimmung ist keine Bedingung für einen Kauf. Antworten Sie mit HELP für Hilfe und STOP, um abzubrechen. Die Nachrichtenhäufigkeit variiert. Nachrichten- und Datentarife können anfallen. Sehen Sie sich die <a href='https://brij.it/terms' target='_self'>Nutzungsbedingungen und Datenschutzrichtlinien</a> an.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Bitte setzen Sie das Einverständnis-Häkchen',
        firstNameInputPlaceholder: 'Vorname',
        lastNameInputPlaceholder: 'Nachname',
        phoneNumberInputPlaceholder: 'Telefonnummer',
        invalidFirstName: 'Vorname erforderlich',
        invalidLastName: 'Nachname erforderlich',
        invalidPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein',
        completeProfile: 'Profil vervollständigen',
        continueButton: 'Fortfahren',
        skip: 'Ich mache das später',
      },
      sideMenu: {
        myCart: 'Mein Warenkorb',
        myProfile: 'Mein Profil',
        myCollection: 'Meine Sammlung',
        visitWebsite: 'Website besuchen',
        removeProduct: 'Produkt entfernen',
        productRemoved: ' wurde aus Ihrer Sammlung entfernt.',
        signOut: 'Abmelden',
        signIn: 'Anmelden',
        signOutToastMessage: 'Abmeldung erfolgreich',
        translate: 'Übersetzen',
        termsAndPrivacy: 'Nutzungsbedingungen & Datenschutz',
        sweepstakesRules: 'Gewinnspielregeln',
      },
      profile: {
        pageTitle: 'Profil',
        pageHeaderTitle: 'Profil',
        firstNameInput: 'Vorname',
        lastNameInput: 'Nachname',
        emailInput: 'E-Mail',
        phoneNumberInput: 'Telefonnummer',
        saveChanges: 'Änderungen speichern',
        updateToastMessage: 'Profil aktualisiert',
      },
      collection: {
        pageTitle: 'Meine Sammlung',
        collectionPageTitle: 'Meine Sammlung',
        emptyCollectionMessage: 'Keine Produkte in Ihrer Sammlung',
        scanCodeButton: 'Code scannen',
        scanSuccessMessage: 'Produkt gescannt',
        scanErrorMessage: 'Scan-Fehler',
        invalidScanMessage: 'Kein gültiger Brij-Code',
        expireStatus: 'Abgelaufen',
        purchaseStatus: 'Gekauft',
        registerStatus: 'Registriert',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Nutzungsbedingungen & Datenschutz',
        pageTitle: 'Nutzungsbedingungen & Datenschutz',
        subtitle: 'Nutzungsbedingungen und Datenschutzrichtlinie',
        questionsAboutData: 'Fragen zu Ihren Daten?',
        contactUs: 'Kontaktieren Sie uns',
        legalNotice: `Brij Inc. fungiert als Dienstleister für {{brand_name}}, der die Erfassung und Verwaltung von Benutzerdaten, einschließlich, aber nicht beschränkt auf E-Mail-Adressen und Telefonnummern, erleichtert. Diese Partnerschaft ermöglicht es {{brand_name}}, die über die Plattform von Brij Inc. gesammelten Daten für die in ihren jeweiligen Datenschutzrichtlinien und Nutzungsbedingungen festgelegten Zwecke zu nutzen. Brij Inc. verpflichtet sich, diese Daten verantwortungsbewusst und in Übereinstimmung mit allen geltenden Datenschutzgesetzen zu behandeln und sicherzustellen, dass die Privatsphäre der Benutzer im Rahmen unserer Zusammenarbeit mit {{brand_name}} respektiert und geschützt wird.`,
        legalNoticeMixed:
          'Bitte beachten Sie die jeweiligen Datenschutzrichtlinien und Nutzungsbedingungen von Brij Inc. und {{brand_name}} unten für detailliertere Informationen.',
        legalNoticeBrij:
          'Bitte beachten Sie die Datenschutzrichtlinie und die Nutzungsbedingungen von Brij Inc. unten für detailliertere Informationen.',
        termsButton: 'Nutzungsbedingungen',
        privacyButton: 'Datenschutzrichtlinie',
      },
      gameRules: {
        pageTitle: 'Gewinnspielregeln',
        pageHeaderTitle: 'Gewinnspielregeln',
      },
      fourZeroFour: {
        pageTitle: '404 | Nicht gefunden',
        message: 'Dieses Erlebnis existiert nicht',
        learnMoreButton: 'Erfahren Sie mehr über Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Empfehlungen',
      },
      customSelectInput: {
        noOptions: 'KEINE OPTIONEN',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            'Müssen Sie ein weiteres Produkt registrieren? Tippen Sie hier',
          callToActionButton: 'Handlungsaufforderung',
          moreButton: 'Mehr',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'Kaufdetails hinzufügen',
            completeProfileScreen: 'Profil vervollständigen',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1:
              'Mit der Registrierung stimmen Sie E-Mail-Kommunikationen von ',
            part2: ' zu und akzeptieren ',
            brijBrand: ' Brij ',
            linkText: 'Nutzungsbedingungen',
            part3: ' Für Hilfe oder Fragen senden Sie eine E-Mail an ',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'Wenn Sie der Eigentümer dieses Produkts sind und Schwierigkeiten beim Anmelden haben, wenden Sie sich bitte an ',
          },
          unitTag: {
            heading: 'Willkommen Zurück',
            description:
              'Um diesen Inhalt zu sehen, tippen Sie unten auf die Schaltfläche, um einen Anmelde-Link an die registrierte E-Mail-Adresse zu senden.',
          },
          viewDetails: 'Details anzeigen',
          fullTermsLink: 'Vollständige Bedingungen anzeigen',
          emailConsentRequired:
            'Bitte setzen Sie das Einverständnis-Häkchen für E-Mail',
          privacyConsentRequired: 'Bitte setzen Sie das Datenschutz-Häkchen',
          and: 'und',
          sweepstakesRules: 'Gewinnspiel-Regeln',
          successMessage: {
            productRegistered: 'Produkt registriert!',
            signedUp: 'Sie sind angemeldet',
            warrantyActivated: 'Garantie aktiviert!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'Kaufdetails hinzufügen',
          successDrawer: {
            title: 'Produkt registriert!',
            description: 'Überprüfen Sie Ihre E-Mails auf Details',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Testergebnisse suchen',
          enterLotNumber:
            'Geben Sie die Losnummer Ihres Produkts ein, um die Testergebnisse Ihres Produkts anzuzeigen:',
          enterUpcNumber:
            'Geben Sie die 12-stellige UPC-Nummer Ihres Produkts ein, um die Testergebnisse Ihres Produkts anzuzeigen:',
          enterUpcAndLotNumber:
            'Geben Sie die 12-stellige UPC-Nummer und die Losnummer Ihres Produkts ein, um die Testergebnisse Ihres Produkts anzuzeigen:',
          lotNumberEmptyInputPlaceholder: 'Losnummer eingeben...',
          lotNumberInputPlaceholder: 'Losnummer...',
          optionalPlaceholderText: '(optional)',
          upcNumberEmptyInputPlaceholder: 'UPC-Code eingeben...',
          upcNumberInputPlaceholder: 'UPC-Code...',
          submitLotNumberButton: 'Testergebnisse Anzeigen',
          lotNumberHeading: 'Los Nr',
          testDateHeading: 'Testdatum',
          expirationDateHeading: 'Ablaufdatum',
          viewFullReportButton: 'Alle Testergebnisse anzeigen',
          startOverButton: 'Neu Starten',
          heavyMetalsHeading: 'Schwermetalle',
          pesticidesAndGlyphosateHeading: 'Pestizide und Glyphosat',
          plasticizersHeading: 'Weichmacher (BPA, BPS)',
          standardHeading: 'Standard',
          disclaimer:
            'Die Tests wurden gemäß den Standards von California AB 899 durchgeführt. Für die aktuellsten Informationen der FDA und Ratschläge zu den gesundheitlichen Auswirkungen von giftigen Elementen bei Kindern besuchen Sie bitte ',
          disclaimerLink: 'diesen Link',
          errors: {
            emptyLotOrUpcNumber:
              'Bitte geben Sie eine Losnummer ein, um fortzufahren',
            noRecordFound:
              'Testdaten konnten nicht gefunden werden. Stellen Sie sicher, dass der UPC-Code oder die Losnummer korrekt eingegeben wurden.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Eintrittsdatum',
          entryIdHeading: 'Eintritts-ID',
          sweepstakesEntryStatus: {
            entryApproved: 'Eintrag Genehmigt',
            entryPending: 'Eintrag Ausstehend',
            entryDenied: 'Eintrag Abgelehnt',
            entryExpired: 'Eintrag Abgelaufen',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage:
              'Sie haben erfolgreich an diesem Gewinnspiel teilgenommen. Wir werden',
            entryPendingReviewMessage:
              'Ihr Gewinnspiel-Eintrag wird derzeit überprüft. Kommen Sie bald zurück, um seinen Status zu sehen.',
            entryDeniedMessage:
              'Ihr Eintrag wurde abgelehnt. Stellen Sie sicher, dass Ihr Eintrag alle Kriterien für dieses Gewinnspiel erfüllt.',
            sweepstakesExpiredMessage:
              'Dieses Gewinnspiel ist beendet. Vielen Dank für Ihre Teilnahme.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Garantie aktiv',
          warrantyHeadingWithMulberry: 'Garantie erweitert durch Mulberry',
          expiredWarrantyHeading: 'Garantie abgelaufen',
          pendingWarrantyHeading: 'Garantie ausstehend',
          deniedWarrantyHeading: 'Garantie abgelehnt',
          mulberryDefaultHeader: 'Bleiben Sie länger geschützt',
          mulberryDefaultDescription:
            'Aktualisieren Sie für eine begrenzte Zeit, um Ihren Schutz zu den garantiert niedrigsten Preisen zu erweitern.',
          mulberryDefaultButtonCta: 'Erweitern Sie meinen Garantieschutz',
          mulberryExpirationNotice:
            'Garantieerweiterungen werden von Mulberry Technology Inc. verwaltet und können bis zum Kaufdatum erworben werden',
          mulberryPolicyTerms: 'Siehe Versicherungsbedingungen',
          mulberryHelpText: {
            part1: 'Erweiterte Garantiepläne werden verwaltet von',
            part2:
              'Wenden Sie sich bei Fragen zu Ihrer erweiterten Garantie an help@getmulberry.com.',
          },
          lifetimeLabel: 'Lebensdauer',
          pendingInfoText:
            'Ihre Garantieregistrierung wird derzeit überprüft. Überprüfen Sie später den Status.',
          deniedInfoText:
            'Ihre Garantieregistrierung wurde abgelehnt. Bitte laden Sie unten einen gültigen datierten Beleg hoch und stellen Sie sicher, dass das Kaufdatum mit dem Datum auf dem Beleg übereinstimmt.',
          issueDate: 'Startdatum',
          expirationDate: 'Ablaufdatum',
          termLength: 'Laufzeit',
          fullTermsLink: 'Vollständige Bedingungen anzeigen',
          viewDetails: 'Details anzeigen',
          detailsModal: {
            activateWarrantyHeading: 'Garantie aktivieren',
            updateWarrantyHeading: 'Details aktualisieren',
            description:
              'Um Ihre Garantie zu aktivieren, geben Sie bitte das Kaufdatum ein und laden Sie einen datierten Beleg hoch.',
            updateDescription:
              'Um Ihre Kaufdetails zu aktualisieren, geben Sie bitte das neue Kaufdatum ein und laden Sie einen datierten Beleg hoch.',
            activateButton: 'Aktivieren',
            updateButton: 'Aktualisieren',
            cancelButton: 'Abbrechen',
          },
          successDrawer: {
            title: 'Garantie aktiviert!',
            description: 'Überprüfen Sie Ihre E-Mails auf Details',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Link kopieren',
          copyLinkToastMessage: 'In die Zwischenablage kopiert',
          shareLinkButton: 'Link teilen',
          shareText: 'Schauen Sie sich dieses Produkt von Brij an',
          helpText: 'Lassen Sie Ihren Freund diesen Code scannen',
        },
        smsDrawer: {
          signUpButton: 'Melden Sie sich für VIP-Texte an',
          subscriptionDisclaimer:
            'Durch die Anmeldung per SMS erklären Sie sich damit einverstanden, wiederkehrende automatisierte personalisierte und werbliche Marketing-Textnachrichten von Canopy an der verwendeten Telefonnummer zu erhalten. Die Zustimmung ist keine Bedingung für einen Kauf. Antworten Sie mit HELP für Hilfe und STOP, um abzubrechen. Die Nachrichtenhäufigkeit variiert. Nachrichten- und Datentarife können anfallen. Sie müssen in den USA wohnhaft sein und mindestens 18 Jahre alt sein. Nutzungsbedingungen und Datenschutz anzeigen.',
        },
        shopDrawer: {
          successDrawer: {
            title: 'Bestellung aufgegeben!',
            description:
              'Überprüfen Sie Ihre E-Mails auf eine Bestellbestätigung',
          },
          productDetails: {
            showMore: 'Mehr',
            showLess: 'Weniger',
          },
          savingBanner: {
            title: 'Sie sparen',
          },
          cartButton: {
            callToAction: 'Zum Warenkorb hinzufügen',
            invalidCombo: 'Nicht vorrätig',
            invalidQuantity: 'Menge auswählen',
          },
          checkoutHint: {
            chooseOptions: 'Bitte wählen Sie Optionen',
            comboUnavailable: 'Kombination nicht verfügbar',
          },
          recommendations: {
            title: 'Empfehlungen',
            seeMore: 'Mehr anzeigen',
          },
          cartResetNotification: 'Ein neuer Warenkorb wurde für ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Zur Kasse',
          },
          drawerTitle: 'Mein Warenkorb',
          emptyCartText: 'Derzeit sind keine Artikel in Ihrem Warenkorb',
        },
        discountDrawer: {
          discountCodeHeading: 'Rabattcode:',
          copiedToClipboard: 'Rabattcode in die Zwischenablage kopiert',
        },
        formDrawer: {
          submit: 'Absenden',
          next: 'Weiter',
          start: 'Start',
          regFormFetchError:
            'Das Abrufen des Registrierungsformulars war nicht erfolgreich. Bitte aktualisieren und erneut versuchen.',
          formCompletetionMessage:
            'Ihre Antworten wurden erfolgreich übermittelt.',
          formSubmissionError:
            'Formularübermittlung fehlgeschlagen. Bitte erneut senden',
          submissionSuccessful: 'Übermittlung Erfolgreich',
          submitAnotherResponse: 'Eine Weitere Antwort Senden',
          fileUpload: {
            fileName: 'Dateiname...',
            uploadFileButton: 'Dateien hochladen',
            syncToS3Error:
              'Datei konnte nicht synchronisiert werden. Bitte erneut hochladen.',
            fileSizeError:
              'Dateigröße überschreitet 20 MB. Bitte erneut senden.',
            fileLimitError: '5-Datei-Limit überschritten. Bitte erneut senden.',
            fileExtensionError: `Dateierweiterung wird nicht unterstützt. Gültige Dateien sind .pdf, .jpeg,
              .jpg, heic und .png`,
          },
          textArea: {
            placeholder: 'Geben Sie Ihre Antwort hier ein...',
            placeholderFilled: 'Ihre Antwort...',
          },
          dropDown: {
            selectText: 'Klicken zum Auswählen',
          },
          datePicker: {
            placeholder: 'Datum auswählen',
            errorMessage: 'Ungültiges Datum',
          },
          addressFields: {
            countryPlaceholder: 'Land auswählen',
            addressLineOnePlaceholder: 'Adresszeile 1',
            addressLineTwoPlaceholder: 'Adresszeile 2',
            cityPlaceholder: 'Stadt',
            stateDropdownPlaceholder: 'Bundesland auswählen',
            stateFieldPlaceholder: 'Bundesland',
            zipcodePlaceholder: 'Postleitzahl',
          },
          firstNamePlaceholder: 'Vorname',
          lastNamePlaceholder: 'Nachname',
        },
      },
    },
  },
  tr: {
    // Turkish
    translation: {
      rotationScreen: {
        message: 'Deneyimi görmek için cihazınızı dikey moda çevirin',
      },
      registration: {
        productRegistered: 'Ürün Kaydedildi!',
        signedUp: 'Kaydoldunuz!',
        warrantyActivated: 'Garanti Aktif!',
        completeProfile: 'Profili Tamamla',
        registrationError:
          'Kayıt sırasında bir hata oluştu, lütfen tekrar deneyin',
      },
      socialLogin: {
        withGoogleText: 'Google ile',
        continueGoogleButton: 'Google ile Devam Et',
        signinError: 'Bir hata oluştu. Lütfen tekrar giriş yapmayı deneyin',
        popupBlockedMessage:
          'tarafından engellendi. Devam etmek için popup engelleyicileri devre dışı bırakın veya devam etmek için e-posta seçeneğini seçin.',
        cookiesDisabled:
          'Devam etmek için çerezleri etkinleştirin veya e-posta ile kayıt olmayı deneyin.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Fiş yüklendi.',
        receiptUploadError: 'Fiş yüklenirken hata oluştu',
        noReceiptError: 'Lütfen satın alma fişini yükleyin',
        firstnameInputPlaceholder: 'Adınızı girin...',
        firstnameFilledInputPlaceholder: 'Ad',
        lastnameInputPlaceholder: 'Soyadınızı girin...',
        lastnameFilledInputPlaceholder: 'Soyad',
        phoneNumberInputPlaceholder: 'Telefon numarasını girin...',
        serialNumberInputPlaceholder: 'Seri numarasını girin...',
        serialNumberFilledInputPlaceholder: 'Seri numarası',
        uploadReceiptButtonText: 'Fiş Yükle',
        datepickerLabel: 'Satın alma tarihi...',
        datepickerFilledLabel: 'Satın alma tarihi',
        smsConsentRequired: 'Lütfen onay kutusunu işaretleyin',
        invalidType: 'Geçersiz dosya türü. Lütfen bir resim veya PDF yükleyin',
        invalidDate: 'Geçersiz Tarih',
        retailChannelInputPlaceholder: 'Satın alma yeri girin...',
        quantitySelectorInputPlaceholder: 'Miktar seçin',
        quantitySelectorInputOther: 'Diğer',
        failedToUpload: ' yüklenemedi',
        submitButtonText: 'Gönder',
        receiptFailedError:
          'Bir veya daha fazla fişiniz gereksinimleri karşılamayabilir. İlgili satırları içeren fişinizin net ve iyi aydınlatılmış bir resmini tekrar yüklemeyi düşünün.',
      },
      signIn: {
        pageTitle: "Brij'e Giriş Yap",
        pageHeaderTitle: 'Giriş Yap',
        withEmail: ' E-posta ile',
        completeProfile: 'Profili Tamamla',
        useDifferentOption: 'Farklı bir seçenek kullan',
        continueWithEmail: 'E-posta ile Devam Et',
        unitTagSignInLink: 'Giriş Bağlantısı İste',
        checkYourEmail: 'E-postanızı kontrol edin',
        emailInputPlaceholder: 'E-posta adresinizi girin...',
        emailInputFilledPlaceholder: 'E-posta',
        toastMessages: {
          invalidEmail: 'Lütfen geçerli bir e-posta adresi girin',
          completeRecaptchaVerification:
            'Lütfen Recaptcha doğrulamasını tamamlayın',
          cantVerifyLoginDetails: 'Giriş detayları doğrulanamıyor',
          emailConsentRequired: 'Lütfen E-posta onay kutusunu işaretleyin',
          privacyConsentRequired: 'Lütfen Gizlilik onay kutusunu işaretleyin',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': 'Lütfen geçerli bir e-posta adresi girin',
        'auth/missing-email': 'Lütfen bir e-posta adresi girin',
        'auth/user-not-found': 'Kullanıcı bulunamadı',
        'auth/wrong-password': 'Geçersiz e-posta veya şifre',
        'auth/internal-error': 'Sunucuda bir hata oluştu',
        'auth/popup-closed-by-user':
          'Giriş iptal edildi. Lütfen giriş işlemini tamamlayın veya devam etmek için e-posta seçeneğini seçin',
        'auth/cancelled-popup-request':
          'Giriş iptal edildi. Lütfen giriş işlemini tamamlayın veya devam etmek için e-posta seçeneğini seçin',
        'auth/invalid-credential-or-provider-id': 'Geçersiz kimlik bilgileri',
        'auth/unauthorized-domain':
          'Güvensiz bir alan adından siteye erişiyorsunuz.',
        'auth/invalid-action-code': 'Kod geçersiz veya süresi dolmuş',
        'auth/popup-blocked':
          "Popup engellendi. Devam etmek için popup'ları etkinleştirin veya e-posta seçeneğini seçin.",
      },
      magicLink: {
        linkSentToastMessage:
          'Tekrar hoş geldiniz! Giriş bağlantısı için e-postanızı kontrol edin.',
        magicLinkError: 'Bir hata oluştu',
        redirecting: 'Yönlendiriliyor...',
      },
      ageGate: {
        title: 'Yaşınızı Onaylayın',
        subtitle: '{{ageLimit}} yaşında mısınız?',
        confirmationButton: 'EVET',
        disapprovalButton: 'HAYIR',
        warningMessage:
          'Bu siteyi görmek için {{ageLimit}} yaşında olmalısınız!',
      },
      contentGate: {
        enterButton: 'GİRİN',
        continueButton: 'DEVAM',
        warningMessage: 'Üzgünüz, en az {{ageLimit}} yaşında olmalısınız',
        invalidYearInput: 'Lütfen geçerli bir yıl girin',
      },
      productDetails: {
        viewWarranty: 'Garantiyi Görüntüle',
        leadInformation: {
          registeredTo: 'Kayıtlı',
          warranty: 'Garanti',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Aramak için dokunun...',
        dropdownNoResults: 'Sonuç yok',
      },
      smsOptIn: {
        receiveMessageButton: 'MESAJ ATIN VE BAŞLAYIN',
        sendMessageButton: 'BİZE MESAJ ATIN VE BAŞLAYIN',
        trySendingAgain: 'Tekrar deneyin',
        sendMeText: 'Bana bir metin gönder',
        sendUsText: 'Bize bir metin gönder',
        phoneNumberInputPlaceholder: 'Telefon numarasını girin...',
        consentRequiredError: 'Lütfen onay kutusunu işaretleyin',
        checkYourPhone: 'Metin mesajı için telefonunuzu kontrol edin',
        errorSendingRequest:
          'İstek gönderilirken hata oluştu. Lütfen tekrar deneyin.',
        messageSentSuccessfully:
          'Lütfen metin mesajı için telefonunuzu kontrol edin',
        invalidPhoneNumberError: 'Lütfen geçerli bir telefon numarası girin',
        rebateAlreadyClaimedError: 'Bu indirim zaten talep edildi',
        rebateIdError: 'İndirim Kimliği bulunamadı',
        messageSentText:
          'Belirtilen numaraya bir metin mesajı gönderildi. Göremiyor musunuz? ',
        defaultLegalText: `<p>Telefon numaranızı girerek veya bize bir metin mesajı göndererek, {{brand_name}}'dan tekrarlayan otomatik kişiselleştirilmiş ve promosyonel pazarlama metin mesajları (örneğin özel teklifler) almayı kabul edersiniz. Onay, herhangi bir satın alma koşulu değildir. Yardım için YARDIM yazın ve İPTAL yazarak iptal edin. Mesaj sıklığı değişir. Mesaj ve veri ücretleri uygulanabilir. ABD vatandaşı olmalı ve 18 yaşından büyük olmalısınız. Şartlar ve Gizlilik'i görüntüleyin.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Lütfen onay kutusunu işaretleyin',
        firstNameInputPlaceholder: 'Ad',
        lastNameInputPlaceholder: 'Soyad',
        phoneNumberInputPlaceholder: 'Telefon Numarası',
        invalidFirstName: 'Ad gerekli',
        invalidLastName: 'Soyad gerekli',
        invalidPhoneNumber: 'Lütfen geçerli bir telefon numarası girin',
        completeProfile: 'Profili Tamamla',
        continueButton: 'Devam Et',
        skip: `Daha sonra yapacağım`,
      },
      sideMenu: {
        myCart: 'Sepetim',
        myProfile: 'Profilim',
        myCollection: 'Koleksiyonum',
        visitWebsite: 'Web Sitesini Ziyaret Et',
        removeProduct: 'Ürünü Kaldır',
        productRemoved: ' koleksiyonunuzdan kaldırıldı.',
        signOut: 'Çıkış Yap',
        signIn: 'Giriş Yap',
        signOutToastMessage: 'Çıkış Başarılı',
        translate: 'Çevir',
        termsAndPrivacy: 'Şartlar ve Gizlilik',
        sweepstakesRules: 'Çekiliş Kuralları',
      },
      profile: {
        pageTitle: 'Profil',
        pageHeaderTitle: 'Profil',
        firstNameInput: 'Ad',
        lastNameInput: 'Soyad',
        emailInput: 'E-posta',
        phoneNumberInput: 'Telefon Numarası',
        saveChanges: 'Değişiklikleri Kaydet',
        updateToastMessage: 'Profil Güncellendi',
      },
      collection: {
        pageTitle: 'Koleksiyonum',
        collectionPageTitle: 'Koleksiyonum',
        emptyCollectionMessage: 'Koleksiyonunuzda Ürün Yok',
        scanCodeButton: 'Kodu Tara',
        scanSuccessMessage: 'Ürün Tarandı',
        scanErrorMessage: 'Tarama Hatası',
        invalidScanMessage: 'Geçerli bir Brij kodu değil',
        expireStatus: 'Süresi Doldu',
        purchaseStatus: 'Satın Alındı',
        registerStatus: 'Kayıtlı',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Şartlar ve Gizlilik',
        pageTitle: 'Şartlar ve Gizlilik',
        subtitle: 'Şartlar ve Koşullar ve Gizlilik Politikası',
        questionsAboutData: 'Verilerinizle ilgili sorularınız mı var?',
        contactUs: 'Bizimle iletişime geçin',
        legalNotice: `Brij Inc., {{brand_name}} için bir hizmet sağlayıcısı olarak hareket eder ve kullanıcı verilerinin toplanması ve yönetilmesini kolaylaştırır, bunlarla sınırlı olmamak üzere e-posta adresleri ve telefon numaraları. Bu ortaklık, {{brand_name}}'ın Brij Inc.'in platformu aracılığıyla toplanan verileri, ilgili gizlilik politikalarında ve hizmet şartlarında belirtilen amaçlar için kullanmasına olanak tanır. Brij Inc., bu verileri sorumlu bir şekilde ele almayı ve tüm geçerli veri koruma yasalarına uygun olarak, kullanıcı gizliliğinin, {{brand_name}} ile işbirliğimiz sırasında saygı gösterilerek korunduğundan emin olmayı taahhüt eder.`,
        legalNoticeMixed: `Daha ayrıntılı bilgi için lütfen aşağıda Brij Inc. ve {{brand_name}}'ın ilgili gizlilik politikaları ve şartlarını inceleyin.`,
        legalNoticeBrij: `Daha ayrıntılı bilgi için lütfen aşağıda Brij Inc.'in gizlilik politikasını ve şartlarını inceleyin.`,
        termsButton: 'Şartlar ve Koşullar',
        privacyButton: 'Gizlilik Politikası',
      },
      gameRules: {
        pageTitle: 'Çekiliş Kuralları',
        pageHeaderTitle: 'Çekiliş Kuralları',
      },
      fourZeroFour: {
        pageTitle: '404 | Bulunamadı',
        message: 'Bu deneyim mevcut değil',
        learnMoreButton: 'Brij hakkında bilgi alın',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Öneriler',
      },
      customSelectInput: {
        noOptions: 'SEÇENEK YOK',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            'Başka bir ürün kaydetmek mi istiyorsunuz? Buraya dokunun',
          callToActionButton: 'Eylem Çağrısı',
          moreButton: 'Daha Fazla',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'Satın Alma Detaylarını Ekleyin',
            completeProfileScreen: 'Profili Tamamlayın',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1: 'Kaydolduğunuzda, ',
            part2:
              ' tarafından e-posta ile iletişim almayı kabul edersiniz ve ',
            brijBrand: " Brij'in ",
            linkText: 'Şartlar ve Koşullar',
            part3: ' için e-posta göndermek için ',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'Bu ürünün sahibiyseniz ve giriş yapmakta zorluk yaşıyorsanız, lütfen ',
          },
          unitTag: {
            heading: 'Tekrar Hoş Geldiniz',
            description:
              'Bu içeriği görmek için aşağıdaki düğmeye tıklayın ve kayıtlı e-posta adresinize bir giriş bağlantısı gönderelim.',
          },
          viewDetails: 'Detayları Görüntüle',
          fullTermsLink: 'Tüm şartları görüntüle',
          emailConsentRequired: 'Lütfen E-posta onay kutusunu işaretleyin',
          privacyConsentRequired: 'Lütfen Gizlilik onay kutusunu işaretleyin',
          and: 've',
          sweepstakesRules: 'Çekiliş Kuralları',
          successMessage: {
            productRegistered: 'Ürün Kaydedildi!',
            signedUp: 'Kaydoldunuz',
            warrantyActivated: 'Garanti Aktif!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'Satın Alma Detaylarını Ekleyin',
          successDrawer: {
            title: 'Ürün Kaydedildi!',
            description: 'Detaylar için e-postanızı kontrol edin',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Test Sonuçlarını Ara',
          enterLotNumber:
            'Ürününüzün test sonuçlarını görmek için lot numaranızı girin:',
          enterUpcNumber:
            'Ürününüzün test sonuçlarını görmek için 12 haneli UPC numaranızı girin:',
          enterUpcAndLotNumber:
            'Ürününüzün test sonuçlarını görmek için 12 haneli UPC numaranızı ve lot numaranızı girin:',
          lotNumberEmptyInputPlaceholder: 'Lot numarası girin...',
          lotNumberInputPlaceholder: 'Lot numarası...',
          optionalPlaceholderText: '(isteğe bağlı)',
          upcNumberEmptyInputPlaceholder: 'UPC kodu girin...',
          upcNumberInputPlaceholder: 'UPC kodu...',
          submitLotNumberButton: 'Test Sonuçlarını Görüntüle',
          lotNumberHeading: 'Lot No',
          testDateHeading: 'Test Tarihi',
          expirationDateHeading: 'Son Kullanma Tarihi',
          viewFullReportButton: 'Tüm Test Sonuçlarını Görüntüle',
          startOverButton: 'Yeniden Başlat',
          heavyMetalsHeading: 'Ağır Metaller',
          pesticidesAndGlyphosateHeading: 'Pestisitler ve Glifosat',
          plasticizersHeading: 'Plastikleştiriciler (BPA, BPS)',
          standardHeading: 'Standart',
          disclaimer:
            'Testler, California AB 899 standartlarına göre gerçekleştirilmiştir. En son FDA bilgileri ve çocuklarda toksik elementlerin sağlık etkileri hakkında tavsiyeler için lütfen ',
          disclaimerLink: 'bu bağlantıya',
          errors: {
            emptyLotOrUpcNumber: 'Devam etmek için bir lot numarası girin',
            noRecordFound:
              'Test verileri bulunamadı. Lütfen UPC kodu veya lot numarasının doğru girildiğinden emin olun.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Giriş Tarihi',
          entryIdHeading: 'Giriş Kimliği',
          sweepstakesEntryStatus: {
            entryApproved: 'Giriş Onaylandı',
            entryPending: 'Giriş Beklemede',
            entryDenied: 'Giriş Reddedildi',
            entryExpired: 'Giriş Süresi Doldu',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage: 'Bu çekilişe başarıyla katıldınız. Biz',
            entryPendingReviewMessage:
              'Çekiliş girişiniz şu anda inceleniyor. Durumu görmek için daha sonra tekrar kontrol edin.',
            entryDeniedMessage:
              'Girişiniz reddedildi. Girişinizin bu çekiliş için tüm gereksinimleri karşıladığından emin olun.',
            sweepstakesExpiredMessage:
              'Bu çekiliş sona erdi. Katılımınız için teşekkür ederiz.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Garanti Aktif',
          warrantyHeadingWithMulberry:
            'Mulberry tarafından genişletilen garanti',
          expiredWarrantyHeading: 'Garanti Süresi Doldu',
          pendingWarrantyHeading: 'Garanti İnceleme Aşamasında',
          deniedWarrantyHeading: 'Garanti Reddedildi',
          mulberryDefaultHeader: 'Daha Uzun Süre Kapalı Kalın',
          mulberryDefaultDescription:
            'Sınırlı bir süre için, korumanızı garantili en düşük oranlarla genişletmek için yükseltme yapın.',
          mulberryDefaultButtonCta: 'Garanti Kapsamımı Uzat',
          mulberryExpirationNotice:
            'Garanti uzatmaları Mulberry Technology Inc. tarafından yönetilir ve satın alma tarihine kadar satın alınabilir',
          mulberryPolicyTerms: 'Sigorta Koşullarını Görüntüle',
          mulberryHelpText: {
            part1: 'Uzatılmış garanti planları şu kişi tarafından yönetilir:',
            part2:
              'Uzatılmış garantinizle ilgili sorularınız için help@getmulberry.com ile iletişime geçin.',
          },
          lifetimeLabel: 'Ömür Boyu',
          pendingInfoText:
            'Garanti kaydınız şu anda inceleniyor. Durumu görmek için daha sonra tekrar kontrol edin.',
          deniedInfoText:
            'Garanti kaydınız reddedildi. Lütfen aşağıda yeni bir tarihli fiş girin ve satın alma tarihinin fişteki tarihle eşleştiğinden emin olun.',
          issueDate: 'Başlangıç Tarihi',
          expirationDate: 'Son Kullanma Tarihi',
          termLength: 'Dönem Uzunluğu',
          fullTermsLink: 'Tüm şartları görüntüle',
          viewDetails: 'Detayları Görüntüle',
          detailsModal: {
            activateWarrantyHeading: 'Garantiyi Aktive Et',
            updateWarrantyHeading: 'Detayları Güncelle',
            description:
              'Garantinizi aktive etmek için lütfen satın alma tarihini girin ve tarihli bir fiş yükleyin.',
            updateDescription:
              'Satın alma detaylarınızı güncellemek için lütfen yeni satın alma tarihini girin ve tarihli bir fiş yükleyin.',
            activateButton: 'Aktive Et',
            updateButton: 'Güncelle',
            cancelButton: 'İptal',
          },
          successDrawer: {
            title: 'Garanti Aktif!',
            description: 'Detaylar için e-postanızı kontrol edin',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Bağlantıyı Kopyala',
          copyLinkToastMessage: 'Panoya Kopyalandı',
          shareLinkButton: 'Bağlantıyı Paylaş',
          shareText: "Brij'den bu ürüne göz atın",
          helpText: 'Arkadaşınızın bu kodu taramasına izin verin',
        },
        smsDrawer: {
          signUpButton: 'VIP metinlere kaydol',
          subscriptionDisclaimer:
            "Metin aracılığıyla kaydolursanız, Canopy'den tekrarlayan otomatik kişiselleştirilmiş ve promosyonel pazarlama metin mesajları almayı kabul edersiniz. Onay, herhangi bir satın alma koşulu değildir. Yardım için YARDIM yazın ve İPTAL yazarak iptal edin. Mesaj sıklığı değişir. Mesaj ve veri ücretleri uygulanabilir. ABD vatandaşı olmalı ve 18 yaşından büyük olmalısınız. Şartlar ve Gizlilik'i görüntüleyin.",
        },
        shopDrawer: {
          successDrawer: {
            title: 'Sipariş Verildi!',
            description: 'Sipariş onayınız için e-postanızı kontrol edin',
          },
          productDetails: {
            showMore: 'Daha Fazla',
            showLess: 'Daha Az',
          },
          savingBanner: {
            title: 'Tasarruf Ediyorsunuz',
          },
          cartButton: {
            callToAction: 'Sepete Ekle',
            invalidCombo: 'Stokta Yok',
            invalidQuantity: 'Miktar Seçin',
          },
          checkoutHint: {
            chooseOptions: 'Lütfen seçenekleri seçin',
            comboUnavailable: 'Kombinasyon mevcut değil',
          },
          recommendations: {
            title: 'Öneriler',
            seeMore: 'Daha Fazla Gör',
          },
          cartResetNotification: 'İçin yeni bir sepet oluşturuldu ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Ödeme Yap',
          },
          drawerTitle: 'Sepetim',
          emptyCartText: 'Şu anda sepette ürün yok',
        },
        discountDrawer: {
          discountCodeHeading: 'İndirim Kodu:',
          copiedToClipboard: 'İndirim Kodu panoya kopyalandı',
        },
        formDrawer: {
          submit: 'Gönder',
          next: 'İleri',
          start: 'Başla',
          regFormFetchError:
            'Kayıt formu alınamadı. Lütfen yenileyin ve tekrar deneyin.',
          formCompletetionMessage: 'Yanıtlarınız başarıyla gönderildi.',
          formSubmissionError:
            'Form gönderimi başarısız oldu. Lütfen yeniden gönderin',
          submissionSuccessful: 'Gönderim Başarılı',
          submitAnotherResponse: 'Başka Bir Yanıt Gönder',
          fileUpload: {
            fileName: 'Dosya Adı...',
            uploadFileButton: 'Dosyaları Yükle',
            syncToS3Error:
              "Dosya s3'e senkronize edilemedi lütfen tekrar yükleyin.",
            fileSizeError:
              "Dosya boyutu 20MB'yi aşıyor. Lütfen yeniden gönderin.",
            fileLimitError: '5 dosya limiti aşıldı. Lütfen yeniden gönderin.',
            fileExtensionError: `Dosya uzantısı desteklenmiyor. Geçerli dosyalar .pdf, .jpeg,
              .jpg, heic ve .png`,
          },
          textArea: {
            placeholder: 'Cevabınızı buraya yazın...',
            placeholderFilled: 'Cevabınız...',
          },
          dropDown: {
            selectText: 'Seçmek için tıklayın',
          },
          datePicker: {
            placeholder: 'Tarih seçin',
            errorMessage: 'Geçersiz Tarih',
          },
          addressFields: {
            countryPlaceholder: 'Ülke seçin',
            addressLineOnePlaceholder: 'Adres Satırı 1',
            addressLineTwoPlaceholder: 'Adres Satırı 2',
            cityPlaceholder: 'Şehir',
            stateDropdownPlaceholder: 'Eyalet seçin',
            stateFieldPlaceholder: 'Eyalet',
            zipcodePlaceholder: 'Posta Kodu',
          },
          firstNamePlaceholder: 'İlk Adı',
          lastNamePlaceholder: 'Soy Isim',
        },
      },
    },
  },
  pt: {
    // Portuguese
    translation: {
      rotationScreen: {
        message:
          'Por favor, gire o dispositivo para o modo retrato para visualizar a experiência',
      },
      registration: {
        productRegistered: 'Produto registrado!',
        signedUp: 'Você está inscrito!',
        warrantyActivated: 'Garantia ativada!',
        completeProfile: 'Complete o perfil',
        registrationError:
          'Ocorreu um erro durante o registro, por favor, tente novamente',
      },
      socialLogin: {
        withGoogleText: 'com Google',
        continueGoogleButton: 'Continuar com Google',
        signinError: 'Houve um erro. Por favor, tente fazer login novamente',
        popupBlockedMessage:
          'foi bloqueado pelo seu navegador. Por favor, desative os bloqueadores de pop-up ou escolha a opção de e-mail para continuar.',
        cookiesDisabled:
          'Por favor, ative os cookies para continuar ou tente se registrar com e-mail.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Recibo enviado.',
        receiptUploadError: 'Erro ao enviar o recibo',
        noReceiptError: 'Por favor, envie o recibo de compra',
        firstnameInputPlaceholder: 'Digite o primeiro nome...',
        firstnameFilledInputPlaceholder: 'Primeiro nome',
        lastnameInputPlaceholder: 'Digite o sobrenome...',
        lastnameFilledInputPlaceholder: 'Sobrenome',
        phoneNumberInputPlaceholder: 'Digite o número de telefone...',
        serialNumberInputPlaceholder: 'Digite o número de série...',
        serialNumberFilledInputPlaceholder: 'Número de série',
        uploadReceiptButtonText: 'Enviar recibo',
        datepickerLabel: 'Data da compra...',
        datepickerFilledLabel: 'Data da compra',
        smsConsentRequired: 'Por favor, marque a caixa de consentimento',
        invalidType:
          'Tipo de arquivo inválido. Por favor, envie uma imagem ou PDF',
        invalidDate: 'Data inválida',
        retailChannelInputPlaceholder: 'Digite o local da compra...',
        quantitySelectorInputPlaceholder: 'Selecione a quantidade',
        quantitySelectorInputOther: 'Outro',
        failedToUpload: ' falhou ao enviar',
        submitButtonText: 'Enviar',
        receiptFailedError:
          'Um ou mais recibos podem não atender aos requisitos. Considere reenviar uma imagem clara e bem iluminada do seu recibo.',
      },
      signIn: {
        pageTitle: 'Entrar no Brij',
        pageHeaderTitle: 'Entrar',
        withEmail: ' com e-mail',
        completeProfile: 'Complete o perfil',
        useDifferentOption: 'Usar uma opção diferente',
        continueWithEmail: 'Continuar com e-mail',
        unitTagSignInLink: 'Solicitar link de login',
        checkYourEmail: 'Verifique seu e-mail',
        emailInputPlaceholder: 'Digite o e-mail...',
        emailInputFilledPlaceholder: 'E-mail',
        toastMessages: {
          invalidEmail: 'Por favor, insira um endereço de e-mail válido',
          completeRecaptchaVerification:
            'Por favor, complete a verificação do Recaptcha',
          cantVerifyLoginDetails:
            'Não é possível verificar os detalhes do login',
          emailConsentRequired:
            'Por favor, marque a caixa de consentimento de e-mail',
          privacyConsentRequired:
            'Por favor, marque a caixa de consentimento de privacidade',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': 'Por favor, forneça um e-mail válido',
        'auth/missing-email': 'Por favor, forneça um e-mail',
        'auth/user-not-found': 'Usuário não existe',
        'auth/wrong-password': 'E-mail ou senha inválidos',
        'auth/internal-error': 'Ocorreu um erro no servidor',
        'auth/popup-closed-by-user':
          'Login cancelado. Por favor, complete o processo de login ou escolha a opção de e-mail para continuar',
        'auth/cancelled-popup-request':
          'Login cancelado. Por favor, complete o processo de login ou escolha a opção de e-mail para continuar',
        'auth/invalid-credential-or-provider-id': 'Credenciais inválidas',
        'auth/unauthorized-domain':
          'Você está acessando o site de um domínio não seguro.',
        'auth/invalid-action-code': 'O código é inválido ou expirou',
        'auth/popup-blocked':
          'Popup bloqueado. Por favor, habilite popups ou escolha a opção de e-mail para continuar.',
      },
      magicLink: {
        linkSentToastMessage:
          'Bem-vindo de volta! Verifique seu e-mail para um link de login.',
        magicLinkError: 'Ocorreu um erro',
        redirecting: 'Redirecionando...',
      },
      ageGate: {
        title: 'Confirme sua idade',
        subtitle: 'Você tem {{ageLimit}} anos ou mais?',
        confirmationButton: 'SIM',
        disapprovalButton: 'NÃO',
        warningMessage:
          'Você deve ter {{ageLimit}} anos ou mais para ver este site!',
      },
      contentGate: {
        enterButton: 'ENTRAR',
        continueButton: 'CONTINUAR',
        warningMessage: 'Desculpe, você deve ter pelo menos {{ageLimit}} anos',
        invalidYearInput: 'Por favor, insira um ano válido',
      },
      productDetails: {
        viewWarranty: 'Ver garantia',
        leadInformation: {
          registeredTo: 'Registrado para',
          warranty: 'Garantia',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Toque para pesquisar...',
        dropdownNoResults: 'Sem resultados',
      },
      smsOptIn: {
        receiveMessageButton: 'ENVIE-ME UMA MENSAGEM E COMECE',
        sendMessageButton: 'ESCREVE-NOS E COMECE',
        trySendingAgain: 'Tente novamente',
        sendMeText: 'Me mande uma mensagem de texto',
        sendUsText: 'Nos mande uma mensagem de texto',
        phoneNumberInputPlaceholder: 'Digite o número de telefone...',
        consentRequiredError: 'Por favor, marque a caixa de consentimento',
        checkYourPhone: 'Verifique seu telefone para uma mensagem de texto',
        errorSendingRequest:
          'Erro ao enviar a solicitação. Por favor, tente novamente.',
        messageSentSuccessfully:
          'Por favor, verifique seu telefone para uma mensagem de texto',
        invalidPhoneNumberError:
          'Por favor, insira um número de telefone válido',
        rebateAlreadyClaimedError: 'Este reembolso já foi reivindicado',
        rebateIdError: 'ID de reembolso não encontrado',
        messageSentText:
          'Uma mensagem de texto foi enviada para o número fornecido. Não está vendo? ',
        defaultLegalText: `<p>Ao inserir seu número de telefone ou nos enviar uma mensagem de texto, você concorda em receber mensagens de texto promocionais automatizadas e personalizadas recorrentes (por exemplo, ofertas especiais) da {{brand_name}} no número de celular usado ao se inscrever. O consentimento não é uma condição de compra. Responda AJUDA para obter ajuda e PARE para cancelar. A frequência das mensagens varia. Taxas de mensagens e dados podem ser aplicadas. Consulte os <a href='https://brij.it/terms' target='_self'>Termos e Privacidade</a>.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Por favor marque a caixa de consentimento',
        firstNameInputPlaceholder: 'Primeiro nome',
        lastNameInputPlaceholder: 'Sobrenome',
        phoneNumberInputPlaceholder: 'Número de telefone',
        invalidFirstName: 'Nome é obrigatório',
        invalidLastName: 'Sobrenome é obrigatório',
        invalidPhoneNumber: 'Por favor, insira um número de telefone válido',
        completeProfile: 'Complete o perfil',
        continueButton: 'Continuar',
        skip: 'Vou fazer isso depois',
      },
      sideMenu: {
        myCart: 'Meu carrinho',
        myProfile: 'Meu perfil',
        myCollection: 'Minha coleção',
        visitWebsite: 'Visite o site',
        removeProduct: 'Remover produto',
        productRemoved: ' removido da sua coleção.',
        signOut: 'Sair',
        signIn: 'Entrar',
        signOutToastMessage: 'Logout bem-sucedido',
        translate: 'Traduzir',
        termsAndPrivacy: 'Termos e Privacidade',
        sweepstakesRules: 'Regras do Sorteio',
      },
      profile: {
        pageTitle: 'Perfil',
        pageHeaderTitle: 'Perfil',
        firstNameInput: 'Primeiro nome',
        lastNameInput: 'Sobrenome',
        emailInput: 'E-mail',
        phoneNumberInput: 'Número de telefone',
        saveChanges: 'Salvar alterações',
        updateToastMessage: 'Perfil atualizado',
      },
      collection: {
        pageTitle: 'Minha coleção',
        collectionPageTitle: 'Minha coleção',
        emptyCollectionMessage: 'Nenhum produto em sua coleção',
        scanCodeButton: 'Digitalizar código',
        scanSuccessMessage: 'Produto digitalizado',
        scanErrorMessage: 'Erro de digitalização',
        invalidScanMessage: 'Não é um código Brij válido',
        expireStatus: 'Expirado',
        purchaseStatus: 'Comprado',
        registerStatus: 'Registrado',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Termos e Privacidade',
        pageTitle: 'Termos e Privacidade',
        subtitle: 'Termos e Condições e Política de Privacidade',
        questionsAboutData: 'Perguntas sobre seus dados?',
        contactUs: 'Entre em contato',
        legalNotice: `A Brij Inc. atua como prestadora de serviços para {{brand_name}}, facilitando a coleta e gestão de dados do usuário, incluindo, mas não se limitando a endereços de e-mail e números de telefone. Essa parceria permite que {{brand_name}} utilize os dados coletados por meio da plataforma da Brij Inc. para os fins especificados em suas respectivas políticas de privacidade e termos de serviço. A Brij Inc. está comprometida em lidar com esses dados de forma responsável e em conformidade com todas as leis aplicáveis de proteção de dados, garantindo que a privacidade do usuário seja respeitada e protegida no decorrer de nossa colaboração com {{brand_name}}.`,
        legalNoticeMixed: `Consulte as respectivas políticas de privacidade e termos e condições da Brij Inc. e de {{brand_name}} abaixo para obter informações mais detalhadas.`,
        legalNoticeBrij: `Consulte a política de privacidade e os termos e condições da Brij Inc. abaixo para obter informações mais detalhadas.`,
        termsButton: 'Termos e Condições',
        privacyButton: 'Política de Privacidade',
      },
      gameRules: {
        pageTitle: 'Regras do Jogo',
        pageHeaderTitle: 'Regras do Jogo',
      },
      fourZeroFour: {
        pageTitle: '404 | Não encontrado',
        message: 'Esta experiência não existe',
        learnMoreButton: 'Saiba mais sobre Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Recomendações',
      },
      customSelectInput: {
        noOptions: 'SEM OPÇÕES',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            'Precisa registrar outro produto? Toque aqui',
          callToActionButton: 'Chamada para ação',
          moreButton: 'Mais',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'Adicionar detalhes da compra',
            completeProfileScreen: 'Complete o perfil',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1:
              'Ao se registrar, você concorda com as comunicações por e-mail de ',
            part2: ' e aceita ',
            brijBrand: ' os ',
            linkText: 'Termos e Condições',
            part3: ' Para ajuda ou perguntas, envie um e-mail para ',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'Se você é o proprietário deste produto e está tendo dificuldades para fazer login, entre em contato com ',
          },
          unitTag: {
            heading: 'Bem-vindo de Volta',
            description:
              'Para ver este conteúdo, clique no botão abaixo e enviaremos um link de login para o e-mail registrado.',
          },
          viewDetails: 'Ver detalhes',
          fullTermsLink: 'Ver termos completos',
          emailConsentRequired:
            'Por favor, marque a caixa de consentimento de e-mail',
          privacyConsentRequired:
            'Por favor, marque a caixa de consentimento de privacidade',
          and: 'e',
          sweepstakesRules: 'Regras do Sorteio',
          successMessage: {
            productRegistered: 'Produto registrado!',
            signedUp: 'Você está inscrito',
            warrantyActivated: 'Garantia ativada!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'Adicionar detalhes da compra',
          successDrawer: {
            title: 'Produto registrado!',
            description: 'Verifique seu e-mail para detalhes',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Pesquisar resultados de teste',
          enterLotNumber:
            'Digite o número do lote do seu produto para ver os resultados do teste:',
          enterUpcNumber:
            'Introduza o número UPC de 12 dígitos do seu produto para visualizar os resultados dos testes do seu produto:',
          enterUpcAndLotNumber:
            'Introduza o UPC de 12 dígitos e o número de lote do seu produto para visualizar os resultados dos testes do seu produto:',
          lotNumberEmptyInputPlaceholder: 'Digite o número do lote...',
          lotNumberInputPlaceholder: 'Número do lote...',
          optionalPlaceholderText: '(opcional)',
          upcNumberEmptyInputPlaceholder: 'Digite o UPC...',
          upcNumberInputPlaceholder: 'UPC...',
          submitLotNumberButton: 'Ver resultados do teste',
          lotNumberHeading: 'Número do lote',
          testDateHeading: 'Data do teste',
          expirationDateHeading: 'Data de validade',
          viewFullReportButton: 'Ver todos os resultados do teste',
          startOverButton: 'Recomeçar',
          heavyMetalsHeading: 'Metais pesados',
          pesticidesAndGlyphosateHeading: 'Pesticidas e Glifosato',
          plasticizersHeading: 'Plastificantes (BPA, BPS)',
          standardHeading: 'Padrão',
          disclaimer:
            'Os testes foram realizados de acordo com os padrões AB 899 da Califórnia. Para obter as informações mais recentes da FDA e recomendações de saúde sobre os efeitos dos elementos tóxicos em crianças, consulte ',
          disclaimerLink: 'este link',
          errors: {
            emptyLotOrUpcNumber: 'Digite um número de lote para continuar',
            noRecordFound:
              'Dados de teste não encontrados. Verifique se o UPC ou o número do lote foi digitado corretamente.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Data de Entrada',
          entryIdHeading: 'ID de Entrada',
          sweepstakesEntryStatus: {
            entryApproved: 'Entrada Aprovada',
            entryPending: 'Entrada Pendente',
            entryDenied: 'Entrada Negada',
            entryExpired: 'Entrada Expirada',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage:
              'Você foi inscrito com sucesso neste sorteio. Nós',
            entryPendingReviewMessage:
              'Sua inscrição no sorteio está atualmente em revisão. Verifique novamente mais tarde para ver o status.',
            entryDeniedMessage:
              'Sua inscrição foi negada. Certifique-se de que sua inscrição atende a todos os requisitos para este sorteio.',
            sweepstakesExpiredMessage:
              'Este sorteio expirou. Obrigado por participar.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Garantia ativa',
          warrantyHeadingWithMulberry: 'Garantia estendida pela Mulberry',
          expiredWarrantyHeading: 'Garantia expirada',
          pendingWarrantyHeading: 'Garantia aguardando revisão',
          deniedWarrantyHeading: 'Garantia negada',
          mulberryDefaultHeader: 'Fique coberto por mais tempo',
          mulberryDefaultDescription:
            'Por tempo limitado, atualize sua proteção com as taxas mais baixas garantidas.',
          mulberryDefaultButtonCta: 'Estender minha cobertura de garantia',
          mulberryExpirationNotice:
            'As extensões de garantia são gerenciadas pela Mulberry Technology Inc. e podem ser adquiridas até a data da compra',
          mulberryPolicyTerms: 'Ver termos de seguro',
          mulberryHelpText: {
            part1: 'Os planos de extensão de garantia são geridos por',
            part2:
              'Contacte help@getmulberry.com com perguntas sobre a sua extensão de garantia.',
          },
          lifetimeLabel: 'Vitalício',
          pendingInfoText:
            'Seu registro de garantia está atualmente em revisão. Volte mais tarde para ver seu status.',
          deniedInfoText:
            'Seu registro de garantia foi negado. Por favor, envie um recibo datado válido abaixo e certifique-se de que a data da compra corresponda à data no recibo.',
          issueDate: 'Data de início',
          expirationDate: 'Data de expiração',
          termLength: 'Duração do termo',
          fullTermsLink: 'Ver termos completos',
          viewDetails: 'Ver detalhes',
          detailsModal: {
            activateWarrantyHeading: 'Ativar garantia',
            updateWarrantyHeading: 'Atualizar detalhes',
            description:
              'Para ativar sua garantia, insira a data da compra e faça o upload de um recibo datado.',
            updateDescription:
              'Para atualizar seus detalhes de compra, insira a nova data da compra e faça o upload de um recibo datado.',
            activateButton: 'Ativar',
            updateButton: 'Atualizar',
            cancelButton: 'Cancelar',
          },
          successDrawer: {
            title: 'Garantia ativada!',
            description: 'Verifique seu e-mail para detalhes',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Copiar link',
          copyLinkToastMessage: 'Copiado para a área de transferência',
          shareLinkButton: 'Compartilhar link',
          shareText: 'Dê uma olhada neste produto da Brij',
          helpText: 'Peça ao seu amigo para digitalizar este código',
        },
        smsDrawer: {
          signUpButton: 'Inscreva-se para receber mensagens VIP',
          subscriptionDisclaimer:
            'Ao se inscrever via mensagem de texto, você concorda em receber mensagens de texto promocionais automatizadas e personalizadas recorrentes da Canopy no número de celular usado ao se inscrever. O consentimento não é uma condição de compra. Responda AJUDA para obter ajuda e PARE para cancelar. A frequência das mensagens varia. Taxas de mensagens e dados podem ser aplicadas. Deve ser residente nos EUA e ter 18 anos ou mais. Veja os Termos e Privacidade.',
        },
        shopDrawer: {
          successDrawer: {
            title: 'Pedido feito!',
            description: 'Verifique seu e-mail para uma confirmação do pedido',
          },
          productDetails: {
            showMore: 'Mais',
            showLess: 'Menos',
          },
          savingBanner: {
            title: 'Você está economizando',
          },
          cartButton: {
            callToAction: 'Adicionar ao carrinho',
            invalidCombo: 'Indisponível',
            invalidQuantity: 'Selecione a quantidade',
          },
          checkoutHint: {
            chooseOptions: 'Por favor, escolha as opções',
            comboUnavailable: 'Combinação não disponível',
          },
          recommendations: {
            title: 'Recomendações',
            seeMore: 'Ver mais',
          },
          cartResetNotification: 'Um novo carrinho foi criado para ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Finalizar compra',
          },
          drawerTitle: 'Meu carrinho',
          emptyCartText: 'Atualmente não há itens em seu carrinho',
        },
        discountDrawer: {
          discountCodeHeading: 'Código de desconto:',
          copiedToClipboard:
            'Código de desconto copiado para a área de transferência',
        },
        formDrawer: {
          submit: 'Enviar',
          next: 'Próximo',
          start: 'Começar',
          regFormFetchError:
            'Falha ao buscar o formulário de registro. Por favor, atualize e tente novamente.',
          formCompletetionMessage: 'Suas respostas foram enviadas com sucesso.',
          formSubmissionError:
            'Falha no envio do formulário. Por favor, envie novamente',
          submissionSuccessful: 'Envio Bem-Sucedido',
          submitAnotherResponse: 'Enviar Outra Resposta',
          fileUpload: {
            fileName: 'Nome do arquivo...',
            uploadFileButton: 'Enviar arquivos',
            syncToS3Error:
              'Falha ao sincronizar o arquivo com o s3. Por favor, envie novamente.',
            fileSizeError:
              'O tamanho do arquivo excede 20MB. Por favor, envie novamente.',
            fileLimitError:
              'Limite de 5 arquivos excedido. Por favor, envie novamente.',
            fileExtensionError: `Extensão de arquivo não suportada. Arquivos válidos são .pdf, .jpeg,
              .jpg, heic e .png`,
          },
          textArea: {
            placeholder: 'Digite sua resposta aqui...',
            placeholderFilled: 'Sua resposta...',
          },
          dropDown: {
            selectText: 'Clique para selecionar',
          },
          datePicker: {
            placeholder: 'Selecione uma data',
            errorMessage: 'Data inválida',
          },
          addressFields: {
            countryPlaceholder: 'Selecione o país',
            addressLineOnePlaceholder: 'Endereço linha 1',
            addressLineTwoPlaceholder: 'Endereço linha 2',
            cityPlaceholder: 'Cidade',
            stateDropdownPlaceholder: 'Selecione o estado',
            stateFieldPlaceholder: 'Estado',
            zipcodePlaceholder: 'CEP',
          },
          firstNamePlaceholder: 'Primeiro Nome',
          lastNamePlaceholder: 'Sobrenome',
        },
      },
    },
  },
  ja: {
    // Japanese
    translation: {
      rotationScreen: {
        message: 'デバイスを縦向きに回転して体験を表示してください',
      },
      registration: {
        productRegistered: '製品が登録されました！',
        signedUp: 'サインアップしました！',
        warrantyActivated: '保証が有効になりました！',
        completeProfile: 'プロフィールを完成させる',
        registrationError:
          '登録中にエラーが発生しました。もう一度お試しください',
      },
      socialLogin: {
        withGoogleText: 'Googleで',
        continueGoogleButton: 'Googleで続行',
        signinError: 'エラーが発生しました。もう一度サインインしてください',
        popupBlockedMessage:
          'がブラウザによってブロックされました。ポップアップブロッカーを無効にするか、続行するにはメールオプションを選択してください。',
        cookiesDisabled:
          '続行するにはCookieを有効にしてください、またはメールで登録してみてください。',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'レシートがアップロードされました。',
        receiptUploadError: 'レシートのアップロード中にエラーが発生しました',
        noReceiptError: '購入レシートをアップロードしてください',
        firstnameInputPlaceholder: '名を入力してください...',
        firstnameFilledInputPlaceholder: '名',
        lastnameInputPlaceholder: '姓を入力してください...',
        lastnameFilledInputPlaceholder: '姓',
        phoneNumberInputPlaceholder: '電話番号を入力してください...',
        serialNumberInputPlaceholder: 'シリアル番号を入力してください...',
        serialNumberFilledInputPlaceholder: 'シリアル番号',
        uploadReceiptButtonText: 'レシートをアップロード',
        datepickerLabel: '購入日...',
        datepickerFilledLabel: '購入日',
        smsConsentRequired: '同意ボックスにチェックしてください',
        invalidType:
          '無効なファイルタイプです。画像またはPDFをアップロードしてください',
        invalidDate: '無効な日付',
        retailChannelInputPlaceholder: '購入場所を入力してください...',
        quantitySelectorInputPlaceholder: '数量を選択してください',
        quantitySelectorInputOther: 'その他',
        failedToUpload: 'アップロードに失敗しました',
        submitButtonText: '送信',
        receiptFailedError:
          '1つ以上のレシートが要件を満たしていない可能性があります。レシートの明確で十分に照らされた画像を再アップロードしてください。',
      },
      signIn: {
        pageTitle: 'Brijにサインイン',
        pageHeaderTitle: 'サインイン',
        withEmail: ' メールで',
        completeProfile: 'プロフィールを完成させる',
        useDifferentOption: '別のオプションを使用する',
        continueWithEmail: 'メールで続行',
        unitTagSignInLink: 'サインインリンクをリクエスト',
        checkYourEmail: 'メールを確認してください',
        emailInputPlaceholder: 'メールアドレスを入力してください...',
        emailInputFilledPlaceholder: 'メールアドレス',
        toastMessages: {
          invalidEmail: '有効なメールアドレスを入力してください',
          completeRecaptchaVerification: 'Recaptchaの確認を完了してください',
          cantVerifyLoginDetails: 'ログインの詳細を確認できません',
          emailConsentRequired: 'メール同意ボックスにチェックしてください',
          privacyConsentRequired:
            'プライバシー同意ボックスにチェックしてください',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': '有効なメールアドレスを入力してください',
        'auth/missing-email': 'メールアドレスを入力してください',
        'auth/user-not-found': 'ユーザーが存在しません',
        'auth/wrong-password': '無効なメールアドレスまたはパスワード',
        'auth/internal-error': 'サーバーエラーが発生しました',
        'auth/popup-closed-by-user':
          'サインインがキャンセルされました。サインインプロセスを完了するか、続行するにはメールオプションを選択してください',
        'auth/cancelled-popup-request':
          'サインインがキャンセルされました。サインインプロセスを完了するか、続行するにはメールオプションを選択してください',
        'auth/invalid-credential-or-provider-id': '無効な資格情報',
        'auth/unauthorized-domain':
          '安全でないドメインからウェブサイトにアクセスしています。',
        'auth/invalid-action-code': 'コードが無効または期限切れです',
        'auth/popup-blocked':
          'ポップアップがブロックされました。ポップアップを有効にするか、メールオプションを選択して続行してください。',
      },
      magicLink: {
        linkSentToastMessage:
          'おかえりなさい！サインインリンクをメールで確認してください。',
        magicLinkError: 'エラーが発生しました',
        redirecting: 'リダイレクトしています...',
      },
      ageGate: {
        title: '年齢を確認してください',
        subtitle: '{{ageLimit}} 歳以上ですか？',
        confirmationButton: 'はい',
        disapprovalButton: 'いいえ',
        warningMessage:
          'このサイトを表示するには {{ageLimit}} 歳以上である必要があります！',
      },
      contentGate: {
        enterButton: '入力',
        continueButton: '続行',
        warningMessage: '{{ageLimit}}歳以上である必要があります',
        invalidYearInput: '有効な年を入力してください',
      },
      productDetails: {
        viewWarranty: '保証を表示',
        leadInformation: {
          registeredTo: '登録先',
          warranty: '保証',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'タップして検索...',
        dropdownNoResults: '結果がありません',
      },
      smsOptIn: {
        receiveMessageButton: 'テキストメッセージを送って始めましょう',
        sendMessageButton: 'テキストメッセージを送って始めましょう',
        trySendingAgain: '再試行',
        sendMeText: '代わりにテキストを送信',
        sendUsText: '代わりにテキストを送信',
        phoneNumberInputPlaceholder: '電話番号を入力してください...',
        consentRequiredError: '同意ボックスにチェックしてください',
        checkYourPhone: 'テキストメッセージを確認してください',
        errorSendingRequest:
          'リクエストの送信中にエラーが発生しました。もう一度お試しください。',
        messageSentSuccessfully: 'テキストメッセージを確認してください',
        invalidPhoneNumberError: '有効な電話番号を入力してください',
        rebateAlreadyClaimedError: 'このリベートはすでに請求されています',
        rebateIdError: 'リベートIDが見つかりません',
        messageSentText:
          '提供された番号にテキストメッセージが送信されました。見つけられませんか？ ',
        defaultLegalText: `<p>電話番号を入力するか、テキストメッセージを送信することで、{{brand_name}}からの繰り返しの自動化されたプロモーションおよびパーソナライズされたマーケティングテキストメッセージ（特別オファーなど）を携帯電話番号で受け取ることに同意しました。同意は購入の条件ではありません。ヘルプを求める場合はHELPと返信し、キャンセルする場合はSTOPと返信してください。メッセージの頻度は異なります。メッセージおよびデータ料金がかかる場合があります。 <a href='https://brij.it/terms' target='_self'>利用規約とプライバシー</a>を表示。</p>`,
      },
      personalDetails: {
        smsConsentRequired: '同意ボックスにチェックしてください',
        firstNameInputPlaceholder: '名',
        lastNameInputPlaceholder: '姓',
        phoneNumberInputPlaceholder: '電話番号',
        invalidFirstName: '名が必要です',
        invalidLastName: '姓が必要です',
        invalidPhoneNumber: '有効な電話番号を入力してください',
        completeProfile: 'プロフィールを完成させる',
        continueButton: '続行',
        skip: '後で行う',
      },
      sideMenu: {
        myCart: 'マイカート',
        myProfile: 'マイプロフィール',
        myCollection: 'マイコレクション',
        visitWebsite: 'ウェブサイトを訪問',
        removeProduct: '製品を削除',
        productRemoved: ' コレクションから削除されました。',
        signOut: 'サインアウト',
        signIn: 'サインイン',
        signOutToastMessage: 'ログアウトしました',
        translate: '翻訳',
        termsAndPrivacy: '利用規約とプライバシー',
        sweepstakesRules: '抽選ルール',
      },
      profile: {
        pageTitle: 'プロフィール',
        pageHeaderTitle: 'プロフィール',
        firstNameInput: '名',
        lastNameInput: '姓',
        emailInput: 'メール',
        phoneNumberInput: '電話番号',
        saveChanges: '変更を保存',
        updateToastMessage: 'プロフィールが更新されました',
      },
      collection: {
        pageTitle: 'マイコレクション',
        collectionPageTitle: 'マイコレクション',
        emptyCollectionMessage: 'コレクションに製品がありません',
        scanCodeButton: 'コードをスキャン',
        scanSuccessMessage: '製品がスキャンされました',
        scanErrorMessage: 'スキャンエラー',
        invalidScanMessage: '有効なBrijコードではありません',
        expireStatus: '期限切れ',
        purchaseStatus: '購入済み',
        registerStatus: '登録済み',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: '利用規約とプライバシー',
        pageTitle: '利用規約とプライバシー',
        subtitle: '利用規約とプライバシーポリシー',
        questionsAboutData: 'データに関する質問はありますか？',
        contactUs: 'お問い合わせ',
        legalNotice: `Brij Inc.は{{brand_name}}のデータ（電子メールアドレスや電話番号など）の収集と管理を支援するサービスプロバイダーとして機能し、{{brand_name}}がBrij Inc.のプラットフォームを介して収集したデータを、それぞれのプライバシーポリシーや利用規約で指定された目的のために利用することを可能にします。Brij Inc.は、このデータを責任を持って取り扱い、すべての適用されるデータ保護法に準拠して、{{brand_name}}との協力関係の過程でユーザーのプライバシーが尊重され、保護されるよう努めています。`,
        legalNoticeMixed: `詳細については、以下のBrij Inc.と{{brand_name}}のそれぞれのプライバシーポリシーと利用規約をご覧ください。`,
        legalNoticeBrij: `詳細については、Brij Inc.のプライバシーポリシーと利用規約をご覧ください。`,
        termsButton: '利用規約',
        privacyButton: 'プライバシーポリシー',
      },
      gameRules: {
        pageTitle: 'ゲームルール',
        pageHeaderTitle: 'ゲームルール',
      },
      fourZeroFour: {
        pageTitle: '404 | 未検出',
        message: 'この体験は存在しません',
        learnMoreButton: 'Brijについて詳しく',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'おすすめ',
      },
      customSelectInput: {
        noOptions: 'オプションなし',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            '他の製品を登録する必要がありますか？ここをタップ',
          callToActionButton: 'コールトゥアクション',
          moreButton: 'もっと',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: '購入詳細を追加',
            completeProfileScreen: 'プロフィールを完成させる',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1: '登録することで、',
            part2: 'からのメール通知に同意し、',
            brijBrand: ' Brijの',
            linkText: '利用規約',
            part3: 'に同意します。ヘルプや質問がある場合は、',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'この製品の所有者でサインインに問題がある場合は、お問い合わせください。',
          },
          unitTag: {
            heading: 'おかえりなさい',
            description:
              'このコンテンツを表示するには、下のボタンをクリックして登録されたメールアドレスにログインリンクを送信します。',
          },
          viewDetails: '詳細を表示',
          fullTermsLink: '全文を表示',
          emailConsentRequired: 'メール同意ボックスにチェックしてください',
          privacyConsentRequired:
            'プライバシー同意ボックスにチェックしてください',
          and: 'および',
          sweepstakesRules: '抽選ルール',
          successMessage: {
            productRegistered: '製品が登録されました！',
            signedUp: 'サインアップしました！',
            warrantyActivated: '保証が有効になりました！',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: '購入詳細を追加',
          successDrawer: {
            title: '製品が登録されました！',
            description: '詳細はメールを確認してください',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'テスト結果を検索',
          enterLotNumber:
            '製品のテスト結果を表示するには、製品のロット番号を入力してください：',
          enterUpcNumber:
            '製品の 12 桁の UPC 番号を入力して、製品のテスト結果を表示します。',
          enterUpcAndLotNumber:
            '製品の 12 桁の UPC とロット番号を入力して、製品のテスト結果を表示します。',
          lotNumberEmptyInputPlaceholder: 'ロット番号を入力してください...',
          lotNumberInputPlaceholder: 'ロット番号...',
          optionalPlaceholderText: '(オプション)',
          upcNumberEmptyInputPlaceholder: 'UPCを入力してください...',
          upcNumberInputPlaceholder: 'UPC...',
          submitLotNumberButton: 'テスト結果を表示',
          lotNumberHeading: 'ロット番号',
          testDateHeading: 'テスト日',
          expirationDateHeading: '有効期限',
          viewFullReportButton: 'すべてのテスト結果を表示',
          startOverButton: '最初から',
          heavyMetalsHeading: '重金属',
          pesticidesAndGlyphosateHeading: '農薬とグリホサート',
          plasticizersHeading: 'プラスチサイザー（BPA、BPS）',
          standardHeading: '標準',
          disclaimer:
            'テストはAB 899カリフォルニア基準に従って実施されました。最新のFDA情報と子供に対する有害物質の影響に関する健康上の推奨事項については、',
          disclaimerLink: 'このリンク',
          errors: {
            emptyLotOrUpcNumber: '続行するにはロット番号を入力してください',
            noRecordFound:
              'テストデータが見つかりませんでした。UPCまたはロット番号が正しく入力されているか確認してください。',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'エントリー日',
          entryIdHeading: 'エントリーID',
          sweepstakesEntryStatus: {
            entryApproved: 'エントリー承認済み',
            entryPending: 'エントリー保留中',
            entryDenied: 'エントリー拒否',
            entryExpired: 'エントリー期限切れ',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage: 'この抽選に正常に登録されました。',
            entryPendingReviewMessage:
              '抽選への登録は現在審査中です。後でステータスを確認してください。',
            entryDeniedMessage:
              '抽選への登録が拒否されました。この抽選の要件を満たしていることを確認してください。',
            sweepstakesExpiredMessage:
              'この抽選は終了しました。ご参加いただきありがとうございます。',
          },
        },
        warrantyDrawer: {
          warrantyHeading: '保証が有効',
          warrantyHeadingWithMulberry: 'Mulberryによる保証延長',
          expiredWarrantyHeading: '保証が切れました',
          pendingWarrantyHeading: '保証の審査中',
          deniedWarrantyHeading: '保証が拒否されました',
          mulberryDefaultHeader: 'より長くカバーを維持',
          mulberryDefaultDescription:
            '期間限定でアップグレードすると、保証された最低料金で保護を延長できます。',
          mulberryDefaultButtonCta: '保証範囲を延長する',
          mulberryExpirationNotice:
            '保証延長はMulberry Technology Inc.によって管理され、購入日まで購入できます',
          mulberryPolicyTerms: '保険条件を表示',
          mulberryHelpText: {
            part1: '保証延長プランは',
            part2:
              '延長保証に関するご質問は、help@getmulberry.com までお問い合わせください。',
          },
          lifetimeLabel: '生涯',
          pendingInfoText:
            '保証登録は現在審査中です。後でステータスを確認してください。',
          deniedInfoText:
            '保証登録が拒否されました。下記の日付を入力し、購入日がレシートの日付と一致することを確認してください。',
          issueDate: '開始日',
          expirationDate: '有効期限',
          termLength: '期間',
          fullTermsLink: '全文を表示',
          viewDetails: '詳細を表示',
          detailsModal: {
            activateWarrantyHeading: '保証を有効にする',
            updateWarrantyHeading: '詳細を更新',
            description:
              '保証を有効にするには、購入日を入力し、日付付きのレシートをアップロードしてください。',
            updateDescription:
              '購入詳細を更新するには、新しい購入日を入力し、日付付きのレシートをアップロードしてください。',
            activateButton: '有効にする',
            updateButton: '更新',
            cancelButton: 'キャンセル',
          },
          successDrawer: {
            title: '保証が有効になりました！',
            description: '詳細はメールを確認してください',
          },
        },
        referralDrawer: {
          copyLinkButton: 'リンクをコピー',
          copyLinkToastMessage: 'クリップボードにコピーしました',
          shareLinkButton: 'リンクを共有',
          shareText: 'Brijからこの製品をご覧ください',
          helpText: '友達にこのコードをスキャンしてもらってください',
        },
        smsDrawer: {
          signUpButton: 'VIPテキストにサインアップ',
          subscriptionDisclaimer:
            'テキストでサインアップすることで、Canopyから携帯電話番号で繰り返し自動化されたパーソナライズされたマーケティングテキストメッセージ（特別オファーなど）を受け取ることに同意します。同意は購入の条件ではありません。ヘルプを求める場合はHELPと返信し、キャンセルする場合はSTOPと返信してください。メッセージの頻度は異なります。メッセージおよびデータ料金がかかる場合があります。米国の居住者で、18歳以上である必要があります。利用規約とプライバシーをご覧ください。',
        },
        shopDrawer: {
          successDrawer: {
            title: '注文が完了しました！',
            description: '注文確認のメールを確認してください',
          },
          productDetails: {
            showMore: 'もっと',
            showLess: '少なく',
          },
          savingBanner: {
            title: '節約中',
          },
          cartButton: {
            callToAction: 'カートに追加',
            invalidCombo: '在庫切れ',
            invalidQuantity: '数量を選択してください',
          },
          checkoutHint: {
            chooseOptions: 'オプションを選択してください',
            comboUnavailable: '組み合わせが利用できません',
          },
          recommendations: {
            title: 'おすすめ',
            seeMore: 'もっと見る',
          },
          cartResetNotification: '新しいカートが作成されました',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'チェックアウト',
          },
          drawerTitle: 'マイカート',
          emptyCartText: '現在、カートにアイテムがありません',
        },
        discountDrawer: {
          discountCodeHeading: '割引コード：',
          copiedToClipboard: '割引コードがクリップボードにコピーされました',
        },
        formDrawer: {
          submit: '提出',
          next: '次へ',
          start: 'スタート',
          regFormFetchError:
            '登録フォームの取得に失敗しました。更新してもう一度お試しください。',
          formCompletetionMessage: '回答が正常に送信されました。',
          formSubmissionError:
            'フォームの送信に失敗しました。再送信してください',
          submissionSuccessful: '提出が成功しました',
          submitAnotherResponse: '別の回答を送信',
          fileUpload: {
            fileName: 'ファイル名...',
            uploadFileButton: 'ファイルをアップロード',
            syncToS3Error:
              'ファイルがS3に同期できませんでした。もう一度アップロードしてください。',
            fileSizeError:
              'ファイルサイズが20MBを超えています。再送信してください。',
            fileLimitError: '5ファイルの制限を超えました。再送信してください。',
            fileExtensionError: `ファイル拡張子がサポートされていません。有効なファイルは.pdf、.jpeg、.jpg、heic、.pngです`,
          },
          textArea: {
            placeholder: 'ここに回答を入力してください...',
            placeholderFilled: '回答...',
          },
          dropDown: {
            selectText: 'クリックして選択',
          },
          datePicker: {
            placeholder: '日付を選択',
            errorMessage: '無効な日付',
          },
          addressFields: {
            countryPlaceholder: '国を選択',
            addressLineOnePlaceholder: '住所ライン1',
            addressLineTwoPlaceholder: '住所ライン2',
            cityPlaceholder: '市',
            stateDropdownPlaceholder: '州を選択',
            stateFieldPlaceholder: '州',
            zipcodePlaceholder: '郵便番号',
          },
          firstNamePlaceholder: '名',
          lastNamePlaceholder: '苗字',
        },
      },
    },
  },
  ru: {
    // Russian
    translation: {
      rotationScreen: {
        message:
          'Пожалуйста, поверните устройство в портретный режим для просмотра опыта',
      },
      registration: {
        productRegistered: 'Продукт зарегистрирован!',
        signedUp: 'Вы зарегистрированы!',
        warrantyActivated: 'Гарантия активирована!',
        completeProfile: 'Заполните профиль',
        registrationError:
          'Произошла ошибка во время регистрации. Пожалуйста, попробуйте еще раз',
      },
      socialLogin: {
        withGoogleText: 'с Google',
        continueGoogleButton: 'Продолжить с Google',
        signinError: 'Произошла ошибка. Пожалуйста, попробуйте войти снова',
        popupBlockedMessage:
          'был заблокирован вашим браузером. Пожалуйста, отключите блокировщики всплывающих окон или выберите вариант электронной почты, чтобы продолжить.',
        cookiesDisabled:
          'Пожалуйста, включите файлы cookie, чтобы продолжить, или попробуйте зарегистрироваться с помощью электронной почты.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'Чек загружен.',
        receiptUploadError: 'Ошибка загрузки чека',
        noReceiptError: 'Пожалуйста, загрузите чек покупки',
        firstnameInputPlaceholder: 'Введите имя...',
        firstnameFilledInputPlaceholder: 'Имя',
        lastnameInputPlaceholder: 'Введите фамилию...',
        lastnameFilledInputPlaceholder: 'Фамилия',
        phoneNumberInputPlaceholder: 'Введите номер телефона...',
        serialNumberInputPlaceholder: 'Введите серийный номер...',
        serialNumberFilledInputPlaceholder: 'Серийный номер',
        uploadReceiptButtonText: 'Загрузить чек',
        datepickerLabel: 'Дата покупки...',
        datepickerFilledLabel: 'Дата покупки',
        smsConsentRequired: 'Пожалуйста, установите флажок согласия',
        invalidType:
          'Недопустимый тип файла. Пожалуйста, загрузите изображение или PDF',
        invalidDate: 'Неверная дата',
        retailChannelInputPlaceholder: 'Введите место покупки...',
        quantitySelectorInputPlaceholder: 'Выберите количество',
        quantitySelectorInputOther: 'Другое',
        failedToUpload: 'Не удалось загрузить',
        submitButtonText: 'Отправить',
        receiptFailedError:
          'Один или несколько чеков могут не соответствовать требованиям. Пожалуйста, загрузите четкое и хорошо освещенное изображение вашего чека.',
      },
      signIn: {
        pageTitle: 'Войти в Brij',
        pageHeaderTitle: 'Войти',
        withEmail: ' с Email',
        completeProfile: 'Заполните профиль',
        useDifferentOption: 'Использовать другой вариант',
        continueWithEmail: 'Продолжить с Email',
        unitTagSignInLink: 'Запросить ссылку для входа',
        checkYourEmail: 'Проверьте свою почту',
        emailInputPlaceholder: 'Введите email...',
        emailInputFilledPlaceholder: 'Email',
        toastMessages: {
          invalidEmail:
            'Пожалуйста, введите действительный адрес электронной почты',
          completeRecaptchaVerification:
            'Пожалуйста, выполните проверку Recaptcha',
          cantVerifyLoginDetails: 'Не удается проверить данные для входа',
          emailConsentRequired:
            'Пожалуйста, установите флажок согласия на электронную почту',
          privacyConsentRequired:
            'Пожалуйста, установите флажок согласия на конфиденциальность',
        },
      },
      firebaseErrors: {
        'auth/invalid-email':
          'Пожалуйста, укажите действительный адрес электронной почты',
        'auth/missing-email': 'Пожалуйста, укажите адрес электронной почты',
        'auth/user-not-found': 'Пользователь не существует',
        'auth/wrong-password': 'Неверный адрес электронной почты или пароль',
        'auth/internal-error': 'Произошла ошибка сервера',
        'auth/popup-closed-by-user':
          'Вход отменен. Пожалуйста, завершите процесс входа или выберите вариант электронной почты для продолжения',
        'auth/cancelled-popup-request':
          'Вход отменен. Пожалуйста, завершите процесс входа или выберите вариант электронной почты для продолжения',
        'auth/invalid-credential-or-provider-id': 'Недопустимые учетные данные',
        'auth/unauthorized-domain':
          'Вы заходите на сайт с небезопасного домена.',
        'auth/invalid-action-code': 'Код недействителен или истек',
        'auth/popup-blocked':
          'Всплывающее окно заблокировано. Пожалуйста, разрешите всплывающие окна или выберите вариант электронной почты для продолжения.',
      },
      magicLink: {
        linkSentToastMessage:
          'Добро пожаловать! Проверьте свою почту для получения ссылки для входа.',
        magicLinkError: 'Произошла ошибка',
        redirecting: 'Перенаправление...',
      },
      ageGate: {
        title: 'Подтвердите свой возраст',
        subtitle: 'Вам {{ageLimit}} год или старше?',
        confirmationButton: 'ДА',
        disapprovalButton: 'НЕТ',
        warningMessage:
          'Вам должно быть {{ageLimit}} год или старше для просмотра этого сайта!',
      },
      contentGate: {
        enterButton: 'Ввод',
        continueButton: 'Продолжить',
        warningMessage: 'Вам должно быть {{ageLimit}} лет и старше',
        invalidYearInput: 'Пожалуйста, введите действительный год',
      },
      productDetails: {
        viewWarranty: 'Посмотреть гарантию',
        leadInformation: {
          registeredTo: 'Зарегистрировано на',
          warranty: 'Гарантия',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'Нажмите для поиска...',
        dropdownNoResults: 'Нет результатов',
      },
      smsOptIn: {
        receiveMessageButton: 'НАПИШИТЕ МНЕ И НАЧНИТЕ',
        sendMessageButton: 'НАПИШИТЕ НАМ И НАЧНИТЕ НАЧАТЬ',
        trySendingAgain: 'Попробуйте снова',
        sendMeText: 'Отправьте мне текст вместо этого',
        sendUsText: 'Отправьте нам текст вместо этого',
        phoneNumberInputPlaceholder: 'Введите номер телефона...',
        consentRequiredError: 'Пожалуйста, установите флажок согласия',
        checkYourPhone:
          'Проверьте свой телефон на наличие текстового сообщения',
        errorSendingRequest:
          'Ошибка отправки запроса. Пожалуйста, попробуйте снова.',
        messageSentSuccessfully:
          'Пожалуйста, проверьте свой телефон на наличие текстового сообщения',
        invalidPhoneNumberError:
          'Пожалуйста, введите действительный номер телефона',
        rebateAlreadyClaimedError: 'Этот возмещение уже получено',
        rebateIdError: 'Идентификатор возмещения не найден',
        messageSentText:
          'Сообщение было отправлено на указанный номер телефона. Не видите его? ',
        defaultLegalText: `<p>Введя свой номер телефона или отправив нам текстовое сообщение, вы соглашаетесь на получение повторяющихся автоматизированных рекламных и персонализированных маркетинговых текстовых сообщений (например, специальные предложения) от {{brand_name}} на номер телефона, указанный при регистрации. Согласие не является обязательным условием для совершения покупки. Ответьте HELP для получения справки и STOP для отмены. Частота сообщений может варьироваться. Стоимость сообщений и данных может применяться. Просмотреть <a href='https://brij.it/terms' target='_self'>Условия и конфиденциальность</a>.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'Пожалуйста, установите флажок согласия',
        firstNameInputPlaceholder: 'Имя',
        lastNameInputPlaceholder: 'Фамилия',
        phoneNumberInputPlaceholder: 'Номер телефона',
        invalidFirstName: 'Имя обязательно',
        invalidLastName: 'Фамилия обязательна',
        invalidPhoneNumber: 'Пожалуйста, введите действительный номер телефона',
        completeProfile: 'Заполните профиль',
        continueButton: 'Продолжить',
        skip: `Я сделаю это позже`,
      },
      sideMenu: {
        myCart: 'Моя корзина',
        myProfile: 'Мой профиль',
        myCollection: 'Моя коллекция',
        visitWebsite: 'Посетить сайт',
        removeProduct: 'Удалить продукт',
        productRemoved: ' удален из коллекции.',
        signOut: 'Выйти',
        signIn: 'Войти',
        signOutToastMessage: 'Выход выполнен успешно',
        translate: 'Перевести',
        termsAndPrivacy: 'Условия и конфиденциальность',
        sweepstakesRules: 'Правила розыгрыша',
      },
      profile: {
        pageTitle: 'Профиль',
        pageHeaderTitle: 'Профиль',
        firstNameInput: 'Имя',
        lastNameInput: 'Фамилия',
        emailInput: 'Email',
        phoneNumberInput: 'Номер телефона',
        saveChanges: 'Сохранить изменения',
        updateToastMessage: 'Профиль обновлен',
      },
      collection: {
        pageTitle: 'Моя коллекция',
        collectionPageTitle: 'Моя коллекция',
        emptyCollectionMessage: 'Нет продуктов в вашей коллекции',
        scanCodeButton: 'Сканировать код',
        scanSuccessMessage: 'Продукт отсканирован',
        scanErrorMessage: 'Ошибка сканирования',
        invalidScanMessage: 'Недействительный код Brij',
        expireStatus: 'Истекает',
        purchaseStatus: 'Куплено',
        registerStatus: 'Зарегистрировано',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'Условия и конфиденциальность',
        pageTitle: 'Условия и конфиденциальность',
        subtitle: 'Условия и политика конфиденциальности',
        questionsAboutData: 'Вопросы о ваших данных?',
        contactUs: 'Свяжитесь с нами',
        legalNotice: `Brij Inc. действует в качестве поставщика услуг для {{brand_name}}, облегчая сбор и управление данными пользователей, включая, но не ограничиваясь, адресами электронной почты и номерами телефонов. Это партнерство позволяет {{brand_name}} использовать данные, собранные через платформу Brij Inc., для целей, указанных в их соответствующих политиках конфиденциальности и условиях обслуживания. Brij Inc. обязуется обрабатывать эти данные ответственно и в соответствии со всеми применимыми законами о защите данных, обеспечивая, что конфиденциальность пользователей уважается и защищается в ходе нашего сотрудничества с {{brand_name}}.`,
        legalNoticeMixed: `Пожалуйста, ознакомьтесь с соответствующими политиками конфиденциальности и условиями обслуживания как Brij Inc., так и {{brand_name}} ниже для получения более подробной информации.`,
        legalNoticeBrij: `Пожалуйста, ознакомьтесь с политикой конфиденциальности и условиями обслуживания Brij Inc. ниже для получения более подробной информации.`,
        termsButton: 'Условия',
        privacyButton: 'Политика конфиденциальности',
      },
      gameRules: {
        pageTitle: 'Правила игры',
        pageHeaderTitle: 'Правила игры',
      },
      fourZeroFour: {
        pageTitle: '404 | Не найдено',
        message: 'Этот опыт не существует',
        learnMoreButton: 'Узнать о Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'Рекомендации',
      },
      customSelectInput: {
        noOptions: 'Нет опций',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText:
            'Нужно зарегистрировать еще один продукт? Нажмите здесь',
          callToActionButton: 'Призыв к действию',
          moreButton: 'Больше',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'Добавить данные о покупке',
            completeProfileScreen: 'Заполнить профиль',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1:
              'Регистрируясь, вы соглашаетесь на получение электронных сообщений от ',
            part2: ' и принимаете ',
            brijBrand: " Brij's ",
            linkText: 'Условия и положения',
            part3: ' Для помощи или вопросов пишите на ',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'Если вы являетесь владельцем этого продукта и испытываете трудности с входом, свяжитесь с ',
          },
          unitTag: {
            heading: 'Добро пожаловать',
            description:
              'Чтобы просмотреть этот контент, нажмите кнопку ниже, чтобы отправить ссылку для входа на ваш зарегистрированный адрес электронной почты.',
          },
          viewDetails: 'Подробнее',
          fullTermsLink: 'См. полные условия',
          emailConsentRequired:
            'Пожалуйста, установите флажок согласия на электронную почту',
          privacyConsentRequired:
            'Пожалуйста, установите флажок согласия на конфиденциальность',
          and: 'и',
          sweepstakesRules: 'Правила розыгрыша',
          successMessage: {
            productRegistered: 'Продукт зарегистрирован!',
            signedUp: 'Вы зарегистрированы',
            warrantyActivated: 'Гарантия активирована!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'Добавить данные о покупке',
          successDrawer: {
            title: 'Продукт зарегистрирован!',
            description: 'Проверьте свою почту для получения деталей',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'Поиск результатов теста',
          enterLotNumber:
            'Введите номер партии продукта, чтобы увидеть результаты теста:',
          enterUpcNumber:
            'Введите 12-значный UPC номер продукта, чтобы увидеть результаты теста.',
          enterUpcAndLotNumber:
            'Введите 12-значный UPC и номер партии продукта, чтобы увидеть результаты теста.',
          lotNumberEmptyInputPlaceholder: 'Введите номер партии...',
          lotNumberInputPlaceholder: 'Номер партии...',
          optionalPlaceholderText: '(необязательно)',
          upcNumberEmptyInputPlaceholder: 'Введите UPC...',
          upcNumberInputPlaceholder: 'UPC...',
          submitLotNumberButton: 'Показать результаты теста',
          lotNumberHeading: 'Номер партии',
          testDateHeading: 'Дата теста',
          expirationDateHeading: 'Срок действия',
          viewFullReportButton: 'Показать все результаты теста',
          startOverButton: 'Сначала',
          heavyMetalsHeading: 'Тяжелые металлы',
          pesticidesAndGlyphosateHeading: 'Пестициды и глифосат',
          plasticizersHeading: 'Пластификаторы (BPA, BPS)',
          standardHeading: 'Стандарт',
          disclaimer:
            'Тесты проводились в соответствии с AB 899 Калифорнийскими стандартами. Для получения последней информации FDA и рекомендаций по воздействию вредных веществ на детей, посетите',
          disclaimerLink: 'эту ссылку',
          errors: {
            emptyLotOrUpcNumber: 'Введите номер партии для продолжения',
            noRecordFound:
              'Данные теста не найдены. Пожалуйста, убедитесь, что UPC или номер партии введены правильно.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'Дата ввода',
          entryIdHeading: 'ID ввода',
          sweepstakesEntryStatus: {
            entryApproved: 'Ввод одобрен',
            entryPending: 'Ввод в ожидании',
            entryDenied: 'Ввод отклонен',
            entryExpired: 'Срок действия ввода истек',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage:
              'Вы успешно зарегистрировались на этот розыгрыш.',
            entryPendingReviewMessage:
              'Ваша регистрация на розыгрыш в настоящее время находится на рассмотрении. Пожалуйста, проверьте статус позже.',
            entryDeniedMessage:
              'Ваша регистрация на розыгрыш была отклонена. Пожалуйста, убедитесь, что вы соответствуете требованиям этого розыгрыша.',
            sweepstakesExpiredMessage:
              'Этот розыгрыш завершен. Спасибо за ваше участие.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'Гарантия активирована',
          warrantyHeadingWithMulberry: 'Гарантия продлена Mulberry',
          expiredWarrantyHeading: 'Гарантия истекла',
          pendingWarrantyHeading: 'Гарантия ожидает рассмотрения',
          deniedWarrantyHeading: 'Гарантия отклонена',
          mulberryDefaultHeader: 'Держите свою защиту дольше',
          mulberryDefaultDescription:
            'Обновите, чтобы продлить защиту по гарантированной минимальной цене.',
          mulberryDefaultButtonCta: 'Продлить гарантию',
          mulberryExpirationNotice:
            'Гарантия продлевается Mulberry Technology Inc. и может быть приобретена до даты покупки',
          mulberryPolicyTerms: 'Показать условия гарантии',
          mulberryHelpText: {
            part1: 'Есть вопросы о продлении гарантии? Обратитесь по адресу',
            part2:
              'Свяжитесь с help@getmulberry.com, если у вас возникнут вопросы относительно расширенной гарантии.',
          },
          lifetimeLabel: 'Пожизненно',
          pendingInfoText:
            'Ваша регистрация гарантии в настоящее время находится на рассмотрении. Зайдите позже, чтобы узнать ее статус.',
          deniedInfoText:
            'Ваша регистрация гарантии была отклонена. Пожалуйста, отправьте действительный датированный чек ниже и убедитесь, что дата покупки совпадает с датой на чеке.',
          issueDate: 'Дата начала',
          expirationDate: 'Дата окончания',
          termLength: 'Срок действия',
          fullTermsLink: 'См. полные условия',
          viewDetails: 'Подробнее',
          detailsModal: {
            activateWarrantyHeading: 'Активировать гарантию',
            updateWarrantyHeading: 'Обновить данные',
            description:
              'Для активации гарантии введите дату покупки и загрузите датированный чек.',
            updateDescription:
              'Для обновления данных о покупке введите новую дату покупки и загрузите датированный чек.',
            activateButton: 'Активировать',
            updateButton: 'Обновить',
            cancelButton: 'Отмена',
          },
          successDrawer: {
            title: 'Гарантия активирована!',
            description: 'Проверьте свою почту для получения деталей',
          },
        },
        referralDrawer: {
          copyLinkButton: 'Скопировать ссылку',
          copyLinkToastMessage: 'Скопировано в буфер обмена',
          shareLinkButton: 'Поделиться ссылкой',
          shareText: 'Посмотрите этот продукт от Brij',
          helpText: 'Пусть ваш друг отсканирует этот код',
        },
        smsDrawer: {
          signUpButton: 'Подпишитесь на VIP-тексты',
          subscriptionDisclaimer:
            'Подписавшись на текст, вы соглашаетесь на получение повторяющихся автоматизированных персонализированных и маркетинговых текстовых сообщений от Canopy на номер телефона, указанный при регистрации. Согласие не является обязательным условием для совершения покупки. Ответьте HELP для получения справки и STOP для отмены. Частота сообщений может варьироваться. Стоимость сообщений и данных может применяться. Должен быть резидентом США и быть старше 18 лет. Просмотреть Условия и конфиденциальность.',
        },
        shopDrawer: {
          successDrawer: {
            title: 'Заказ размещен!',
            description: 'Проверьте свою почту для подтверждения заказа',
          },
          productDetails: {
            showMore: 'Больше',
            showLess: 'Меньше',
          },
          savingBanner: {
            title: 'Вы экономите',
          },
          cartButton: {
            callToAction: 'Добавить в корзину',
            invalidCombo: 'Нет в наличии',
            invalidQuantity: 'Выберите количество',
          },
          checkoutHint: {
            chooseOptions: 'Пожалуйста, выберите опции',
            comboUnavailable: 'Комбинация недоступна',
          },
          recommendations: {
            title: 'Рекомендации',
            seeMore: 'Посмотреть больше',
          },
          cartResetNotification: 'Для ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'Оформить заказ',
          },
          drawerTitle: 'Моя корзина',
          emptyCartText: 'В вашей корзине пока нет товаров',
        },
        discountDrawer: {
          discountCodeHeading: 'Промокод:',
          copiedToClipboard: 'Промокод скопирован в буфер обмена',
        },
        formDrawer: {
          submit: 'Отправить',
          next: 'Далее',
          start: 'Начать',
          regFormFetchError:
            'Не удалось получить форму регистрации. Пожалуйста, обновите и попробуйте снова.',
          formCompletetionMessage: 'Ваши ответы были успешно отправлены.',
          formSubmissionError:
            'Ошибка отправки формы. Пожалуйста, отправьте еще раз',
          submissionSuccessful: 'Успешная отправка',
          submitAnotherResponse: 'Отправить другой ответ',
          fileUpload: {
            fileName: 'Имя файла...',
            uploadFileButton: 'Загрузить файлы',
            syncToS3Error:
              'Файл не удалось синхронизировать с s3, пожалуйста, загрузите еще раз.',
            fileSizeError:
              'Размер файла превышает 20 МБ. Пожалуйста, отправьте еще раз.',
            fileLimitError:
              'Превышен лимит на 5 файлов. Пожалуйста, отправьте еще раз.',
            fileExtensionError: `Расширение файла не поддерживается. Допустимые файлы: .pdf, .jpeg,
              .jpg, heic и .png`,
          },
          textArea: {
            placeholder: 'Введите свой ответ здесь...',
            placeholderFilled: 'Ваш ответ...',
          },
          dropDown: {
            selectText: 'Нажмите, чтобы выбрать',
          },
          datePicker: {
            placeholder: 'Выберите дату',
            errorMessage: 'Неверная дата',
          },
          addressFields: {
            countryPlaceholder: 'Страна',
            addressLineOnePlaceholder: 'Адрес 1',
            addressLineTwoPlaceholder: 'Адрес 2',
            cityPlaceholder: 'Город',
            stateDropdownPlaceholder: 'Штат',
            stateFieldPlaceholder: 'Штат',
            zipcodePlaceholder: 'Почтовый индекс',
          },
          firstNamePlaceholder: 'Имя',
          lastNamePlaceholder: 'Фамилия',
        },
      },
    },
  },
  he: {
    // HEBREW
    translation: {
      rotationScreen: {
        message: 'אנא הפוך את המכשיר למצב זכוכית לצפייה בחוויה',
      },
      registration: {
        productRegistered: 'המוצר נרשם!',
        signedUp: 'הינך רשום!',
        warrantyActivated: 'האחריות הופעלה!',
        completeProfile: 'השלם פרופיל',
        registrationError: 'שגיאה בהרשמה',
      },
      socialLogin: {
        withGoogleText: 'עם Google',
        continueGoogleButton: 'המשך עם Google',
        signinError: 'אירעה שגיאה. אנא נסה להתחבר שוב',
        popupBlockedMessage:
          'חוסם הקופצים שלך חסם את החלון. אנא השבת את חוסמי הקופצים או בחר באפשרות האימייל כדי להמשיך.',
        cookiesDisabled:
          'אנא הפעל קובצי Cookie כדי להמשיך או נסה להירשם באמצעות אימייל.',
      },
      purchaseDetails: {
        receiptUploadSuccess: 'קבלה הועלתה.',
        receiptUploadError: 'שגיאה בהעלאת הקבלה',
        noReceiptError: 'אנא העלה קבלת רכישה',
        firstnameInputPlaceholder: 'הזן שם פרטי...',
        firstnameFilledInputPlaceholder: 'שם פרטי',
        lastnameInputPlaceholder: 'הזן שם משפחה...',
        lastnameFilledInputPlaceholder: 'שם משפחה',
        phoneNumberInputPlaceholder: 'הזן מספר טלפון...',
        serialNumberInputPlaceholder: 'הזן מספר סידורי...',
        serialNumberFilledInputPlaceholder: 'מספר סידורי',
        uploadReceiptButtonText: 'העלה קבלה',
        datepickerLabel: 'תאריך רכישה...',
        datepickerFilledLabel: 'תאריך רכישה',
        smsConsentRequired: 'אנא סמן את תיבת ההסכמה',
        invalidType: 'סוג קובץ לא חוקי. אנא העלה תמונה או PDF',
        invalidDate: 'תאריך לא חוקי',
        retailChannelInputPlaceholder: 'הזן מקום רכישה...',
        quantitySelectorInputPlaceholder: 'בחר כמות',
        quantitySelectorInputOther: 'אחר',
        failedToUpload: 'העלאה נכשלה',
        submitButtonText: 'שלח',
        receiptFailedError:
          'אחת או יותר מהקבלות עשויות לא לעמוד בדרישות. אנא העלה תמונה ברורה ומוארת של קבלת רכישה שלך.',
      },
      signIn: {
        pageTitle: 'התחבר ל-Brij',
        pageHeaderTitle: 'התחברות',
        withEmail: ' עם אימייל',
        completeProfile: 'השלם פרופיל',
        useDifferentOption: 'השתמש באפשרות אחרת',
        continueWithEmail: 'המשך עם אימייל',
        unitTagSignInLink: 'בקש קישור התחברות',
        checkYourEmail: 'בדוק את האימייל שלך',
        emailInputPlaceholder: 'הזן אימייל...',
        emailInputFilledPlaceholder: 'אימייל',
        toastMessages: {
          invalidEmail: 'אנא הזן כתובת אימייל חוקית',
          completeRecaptchaVerification: 'אנא השלם את אימות Recaptcha',
          cantVerifyLoginDetails: 'לא ניתן לאמת את פרטי ההתחברות',
          emailConsentRequired: 'אנא סמן את תיבת ההסכמה לאימייל',
          privacyConsentRequired: 'אנא סמן את תיבת ההסכמה לפרטיות',
        },
      },
      firebaseErrors: {
        'auth/invalid-email': 'אנא ספק כתובת אימייל חוקית',
        'auth/missing-email': 'אנא ספק אימייל',
        'auth/user-not-found': 'המשתמש לא קיים',
        'auth/wrong-password': 'אימייל או סיסמה לא חוקיים',
        'auth/internal-error': 'אירעה שגיאת שרת',
        'auth/popup-closed-by-user':
          'התחברות בוטלה. אנא השלם את תהליך ההתחברות או בחר באפשרות האימייל להמשך',
        'auth/cancelled-popup-request':
          'התחברות בוטלה. אנא השלם את תהליך ההתחברות או בחר באפשרות האימייל להמשך',
        'auth/invalid-credential-or-provider-id': 'פרטי כניסה לא חוקיים',
        'auth/unauthorized-domain': 'אתה נכנס לאתר מדומיין לא מאובטח.',
        'auth/invalid-action-code': 'הקוד אינו חוקי או פג תוקפו',
        'auth/popup-blocked':
          'חלון קופץ חסום. אנא הפעל חלונות קופצים או בחר באפשרות האימייל להמשך.',
      },
      magicLink: {
        linkSentToastMessage:
          'ברוך הבא! בדוק את האימייל שלך לקבלת קישור התחברות.',
        magicLinkError: 'אירעה שגיאה',
        redirecting: 'מופנה...',
      },
      ageGate: {
        title: 'אשר את גילך',
        subtitle: 'האם אתה בן {{ageLimit}} ומעלה?',
        confirmationButton: 'כן',
        disapprovalButton: 'לא',
        warningMessage: 'עליך להיות בן {{ageLimit}} ומעלה כדי לראות את האתר!',
      },
      contentGate: {
        enterButton: 'כניסה',
        continueButton: 'המשך',
        warningMessage: 'עליך להיות בגיל {{ageLimit}} ומעלה',
        invalidYearInput: 'אנא הזן שנה תקינה',
      },
      productDetails: {
        viewWarranty: 'הצג אחריות',
        leadInformation: {
          registeredTo: 'נרשם ל',
          warranty: 'אחריות',
        },
      },
      digitalHub: {
        searchBarPlaceholder: 'לחץ לחיפוש...',
        dropdownNoResults: 'אין תוצאות',
      },
      smsOptIn: {
        receiveMessageButton: 'שלח לי טקסט והתחל',
        sendMessageButton: 'שלח לנו טקסט והתחל',
        trySendingAgain: 'נסה שוב',
        sendMeText: 'שלח לי הודעת טקסט במקום זאת',
        sendUsText: 'שלח לנו הודעת טקסט במקום זאת',
        phoneNumberInputPlaceholder: 'הזן מספר טלפון...',
        consentRequiredError: 'אנא סמן את תיבת ההסכמה',
        checkYourPhone: 'בדוק את הטלפון שלך לקבלת הודעת טקסט',
        errorSendingRequest: 'שגיאה בשליחת הבקשה. אנא נסה שוב.',
        messageSentSuccessfully: 'בדוק את הטלפון שלך לקבלת הודעת טקסט',
        invalidPhoneNumberError: 'אנא הזן מספר טלפון חוקי',
        rebateAlreadyClaimedError: 'ההחזר הזה כבר נמצא',
        rebateIdError: 'מזהה ההחזר לא נמצא',
        messageSentText: 'הודעת טקסט נשלחה למספר שסופק. לא רואה את זה? ',
        defaultLegalText: `<p>על ידי הזנת מספר הטלפון שלך או בשליחת הודעת טקסט אלינו, אתה מסכים לקבל הודעות טקסט פרסומיות אוטומטיות חוזרות ומותאמות אישית (לדוגמה, הצעות מיוחדות) מ-{{brand_name}} במספר הטלפון הנייד שהשתמשת בו בעת ההרשמה. ההסכמה אינה תנאי לרכישה כלשהי. ענה על עזרה לקבלת עזרה ועל עצירה לביטול. תדירות ההודעות נעה. עלולות לחול תעריפי הודעות ונתונים. ראה את <a href='https://brij.it/terms' target='_self'>התנאים והפרטיות</a>.</p>`,
      },
      personalDetails: {
        smsConsentRequired: 'אנא סמן את תיבת ההסכמה לטקסט',
        firstNameInputPlaceholder: 'שם פרטי',
        lastNameInputPlaceholder: 'שם משפחה',
        phoneNumberInputPlaceholder: 'מספר טלפון',
        invalidFirstName: 'אנא הזן שם פרטי חוקי',
        invalidLastName: 'אנא הזן שם משפחה חוקי',
        invalidPhoneNumber: 'אנא הזן מספר טלפון חוקי',
        completeProfile: 'השלם פרופיל',
        continueButton: 'המשך',
        skip: 'אעשה זאת מאוחר יותר',
      },
      sideMenu: {
        myCart: 'עגלת קניות שלי',
        myProfile: 'הפרופיל שלי',
        myCollection: 'אוסף שלי',
        visitWebsite: 'בקר באתר',
        removeProduct: 'הסר מוצר',
        productRemoved: ' הוסר מהאוסף שלך.',
        signOut: 'התנתק',
        signIn: 'התחבר',
        signOutToastMessage: 'התנתקות מוצלחת',
        translate: 'תרגם',
        termsAndPrivacy: 'תנאים ופרטיות',
        sweepstakesRules: 'חוקי הגרלה',
      },
      profile: {
        pageTitle: 'פרופיל',
        pageHeaderTitle: 'פרופיל',
        firstNameInput: 'שם פרטי',
        lastNameInput: 'שם משפחה',
        emailInput: 'אימייל',
        phoneNumberInput: 'מספר טלפון',
        saveChanges: 'שמור שינויים',
        updateToastMessage: 'פרופיל עודכן',
      },
      collection: {
        pageTitle: 'אוסף שלי',
        collectionPageTitle: 'אוסף שלי',
        emptyCollectionMessage: 'אין מוצרים באוסף שלך',
        scanCodeButton: 'סרוק קוד',
        scanSuccessMessage: 'מוצר סרוק',
        scanErrorMessage: 'שגיאת סריקה',
        invalidScanMessage: 'לא קוד תקין של Brij',
        expireStatus: 'פג תוקף',
        purchaseStatus: 'נרכש',
        registerStatus: 'נרשם',
      },
      termsAndPrivacy: {
        brijLegalName: 'Brij Inc.',
        pageHeaderTitle: 'תנאים ופרטיות',
        pageTitle: 'תנאים ופרטיות',
        subtitle: 'תנאים והגבלות ומדיניות פרטיות',
        questionsAboutData: 'שאלות על הנתונים שלך?',
        contactUs: 'צור קשר',
        legalNotice: `Brij Inc. פועלת כספק שירות עבור {{brand_name}}, ומקלה על איסוף וניהול הנתונים של המשתמש, כולל כתובות אימייל ומספרי טלפון. שותפות זו מאפשרת ל-{{brand_name}} להשתמש בנתונים שנאספו דרך פלטפורמת Brij Inc. למטרות שצוינו במדיניות הפרטיות ותנאי השימוש הרלוונטיים. Brij Inc. מחויבת לטיפול בנתונים אלו באופן אחראי ובהתאם לכל דיני ההגנה על הנתונים החלים, ומבטיחה כי פרטיות המשתמש תתקיים ותתווסף במהלך שיתוף הפעולה שלנו עם {{brand_name}}.`,
        legalNoticeMixed: `אנא ראה את מדיניות הפרטיות ותנאי השימוש הרלוונטיים של Brij Inc. ו-{{brand_name}} למידע מפורט יותר.`,
        legalNoticeBrij: `אנא ראה את מדיניות הפרטיות ותנאי השימוש של Brij Inc. למידע מפורט יותר.`,
        termsButton: 'תנאים',
        privacyButton: 'מדיניות פרטיות',
      },
      gameRules: {
        pageTitle: 'חוקי המשחק',
        pageHeaderTitle: 'חוקי המשחק',
      },
      fourZeroFour: {
        pageTitle: '404 | לא נמצא',
        message: 'החוויה הזו לא קיימת',
        learnMoreButton: 'מידע נוסף על Brij',
        learnMoreLink: 'https://brij.it/',
      },
      header: {
        recommendationsHeading: 'המלצות',
      },
      customSelectInput: {
        noOptions: 'אין אפשרויות',
      },
      drawers: {
        bottomDrawer: {
          multipleRegistrationText: 'רוצה לרשום מוצר נוסף? לחץ כאן',
          callToActionButton: 'קריאה לפעולה',
          moreButton: 'עוד',
        },
        autoDeploy: {
          titles: {
            purchaseScreen: 'הוסף פרטי רכישה',
            completeProfileScreen: 'השלם פרופיל',
          },
        },
        authDrawer: {
          termsAndConditions: {
            part1: 'על ידי הרשמה אתה מסכים לקבל תקשורת באימייל מאת ',
            part2: ' ולקבל ',
            brijBrand: ' תנאי שימוש של Brij ',
            linkText: 'תנאים והגבלות',
            part3: ' לעזרה או שאלות שלח אימייל ל',
            helpEmail: 'support@brij.it',
            unitTagDisclaimer:
              'אם אתה בעל המוצר ויש לך קושי להתחבר, אנא צור קשר עם ',
          },
          unitTag: {
            heading: 'בקש קישור התחברות',
            description: 'הזן את כתובת האימייל שלך ונשלח לך קישור התחברות.',
          },
          viewDetails: 'הצג פרטים',
          fullTermsLink: 'ראה תנאים מלאים',
          emailConsentRequired: 'אנא סמן את תיבת ההסכמה לאימייל',
          privacyConsentRequired: 'אנא סמן את תיבת ההסכמה לפרטיות',
          and: 'ו',
          sweepstakesRules: 'חוקי הגרלה',
          successMessage: {
            productRegistered: 'המוצר נרשם!',
            signedUp: 'הינך רשום!',
            warrantyActivated: 'האחריות הופעלה!',
          },
        },
        registrationDrawer: {
          purchaseDetailsHeading: 'הוסף פרטי רכישה',
          successDrawer: {
            title: 'המוצר נרשם!',
            description: 'בדוק את האימייל שלך לפרטים',
          },
        },
        ab899Drawer: {
          testResultsLookup: 'חיפוש תוצאות בדיקה',
          enterLotNumber: 'הזן מספר סידורי',
          enterUpcNumber:
            'הזן את מספר UPC בן 12 הספרות של המוצר שלך כדי לראות את תוצאות הבדיקה של המוצר שלך:',
          enterUpcAndLotNumber:
            'הזן את ה-UPC בן 12 הספרות של המוצר ומספר המגרש כדי לראות את תוצאות הבדיקה של המוצר שלך:',
          lotNumberEmptyInputPlaceholder: 'הזן מספר סידורי...',
          lotNumberInputPlaceholder: 'מספר סידורי',
          optionalPlaceholderText: '(אופציונלי)',
          upcEmptyInputPlaceholder: 'הזן UPC...',
          upcInputPlaceholder: 'UPC',
          submitLotNumberButton: 'שלח',
          lotNumberHeading: 'מספר סידורי',
          testDateHeading: 'תאריך בדיקה',
          expirationDateHeading: 'תאריך תפוגה',
          viewFullReportButton: 'הצג דוח מלא',
          startOverButton: 'התחל מחדש',
          heavyMetalsHeading: 'מתכות כבדות',
          pesticidesAndGlyphosateHeading: 'חומרי מזיקים וגליפוזט',
          plasticizersHeading: 'פלסטיקים',
          standardHeading: 'תקן',
          disclaimer:
            'המידע המוצג נלקח ממאגר הנתונים של משרד הבריאות הציבורית של ארה"ב. כל המוצרים שנבדקו עברו בדיקה לפחמן, עופרת, ניקל, קדמיום, סידן וסודיום. רמות הרעילות המוצגות הן ערכי ממוצע של כל המוצרים שנבדקו בקטגוריה זו. כל המוצרים שנבדקו עברו בדיקה לפחמן, עופרת, ניקל, קדמיום, סידן וסודיום. רמות הרעילות המוצגות הן ערכי ממוצע של כל המוצרים שנבדקו בקטגוריה זו.',
          disclaimerLink: 'למידע נוסף',
          errors: {
            emptyLotOrUpcNumber: 'אנא הזן מספר סידורי',
            noRecordFound:
              'לא ניתן למצוא נתוני בדיקה. אנא ודא שמספר ה-UPC או המגרש הוזן כהלכה.',
          },
        },
        sweepstakesDrawer: {
          entryDateHeading: 'תאריך הכניסה',
          entryIdHeading: 'מספר כניסה',
          sweepstakesEntryStatus: {
            entryApproved: 'הכניסה אושרה',
            entryPending: 'הכניסה ממתינה לאישור',
            entryDenied: 'הכניסה נדחתה',
            entryExpired: 'הכניסה פגה',
          },
          sweepstakesEntryStatusMessage: {
            entryActiveMessage: 'תודה על השתתפותך בסקר!',
            entryPendingReviewMessage: 'הכניסה שלך ממתינה לאישור.',
            entryDeniedMessage: 'הכניסה שלך נדחתה.',
            sweepstakesExpiredMessage: 'הכניסה שלך פגה.',
          },
        },
        warrantyDrawer: {
          warrantyHeading: 'אחריות פעילה',
          warrantyHeadingWithMulberry: 'אחריות פעילה עם Mulberry',
          expiredWarrantyHeading: 'אחריות פגה',
          pendingWarrantyHeading: 'אחריות ממתינה לביקורת',
          deniedWarrantyHeading: 'אחריות נדחתה',
          mulberryDefaultHeader: 'הפעל אחריות',
          mulberryDefaultDescription: 'הזן את תאריך הרכישה והעלה קבלה תאריך.',
          mulberryDefaultButtonCta: 'הרחב את כיסוי האחריות שלי',
          mulberryExpirationNotice:
            'הרחבות אחריות מנוהלות על ידי Mulberry Technology Inc. וזמינות לרכישה עד ',
          mulberryPolicyTerms: 'הצג את תנאי המדיניות',
          mulberryHelpText: {
            part1: 'תוכניות אחריות מורחבת מנוהלות על ידי',
            part2:
              'צור קשר עם help@getmulberry.com עם שאלות בנוגע לאחריות המורחבת שלך.',
          },
          lifetimeLabel: 'לכל החיים',
          pendingInfoText:
            'ההרשמה לאחריות שלך נמצאת כרגע בביקורת. חזור מאוחר יותר כדי לראות את מעמדה.',
          deniedInfoText:
            'ההרשמה לאחריות שלך נדחתה. אנא שלח קבלת רכישה תקפה וודא שתאריך הרכישה תואם את תאריך הקבלה.',
          issueDate: 'תאריך התחלה',
          expirationDate: 'תאריך תפוגה',
          termLength: 'אורך תקופה',
          fullTermsLink: 'ראה תנאים מלאים',
          viewDetails: 'הצג פרטים',
          detailsModal: {
            activateWarrantyHeading: 'הפעל אחריות',
            updateWarrantyHeading: 'עדכן פרטים',
            description:
              'כדי להפעיל את האחריות שלך אנא הזן את תאריך הרכישה והעלה קבלה תאריך.',
            updateDescription:
              'כדי לעדכן את פרטי הרכישה שלך, אנא הזן את תאריך הרכישה החדש והעלה קבלה תאריך.',
            activateButton: 'הפעל',
            updateButton: 'עדכן',
            cancelButton: 'ביטול',
          },
          successDrawer: {
            title: 'האחריות הופעלה!',
            description: 'בדוק את האימייל שלך לפרטים',
          },
        },
        referralDrawer: {
          copyLinkButton: 'העתק קישור',
          copyLinkToastMessage: 'הועתק ללוח',
          shareLinkButton: 'שתף קישור',
          shareText: 'צפה במוצר זה מ-Brij',
          helpText: 'הזמן חבר שיסרוק את הקוד',
        },
        smsDrawer: {
          signUpButton: 'הירשם לטקסט VIP',
          subscriptionDisclaimer:
            'על ידי הרשמה דרך הטקסט, אתה מסכים לקבל הודעות טקסט אוטומטיות חוזרות ופרסומיות ממותג במספר הנייד שהשתמשת בו בעת ההרשמה. ההסכמה אינה תנאי לרכישה. ענה על עזרה לקבלת עזרה ועל עצירה לביטול. תדירות ההודעות נעה. עלולות לחול תעריפי הודעות ונתונים. עליך להיות תושב של ארה"ב ובן 18 ומעלה. ראה תנאים ופרטיות.',
        },
        shopDrawer: {
          successDrawer: {
            title: 'הזמנה בוצעה!',
            description: 'בדוק את האימייל שלך לאישור הזמנה',
          },
          productDetails: {
            showMore: 'עוד',
            showLess: 'פחות',
          },
          savingBanner: {
            title: 'אתה חוסך',
          },
          cartButton: {
            callToAction: 'הוסף לעגלה',
            invalidCombo: 'אזל מהמלאי',
            invalidQuantity: 'בחר כמות',
          },
          checkoutHint: {
            chooseOptions: 'אנא בחר אפשרויות',
            comboUnavailable: 'השילוב אינו זמין',
          },
          recommendations: {
            title: 'המלצות',
            seeMore: 'ראה עוד',
          },
          cartResetNotification: 'נוצרה עגלה חדשה עבור ',
        },
        cartDrawer: {
          checkoutButton: {
            callToAction: 'לקופה',
          },
          drawerTitle: 'עגלת קניות',
          emptyCartText: 'העגלה שלך ריקה',
        },
        discountDrawer: {
          discountCodeHeading: 'קוד הנחה:',
          copiedToClipboard: 'קוד הנחה הועתק ללוח',
        },
        formDrawer: {
          submit: 'שלח',
          next: 'הבא',
          start: 'התחל',
          regFormFetchError: 'הטופס לא נטען בהצלחה. אנא רענן ונסה שוב.',
          formCompletetionMessage: 'התשובות שלך נשלחו בהצלחה.',
          formSubmissionError: 'שליחת הטופס נכשלה. אנא שלח שוב',
          submissionSuccessful: 'הגשה מוצלחת',
          submitAnotherResponse: 'שלח תשובה נוספת',
          fileUpload: {
            fileName: 'שם קובץ...',
            uploadFileButton: 'העלה קבצים',
            syncToS3Error: 'הקובץ לא נשמר בהצלחה. אנא העלה שוב.',
            fileSizeError: 'גודל הקובץ גדול מדי. אנא העלה שוב.',
            fileLimitError: 'הגעת למגבלת 5 קבצים. אנא העלה שוב.',
            fileExtensionError: `סיומת הקובץ אינה נתמכת. קבצים תקינים הם .pdf, .jpeg,
              .jpg, heic ו-.png`,
          },
          textArea: {
            placeholder: 'הקלד את התשובה שלך כאן...',
            placeholderFilled: 'הקלד כאן...',
          },
          dropDown: {
            selectText: 'לחץ לבחירה',
          },
          datePicker: {
            placeholder: 'בחר תאריך',
            errorMessage: 'תאריך לא חוקי',
          },
          addressFields: {
            countryPlaceholder: 'מדינה',
            addressLineOnePlaceholder: 'כתובת 1',
            addressLineTwoPlaceholder: 'כתובת 2',
            cityPlaceholder: 'עיר',
            stateDropdownPlaceholder: 'מדינה',
            stateFieldPlaceholder: 'מדינה',
            zipcodePlaceholder: 'מיקוד',
          },
          firstNamePlaceholder: 'שם פרטי',
          lastNamePlaceholder: 'שם משפחה',
        },
      },
    },
  },
};
