import React from 'react';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';

type HtmlWrapperProps = {
  html?: string;
  styles?: string;
  background?: string;
  hideAnimation?: boolean;
  paragraphTextColor?: string;
  cornerStyle?: string;
};

const fontModifier = (html: string = ''): string =>
  html.replaceAll(
    'style="font-family:',
    'class="override-font" style="font-family:'
  );

const HtmlWrapper: React.FC<HtmlWrapperProps> = ({
  html,
  styles,
  background,
  hideAnimation,
  paragraphTextColor,
  cornerStyle,
}) => {
  const getCornerStyle = () => {
    switch (cornerStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-[26px]';
    }
  };

  const getTableStyle = () => {
    if (html?.includes('fr-alternate-rows'))
      return 'even:[&_tr]:bg-gray-100 odd:[&_tr]:bg-white';
    else return '';
  };

  return (
    <div
      tabIndex={0}
      dangerouslySetInnerHTML={{ __html: fontModifier(html) }}
      style={{
        animation: hideAnimation ? '' : 'fade-in 0.6s ease-in-out forwards',
        color: paragraphTextColor ? paragraphTextColor : 'inherit',
        background: background ? background : 'auto',
        overflow: 'visible',
      }}
      className={`
        relative w-full flex flex-col break-words whitespace-pre-wrap animate-htmlAnimation 
        [&_a]:underline 
        [&_video]:bg-black [&_th]:text-left 
        [&_pre]:font-mono [&_pre]:whitespace-normal 
        [&_span]:text-inherit [&_span]:leading-normal [&_span]:!bg-no-repeat [&_span]:!bg-center 
        [&_hr]:w-full [&_hr]:h-px [&_hr]:my-1.5 [&_hr]:bg-gray-300 
        [&_ul]:text-xs [&_ul]:leading-[18px] [&_ul]:pl-6 [&_ul]:list-disc 
        [&_ol]:text-xs [&_ol]:leading-[18px] [&_ol]:pl-6 [&_ol]:list-decimal 
        [&_p]:text-xs [&_p]:m-0 [&_p]:p-0 [&_p]:text-inherit 
        [&_.fr-fil]:float-left [&_.fr-fir]:float-right [&_.fr-fic]:mx-auto 
        [&_.fr-box]:m-0 [&_.fr-basic]:m-0 [&_.fr-element]:m-0 
        [&_.fr-size-large]:mt-5 [&_.fr-size-large]:w-full [&_.fr-size-large]:h-[52px] [&_.fr-size-large>a]:text-center 
        [&_.fr-size-large]:flex [&_.fr-size-large]:justify-center [&_.fr-size-large]:items-center 
        [&_.fr-size-large]:cursor-pointer [&_.fr-size-large]:font-medium [&_.fr-size-large]:text-lg 
        [&_.fr-size-large]:decoration-0 [&_.fr-size-large]:bg-[#0A0B0A] [&_.fr-size-large]:text-white [&_.fr-size-large]:no-underline 
        [&_.fr-bordered]:!w-[calc(100%-16px)] [&_.fr-bordered]:ring-8 [&_.fr-bordered]:ring-gray-300 
        [&_h1]:text-inherit [&_h1]:text-[32px] [&_h1]:font-bold [&_h1]:leading-normal 
        [&_h2]:text-inherit [&_h2]:text-[24px] [&_h2]:font-bold [&_h2]:leading-normal 
        [&_h3]:text-inherit [&_h3]:text-[18px] [&_h3]:font-bold [&_h3]:leading-normal 
        [&_h4]:text-inherit [&_h4]:text-[14px] [&_h4]:font-bold [&_h4]:leading-normal 
        [&_h5]:text-inherit [&_h5]:leading-normal 
        [&_h6]:text-inherit [&_h6]:leading-normal 
        [&_.fr-inner]:block [&_.fr-inner]:text-center 
        [&_.fr-text-spaced]:tracking-[0.1em] [&_.fr-text-uppercase]:uppercase 
        [&_.fr-text-bordered]:border [&_.fr-text-bordered]:border-solid [&_.fr-text-bordered]:border-l-0 [&_.fr-text-bordered]:border-r-0 
        [&_.fr-text-bordered]:py-2 [&_.fr-text-bordered]:border-black/50 
        [&_.fr-text-gray]:m-0 [&_.fr-text-gray]:p-0 [&_.fr-text-gray]:text-[#7D7D7D] 
        [&_.fr-rounded]:my-1 [&_.fr-rounded]:rounded-[10px] [&_.fr-shadow]:shadow-md 
        [&_iframe]:!relative [&_iframe]:!aspect-video [&_iframe]:!max-w-full [&_iframe]:!h-auto 
        [&_.fr-video]:relative [&_.fr-video>video]:mx-auto 
        [&_.fr-video>.shadow]:w-[calc(100%-14px)] [&_.fr-video>.shadow]:shadow-froalaVideo [&_.fr-video>.shadow]:mx-0 
        [&_.fr-video>.border]:w-[calc(100%-8px)] [&_.fr-video>.border]:ml-1 [&_.fr-video>.border]:ring-4 [&_.fr-video>.border]:ring-froalaBorder 
        [&_.fr-video>.border-radius]:rounded-xl 
        [&_.fr-fvl>video]:float-left [&_.fr-fvr>video]:float-right [&_.fr-active>video]:mx-auto 
        [&_.ql-video]:absolute [&_.ql-video]:top-0 [&_.ql-video]:left-0 [&_.ql-video]:w-full [&_.ql-video]:h-full 
        [&_.ql-align-right]:text-right [&-.ql-align-right]:w-full 
        [&_.ql-align-left]:text-left [&-.ql-align-left]:w-full 
        [&_.ql-align-center]:text-center [&-.ql-align-center]:w-full 
        [&_.ql-align-justify]:text-justify [&-.ql-align-justify]:w-full 
        [&_.ql-size-large]:flex [&_.ql-size-large]:items-center [&_.ql-size-large]:justify-center 
        [&_.ql-size-large]:cursor-pointer [&_.ql-size-large]:rounded-[26px] [&_.ql-size-large]:decoration-0 
        [&_.ql-size-large>a]:text-center [&_.ql-size-large]:mt-5 [&_.ql-size-large]:w-full [&_.ql-size-large]:h-[54px] 
        [&_.ql-size-large]:font-medium [&_.ql-size-large]:text-lg [&_.ql-size-large]:bg-black [&_.ql-size-large]:text-white 
        ${getCornerStyle()} ${getTableStyle()} ${styles}
      `}
    />
  );
};

export default HtmlWrapper;
